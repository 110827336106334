import React, { useEffect, useRef, useState } from "react";
import { LogoScroller } from "../../logoScroller";
import AILogo from "../../images/ic_ai.svg"
import { ReactComponent as IconArrow } from "../../images/ic_landing_arrow.svg"
import { useBroadcastedState } from "base/utils/events";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ClientLogo1 from "../../images/landing/client_logos/ic_client_1.png"
import ClientLogo2 from "../../images/landing/client_logos/ic_client_2.png"
import ClientLogo3 from "../../images/landing/client_logos/ic_client_3.png"
import ClientLogo4 from "../../images/landing/client_logos/ic_client_4.png"
import ClientLogo5 from "../../images/landing/client_logos/ic_client_5.png"
import ClientLogo6 from "../../images/landing/client_logos/ic_client_6.png"
import ClientLogo7 from "../../images/landing/client_logos/ic_client_7.png"
import ClientLogo8 from "../../images/landing/client_logos/ic_client_8.png"
import ClientLogo9 from "../../images/landing/client_logos/ic_client_9.png"
import ClientLogo10 from "../../images/landing/client_logos/ic_client_10.png"
import ClientLogo11 from "../../images/landing/client_logos/ic_client_11.png"
import ClientLogo12 from "../../images/landing/client_logos/ic_client_12.png"
import ClientLogo13 from "../../images/landing/client_logos/ic_client_13.png"
import ClientLogo14 from "../../images/landing/client_logos/ic_client_14.png"

import Feature1 from "../../images/landing/home/features/b2b_1.webp"
import Feature2 from "../../images/landing/home/features/b2b_2.webp"
import Feature3 from "../../images/landing/home/features/b2b_3.webp"
import Feature4 from "../../images/landing/home/features/b2b_4.webp"
import Feature5 from "../../images/landing/home/features/b2b_5.webp"

import B2C1 from "../../images/landing/home/features/b2c_1.webp"
import B2C2 from "../../images/landing/home/features/b2c_2.webp"
import B2C3 from "../../images/landing/home/features/b2c_3.webp"
import B2C4 from "../../images/landing/home/features/b2c_4.webp"

import bgPage1 from "../../images/landing/page1_bg.svg"
import bgPage2 from "../../images/landing/page2_bg.svg"
import bgBoxes from "../../images/landing/boxes_bg.svg"
import ImgHome from "../../images/landing/home/page1_1.webp"

import img_ai1 from "../../images/landing/home/ai/ai1.svg"
import img_ai2 from "../../images/landing/home/ai/ai2.svg"
import img_ai3 from "../../images/landing/home/ai/ai3.svg"
import img_ai4 from "../../images/landing/home/ai/ai4.svg"
import img_ai5 from "../../images/landing/home/ai/ai5.svg"

import img_m_ai1 from "../../images/landing/home/ai/mobile_ai1.svg"
import img_m_ai2 from "../../images/landing/home/ai/mobile_ai2.png"
import img_m_ai3 from "../../images/landing/home/ai/mobile_ai3.png"
import img_m_ai4 from "../../images/landing/home/ai/mobile_ai4.svg"
import img_m_ai5 from "../../images/landing/home/ai/mobile_ai5.png"

import logo_godrej from "../../images/landing/home/logo_godrej.png"
import logo_uncover from "../../images/landing/home/logo_uncover.png"
import { swipeDetect } from "base/utils/detect";

const client_logos = [
  ClientLogo1, ClientLogo2, ClientLogo3, ClientLogo4, ClientLogo5, ClientLogo6, ClientLogo7,
  ClientLogo8, ClientLogo9, ClientLogo10, ClientLogo11, ClientLogo12, ClientLogo13, ClientLogo14
].map((image, i) => ({
  id: i,
  image
}));



const brandsData = [
  {
    title: "Tapping into an already existing consumer behavior with an elevated user journey is genius.",
    desc: "We are excited to have partnered with Sukhiba to launch WhatsApp " +
      "commerce in Kenya. Our customers can receive broadcast newsletters, " +
      "chat to an expert, browse catalogs, add to cart, and pay all within " +
      "WhatsApp. In such a short space of time, our WhatsApp revenue is 3x " +
      "our email revenue and a third of our total e-commerce revenue. We are " +
      "working to replicate this model in Nigeria.",
    name: "Jade Oyateru",
    designation: "COO",
    image: logo_uncover,
  },
  {
    title: "We are much more efficient.",
    desc: "At a glance we can instantly see how we are performing, " +
      "in a very easy and intuitive way. The live chats and loyalty program " +
      "have been a game changer and led us to tripling our sales in just 3 months." +
      "Conversations can be turned into orders in a very personalized manner." +
      "With the analytics dashboard we can see what needs to be done and when.",
    name: "Joyce Watitu",
    designation: "Brand Manager",
    image: logo_godrej,
  },
];

const page2_features = {
  "send_cart": "Send Cart",
  "gateway_support": "Payments",
  "broadcast": "Broadcast",
  "acitivity_tracking": "Activity Tracking",
  "analytics": "Analytics"
}
const page3_features = {
  "online_storefront": "Online Storefront",
  "payment_reward": "Payment, reward and loyalty",
  "broadcast": "Broadcast",
  "customers_wa": "Support Customers on WhatsApp"
}
const page2_details = {
  "send_cart": {
    title: "Send virtual carts to retailers\nand streamline checkout in-app",
    sub_title: "Seal the deal through WhatsApp and compliment\nin-person visits.",
    description: [
      "Manage conversations across multiple retailers with\none WhatsApp number",
      "Efficiently edit orders, customize discounts and\ndrive retailers to checkout"
    ],
    image: Feature1
  },
  "gateway_support": {
    title: "Accept payments across geographies,\ninstantly",
    sub_title: "Integrate with multiple gateways for seamless\npayment processing.",
    description: [
      "Monitor payment inflows and outflows through a\nsingle dashboard",
      "Manage individual accounts with separate\nwallets",
      "Integrations include M-Pesa, Peach Payments,\nPaystack, and more"
    ],
    image: Feature3
  },
  "broadcast": {
    title: "Share deals with retailer cohorts to\noptimize your inventory",
    sub_title: "Spread the word about discounts and promotions, at\nthe right time, every time with broadcasts.",
    description: [
      "Retailers can view live offers and apply them\ndirectly to cart",
      "Link credits, discounts, and cashback to\nretailer's wallets"
    ],
    image: Feature2
  },
  "acitivity_tracking": {
    title: "Simplify tracking your team's day to day\nin the field",
    sub_title: "Manage and scale your sales team's performance\nremotely.",
    description: [
      "View check-ins and checkouts with\ngeofencing",
      "Optimize sales routes and last mile\ndelivery."
    ],
    image: Feature4
  },
  "analytics": {
    title: "Segment retailers based on their\nbuying habits",
    sub_title: "No more one-size-fits-all approach. Use real-time\nactivity analytics to shape your messaging.",
    description: [
      "Group customers by frequency of purchase, most\nfrequently purchased items, and more",
      "Personalize your relationship to different\nretailers with data",
      "Efficiently edit orders, customize discounts and\ndrive retailers to checkout"
    ],
    image: Feature5
  }
}
const page3_details = {
  "online_storefront": {
    title: "List, chat, and sell – all on WhatsApp",
    sub_title: "Set up a shop, get customers to browse your catalog, add to cart, and pay, all within WhatsApp.",
    description: [
      "Set up a direct Sukhiba store front, or sync via Shopify or WooCommerce",
      "Monitor payment inflow and outflow for multiple supported gateways through a single dashboard",
      "Power your deliveries and last-mile payments by integrations with delivery and logistics companies"
    ],
    image: B2C1
  },
  "payment_reward": {
    title: "Build lasting customer relationships with a customizable rewards and loyalty program",
    sub_title: "Keep customers coming back by incentivizing repeat purchases.",
    description: [
      "Generate QR codes so customers can easily participate in your program",
      "Customers can track, access, and redeem loyalty points, all in their WhatsApp wallet",
      "Manage and track coupon codes, from one dashboard"
    ],
    image: B2C2
  },
  "broadcast": {
    title: "Share your latest promotions with every customer",
    sub_title: "Stay top of mind with regular incentives and discounts.",
    description: [
      "Send offers, discounts, new product launches and more exciting news",
      "Easily broadcast to all customers, or target specific ones using our AI-enabled broadcast feature",
    ],
    image: B2C3
  },
  "customers_wa": {
    title: "Unlock AI-powered customer service, at scale",
    sub_title: "Stay on top of customer requests, even after business hours with a handy AI-Assistant.",
    description: [
      "Create automated replies to understand customer needs better",
      "Get visibility into the biggest questions and challenges",
      "Customize your brand's interactions to address these with the AI Flow Builder"
    ],
    image: B2C4
  }
}

function HomePage2() {
  const [selected_tab, setSelectedTab] = useState("send_cart")

  return <div
    id="features"
    style={{ backgroundImage: 'url(' + bgPage2 + ')', backgroundSize: 'cover' }}
    className=" font-figtree tw-text-landing-black tw-grid tw-justify-center tw-p-8 lg:tw-p-16">
    <div className="tw-text-center  tw-font-medium">
      <h1 className="tw-text-[18px] md:tw-text-[28px] tw-text-landing-green">B2B</h1>
      <div className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre tw-flex-1 tw-my-2 tw-leading-tight">
        The Operating System for Businesses{"\n"}on WhatsApp
      </div>
    </div>
    <div className="tw-flex tw-flex-wrap tw-mt-4 md:tw-mt-6 tw-justify-center tw-gap-2 md:tw-gap-4 tw-text-[12px] md:tw-text-[16.6px]">
      {
        Object.keys(page2_features).map((feature, i) => {
          return <div key={i}
            className={`tw-rounded-full tw-cursor-pointer tw-px-4 xl:tw-px-8 tw-py-2 ${feature === selected_tab ? "tw-bg-landing-green tw-text-white" : "tw-border tw-border-landing-black"}`} onClick={() => {
              setSelectedTab(feature)
            }}>
            {page2_features[feature]}
          </div>
        })
      }
    </div>
    <div
      className={`md:tw-flex md:tw-mx-8 tw-my-10 tw-justify-center max-md:tw-border tw-border-landing-gray max-md:tw-rounded-2xl `}>
      <div className="xl:tw-w-[40%] lg:tw-w-[50%] md:tw-w-[60%] tw-p-6 tw-bg-white max-md:tw-rounded-t-2xl md:tw-rounded-l-2xl">
        <h1 className="tw-text-[16px] max-sm:tw-text-[20px] lg:tw-text-[20px] xl:tw-text-[24px] 2xl:tw-text-[28px] !tw-font-semibold tw-text-primary-dark tw-mb-2">
          {page2_details[selected_tab].title}
        </h1>
        {page2_details[selected_tab].sub_title ? (
          <h6 className="tw-text-landing-green tw-font-medium tw-mb-2 tw-text-[14px] max-sm:tw-text-[16px] lg:tw-text-[18px] xl:tw-text-[20px] 2xl:tw-text-[24px]">
            {page2_details[selected_tab].sub_title}
          </h6>
        ) : null}
        <ul className="tw-mt-6 tw-list-none tw-list">
          {page2_details[selected_tab].description.map((point, j) => (
            <li
              key={j}
              className=" tw-text-[#666666] tw-mt-2 tw-flex tw-gap-2 tw-text-[12px] max-sm:tw-text-[14px] lg:tw-text-[15px] xl:tw-text-[16px] 2xl:tw-text-[20px]"
            >
              <div className="2xl:tw-p-1.5  tw-p-1 tw-h-2 2xl:tw-mt-3 tw-mt-2 tw-rounded-full tw-bg-[#FFB800] tw-rounded-full" />
              {point}
            </li>
          ))}
        </ul>
      </div>
      <LazyLoadImage src={page2_details[selected_tab].image} className="xl:tw-w-[35%] lg:tw-w-[50%] md:tw-w-[40%] max-md:tw-w-wrap max-md:tw-rounded-b-2xl md:tw-rounded-r-2xl" />

    </div>
  </div>
}
function HomePage3() {
  const [selected_tab, setSelectedTab] = useState("online_storefront")

  return <div
    style={{ backgroundImage: 'url(' + bgBoxes + ')' }}
    className="tw-p-10 font-figtree tw-text-landing-black tw-bg-[#133A3A] tw-grid tw-justify-items-center">
    <div className="tw-text-center">
      <h1 className="tw-text-[18px] md:tw-text-[28px] tw-font-semibold tw-text-landing-yellow">B2C</h1>
      <div className="tw-text-[24px] md:tw-text-[40px] tw-font-semibold md:tw-whitespace-pre tw-flex-1 tw-my-4 tw-leading-tight tw-text-white ">
        {`One Conversation Channel,\na Lifetime of Growth`}
      </div>
    </div>
    <div className="tw-flex tw-flex-wrap tw-mt-4 md:tw-mt-6 tw-justify-center lg:tw-w-max tw-gap-2 md:tw-gap-4 tw-text-[12px] md:tw-text-[16.6px]">
      {
        Object.keys(page3_features).map((feature, i) => {
          return <div key={i}
            className={`tw-rounded-full tw-cursor-pointer tw-px-4 xl:tw-px-8 tw-py-2 ${feature === selected_tab ? "tw-bg-landing-yellow tw-text-[#122A3A]" : "tw-border tw-text-white tw-border-white"}`} onClick={() => {
              setSelectedTab(feature)
            }}>
            {page3_features[feature]}
          </div>
        })
      }
    </div>
    <div
      className={`md:tw-flex md:tw-mx-8 tw-my-10 tw-justify-center max-md:tw-rounded-2xl `}>
      <LazyLoadImage src={page3_details[selected_tab].image} className="xl:tw-w-[40%] lg:tw-w-[50%] md:tw-w-[60%] max-md:tw-rounded-t-2xl md:tw-rounded-l-2xl" />

      <div className="xl:tw-w-[35%] lg:tw-w-[50%] md:tw-w-[40%] max-md:tw-w-wrap max-md:tw-rounded-b-2xl md:tw-rounded-r-2xl tw-p-6 tw-bg-[#000]/[0.2]">
        <h1 className="tw-text-[16px] max-sm:tw-text-[20px] lg:tw-text-[20px] xl:tw-text-[24px] 2xl:tw-text-[28px] !tw-font-semibold tw-text-white tw-mb-2">
          {page3_details[selected_tab].title}
        </h1>
        {page3_details[selected_tab].sub_title ? (
          <h6 className="tw-text-landing-green tw-font-medium tw-mb-2 tw-text-[14px] max-sm:tw-text-[16px] lg:tw-text-[18px] xl:tw-text-[20px] 2xl:tw-text-[24px]">
            {page3_details[selected_tab].sub_title}
          </h6>
        ) : null}
        <ul className="tw-mt-6 tw-list-none tw-list">
          {page3_details[selected_tab].description.map((point, j) => (
            <li
              key={j}
              className="tw-font-light tw-text-white tw-mt-2 tw-flex tw-gap-2 tw-text-[12px] max-sm:tw-text-[14px] lg:tw-text-[15px] xl:tw-text-[16px] 2xl:tw-text-[20px]"
            >
              <div className="2xl:tw-p-1.5  tw-p-1 tw-h-2 2xl:tw-mt-3 tw-mt-2 tw-rounded-full tw-bg-[#FFB800] tw-rounded-full" />
              {point}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
}

function HomePageTestimonials() {
  const [selected_tab, setSelectedTab] = useState(0)
  const swipe_el_ref = useRef(null);

  var interval = null;
  // useEffect(() => {
  //   if (screen.lg) {
  //     interval = setInterval(() => {
  //       console.log("interval before", selected_tab);
  //       setSelectedTab(selected_tab == 0 ? 1 : 0)
  //     }, 5000)
  //     return () => {
  //       clearInterval(interval);
  //     }
  //   }
  // }, [selected_tab])

  useEffect(() => {
    return swipeDetect(swipe_el_ref.current,
      (direction) => {
        // todo change condition based on direction when added more than 2 testinominals
        setSelectedTab(selected_tab == 0 ? 1 : 0)
      });
  }, [swipe_el_ref, selected_tab]);

  return <div
    style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
    className="tw-p-10 font-figtree tw-text-landing-black tw-bg-[#133A3A] tw-grid tw-justify-items-center">
    <div className="tw-text-center">
      <h1 className="tw-text-[36px] max-sm:tw-text-[24px] md:tw-text-[48px] tw-font-semibold tw-text-landing-black">Customer Testimonials</h1>
    </div>
    <div
      className={`lg:tw-flex max-lg:tw-grid tw-grid-cols-1 tw-items-center lg:tw-w-[90%] xl:tw-w-[70%] tw-rounded-xl tw-mt-8 lg:tw-justify-center tw-justify-items-center tw-bg-[#FAFAFA]`}
      ref={swipe_el_ref}
      // onMouseEnter={() => {
      //   interval && clearInterval(interval)
      // }} onMouseLeave={() => {
      //   setSelectedTab(selected_tab == 0 ? 1 : 0)
      // }}
      >
      <img src={brandsData[selected_tab].image} className="tw-h-max tw-rounded-l-xl " />
      <div className="tw-p-6 lg:tw-p-12 tw-rounded-r-xl lg:tw-w-[70%] xl:tw-w-[60%] max-sm:tw-h-[426px] max-lg:tw-h-[306px] lg:tw-h-[536px] xl:tw-h-[586px] 2xl:tw-h-[566px]">
        <div className="tw-text-[24px] max-sm:tw-text-[20px] md:tw-text-[32px] tw-font-semibold tw-text-[#202020] tw-mb-2 tw-leading-tight">
          {brandsData[selected_tab].title}
        </div>
        {brandsData[selected_tab].desc ? (
          <div className="tw-text-[#202020] tw-text-[14px] lg:tw-text-[18px] xl:tw-text-[20px] 2xl:tw-text-[24px] tw-font-light tw-mb-2">
            {brandsData[selected_tab].desc}
          </div>
        ) : null}

        {brandsData[selected_tab].name ? (
          <div className="tw-text-[#202020] tw-text-[18px] tw-font-semibold">
            {brandsData[selected_tab].name}
          </div>
        ) : null}
        {brandsData[selected_tab].designation ? (
          <div className="tw-text-[#808080] tw-text-[14px] tw-font-medium tw-mb-2">
            {brandsData[selected_tab].designation}
          </div>
        ) : null}
      </div>
    </div>
    <div className="tw-flex tw-flex-wrap tw-mt-8 tw-rounded-2xl tw-bg-black/[0.11] tw-p-1 tw-justify-center md:tw-w-max tw-gap-1 tw-text-[14px] md:tw-text-[16.6px]">
      {
        Object.keys(brandsData).map((feature, i) => {
          return <div key={i}
            className={`tw-rounded-full tw-w-3 tw-h-3 tw-cursor-pointer ${i === selected_tab ? "tw-bg-landing-green tw-border tw-border-white" : "tw-bg-white"}`} onClick={() => {
              setSelectedTab(i)
            }}>
          </div>
        })
      }
    </div>
  </div>
}

const ScheduleDemoButton = ({ className }) => {
  return <button
    className={`font-figtree tw-text-[20px] tw-rounded-full tw-bg-landing-green tw-px-8 tw-text-white tw-py-3.5 ${className}`}
    onClick={() => {
      window.open("/contactus", "_self")
    }}>
    Schedule a demo
  </button>
}

function HomePage() {
  const [screen] = useBroadcastedState("tw_screen_size");

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none font-figtree">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
        className="tw-relative tw-px-6 tw-py-6  sm:tw-flex md:tw-justify-center max-lg:tw-gap-8 lg:tw-gap-16">
        <div className="tw-text-landing-black tw-font-semibold">
          <div className="tw-text-[36px] lg:tw-text-[48px] xl:tw-text-[60px] tw-leading-tight">
            <p className="">Supercharge your</p>
            <p className="tw-text-landing-green">Sales on WhatsApp</p>
          </div>
          <div className="sm:tw-whitespace-pre tw-mt-4 tw-font-light tw-text-[15px] md:tw-text-[15px]  sm:tw-text-[18px] lg:tw-text-[22px]">Empower your sales team to maximize customer journeys,{"\n"}from carts and payments to promotion all-in-app
          </div>
          <div className="tw-mt-8 md:tw-mt-4 tw-bg-[#9746fe]/[0.1] tw-rounded tw-flex tw-gap-1 tw-items-center max-md:tw-justify-center tw-w-max tw-py-2 tw-px-3  max-md:tw-w-full">
            <img src={AILogo} className="tw-w-3" />
            <p className="tw-text-[#3A20D5] tw-text-[14px] md:tw-text-[18px] tw-font-medium">AI Powered Assistance to Help you Scale</p>
          </div>
          <ScheduleDemoButton className={"tw-mt-8 max-md:tw-w-full"} />
          <div className="tw-mt-12 tw-flex tw-gap-8 md:tw-gap-4 lg:tw-gap-8 tw-items-center max-md:tw-justify-between">
            <div>
              <div className="tw-text-[24px] md:tw-text-[22px] lg:tw-text-[28px] tw-flex tw-items-center">
                2-3x <IconArrow fill="green" className="tw-ml-2 tw-w-[26.5] tw-text-red-500" />
              </div>
              <div className="tw-text-[16px] md:tw-text-[15px] lg:tw-text-[18px] tw-font-medium">increased revenue</div>
              <div className="tw-text-[12px] lg:tw-text-[14px] tw-text-[#363031] tw-font-light">per sales person</div>
            </div>
            {
              screen.sm
                ? <div>
                  <div className="tw-text-[24px] md:tw-text-[22px] lg:tw-text-[28px] tw-flex tw-items-center">
                    25-30% <IconArrow fill="green" className="tw-ml-2 tw-w-6 tw-text-red-500" />
                  </div>
                  <div className="tw-text-[16px] md:tw-text-[15px] lg:tw-text-[18px] tw-font-medium">increased retention</div>
                  <div className="tw-text-[12px] lg:tw-text-[14px] tw-text-[#363031] tw-font-light">through loyalty program</div>
                </div>
                : null
            }
            <div>
              <div className="tw-text-[24px] md:tw-text-[22px] lg:tw-text-[28px] tw-flex tw-items-center">
                25-30% <IconArrow fill="red" className="tw-ml-2 tw-w-6 tw-text-red-500 tw-rotate-180" />
              </div>
              <div className="tw-text-[16px] md:tw-text-[15px] lg:tw-text-[18px] tw-font-medium">lower CAC</div>
              <div className="tw-text-[12px] lg:tw-text-[14px] tw-text-[#363031] tw-font-light">with easier onboarding</div>
            </div>
          </div>

        </div>

        <div className="max-sm:tw-max-w-[90%] md:tw-max-w-[38%]  2xl:tw-max-w-[30%] tw-display-block xl:tw--mt-4 max-lg:tw-mt-8 max-lg:tw-flex max-lg:tw-justify-center">
          <img src={ImgHome} className="tw-object-scale-down" />
        </div>
      </div>
      {/* client logos */}
      <div className="tw-bg-[#093232] tw-p-2">
        <LogoScroller images={client_logos} speed={15000} />
      </div>
      {/* Page 2 */}
      <HomePage2 />

      <HomePage3 />
      <HomePageTestimonials />

      {/* Page 7 */}

      <div
        className="tw-p-6 tw-py-16 tw-bg-[#052F2F] tw-text-white tw-flex tw-justify-center"
        style={{ backgroundImage: 'url(' + bgBoxes + ')', backgroundRepeat: 'repeat' }}>
        <div className="tw-text-center tw-font-semibold  tw-leading-tight tw-tracking-wider">
          <div className="tw-text-[24px] md:tw-text-[40px] tw-text-white">Get the reach of WhatsApp with
            <span className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre tw-text-landing-yellow ">
              {`\nthe depth of AI`}
            </span></div>
          <div className="xl:tw-flex tw-mx-4 md:tw-mx-24 tw-mt-12 tw-gap-8">
            <div className="tw-relative tw-bg-white tw-rounded-2xl ">
              <div className="tw-absolute tw-top-0 tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                {"Ticketing made easier"}
                <div className="tw-text-[14px] md:tw-text-[13px] lg:tw-text-[15px]  tw-mt-2 tw-text-[#666666] tw-font-light">
                  {"Receive, assign, and start addressing tickets from customers even while your team is off."}</div>
              </div>
              <img src={screen.md ? img_ai4 : img_m_ai4} className="tw-w-max" />
            </div>
            <div className="tw-relative tw-bg-white tw-rounded-2xl max-xl:tw-mt-8 ">
              <div className="tw-absolute tw-top-0 tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                {"Travel Concierge"}
                <div className="tw-text-[14px] md:tw-text-[13px] lg:tw-text-[15px]  tw-mt-2 tw-text-[#666666]  tw-font-light">
                  {"Share availability details for your hotel, travel service, or event and provide essential information to automate bookings."}</div>
              </div>
              <img src={screen.md ? img_ai3 : img_m_ai3} className="tw-w-max " />
            </div>
            <div className="tw-relative tw-bg-white tw-rounded-2xl max-xl:tw-mt-8">
              <div className="tw-absolute tw-top-0 tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                {"Be in the know"}
                <div className="tw-text-[14px] md:tw-text-[15px] tw-mt-2 tw-text-[#666666]  tw-font-light">
                  {"Get feedback, send surveys, request reviews and more over a quick chat conversation to gather valuable insights on your market and emerging opportunities."}</div>
              </div>
              <img src={screen.md ? img_ai2 : img_m_ai2} className="tw-w-max " />
            </div>
          </div>
          <div className="xl:tw-flex tw-mx-4 md:tw-mx-24 tw-mt-8 tw-gap-8">
            <div className="tw-relative tw-bg-white tw-rounded-2xl ">
              <div className="tw-absolute tw-top-0 tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                {"Personal Retail Companion"}
                <div className="tw-text-[14px] md:tw-text-[13px] lg:tw-text-[15px] tw-mt-2 tw-text-[#666666] tw-font-light">
                  {"Answer customer questions around product inventory and product information. Upsell, and cross-sell products while offering personalized recommendations based on purchase history. "}</div>
              </div>
              <img src={screen.md ? img_ai5 : img_m_ai5} className="tw-w-max " />
            </div>
            <div className="tw-relative tw-bg-white tw-rounded-2xl max-xl:tw-mt-8">
              <div className="tw-absolute tw-top-0 tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                {"Flow Builder"}
                <div className="tw-text-[12px] md:tw-text-[13px] tw-mt-2 tw-text-[#666666]  tw-font-light">
                  {"The AI Assistant PI lets you build conversational flows unique to how you interact with customers in your business and industry. Train AI to take on the role of customer success, support, concierge, researcher, and more."}</div>
              </div>
              <img src={screen.md ? img_ai1 : img_m_ai1} className="tw-w-max " />
            </div>
          </div>
        </div>
      </div>

      <div
        className="font-figtree tw-p-12 tw-bg-white tw-text-center"
      >
        <h1 className="tw-text-[28px] tw-text-[36px] tw-text-[#24292F] tw-font-semibold md:tw-whitespace-pre tw-leading-tight">
          {"Explore the future of\nsales management with WhatsApp"}
        </h1>
        <div className="tw-text-[20px] tw-text-[#6E6E73]">See Sukhiba in action</div>

        <div className="tw-justify-center tw-mt-8 tw-text-center tw-text-md">
          <button className="tw-rounded-full tw-bg-landing-green tw-text-white tw-px-9 tw-py-3.5"
            onClick={() => { window.open("/landing/contactus", "_self") }}>
            {"Schedule a demo"}
          </button>
        </div>
      </div>



    </div>
  );
}

export default HomePage;
