import React, { useState } from "react";
import { useBroadcastedState } from "base/utils/events";
import bgPage1 from "../../images/landing/page1_bg.svg"
import bgBoxes from "../../images/landing/boxes_bg.svg"
import ic_star from "../../images/landing/ic_soft_star.svg"
import ImgHome from "../../images/landing/partnership/page1.webp"
import ImgPage2 from "../../images/landing/partnership/page2.webp"
import ImgPage3 from "../../images/landing/partnership/page3.webp"
import PartnerPopup from "./PartnerPopup";
import SuccessPopUp from "./SuccessPopUp";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function PartnershipsPage() {
  const [screen] = useBroadcastedState("tw_screen_size");
  const [show_partner_popup, setShowPartnerPopup] = useState(false);
  const [show_success_popup, setShowSuccessPopup] = useState(false);


  return (
    <div className="tw-scroll-smooth tw-scrollbar-none font-figtree">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + bgPage1 + ')' }}
        className="tw-relative tw-px-8 tw-py-10 lg:tw-flex tw-justify-center lg:tw-gap-8 xl:tw-gap-16 tw-items-center">
        <div className="tw-text-landing-black font-figtree-semibold  lg:tw-ml-8">
          <div className="tw-text-[36px] md:tw-text-[60px] lg:tw-text-[52px] xl:tw-text-[60px] tw-leading-tight tw-tracking-wide">
            <p className="md:tw-whitespace-pre">Helping customers</p>
            <p className="md:tw-whitespace-pre tw-text-landing-green ">grow, together</p>
          </div>
          <div className="md:tw-whitespace-pre tw-mt-4 font-figtree !tw-font-light tw-text-[18px] md:tw-text-[22px] lg:tw-text-[20px] md:tw-leading-[32px] tw-text-landing-black">Become a Sukhiba partner and grow your earnings{"\n"}through commissions, or through custom solutions{"\n"}that we can help build.</div>
          <div className="tw-mt-8 tw-flex tw-gap-4 tw-items-center tw-text-center tw-text-[16px]">
            <button onClick={() => setShowPartnerPopup(true)}
              className="max-md:tw-flex-1 font-figtree tw-rounded-full tw-bg-landing-green tw-px-6 md:tw-px-12 tw-text-white tw-py-3.5">
              Partner With Us
            </button>
          </div>
        </div>
        <div className="lg:tw-max-w-[46%] xl:tw-max-w-[40%] max-lg:tw-my-12 max-lg:tw-flex max-lg:tw-justify-center">
          <LazyLoadImage src={ImgHome} className="" />
        </div>
      </div>
      <div className="font-figtree-semibold tw-text-[24px] md:tw-text-[40px] tw-text-center tw-py-10 tw-px-8 max-lg:tw-py-2 max-lg:tw-mb-8  tw-leading-tight"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
      >
        <span className="md:tw-whitespace-pre tw-text-landing-black">{`At Sukhiba, we're currently building`}</span>
        <span className="md:tw-whitespace-pre tw-text-landing-green ">{"\n"}two strong partnership networks</span>
      </div>

      {/* Page 2 */}
      <div
        className="font-figtree tw-p-8 md:tw-py-16 tw-bg-[#052F2F] tw-text-white "
        style={{ backgroundImage: 'url(' + bgBoxes + ')', backgroundRepeat: 'repeat' }}>
        <div
          className={`md:tw-flex tw-items-center tw-justify-center tw-gap-8 xl:tw-gap-16 tw-mb-10 max-lg:tw-flex-wrap `}>
          <div className="">
            <h1 className="tw-text-[24px] md:tw-text-[40px] lg:tw-text-[36px] xl:tw-text-[40px] font-figtree-semibold !tw-font-medium	tw-font tw-mb-2">
              Channel Partners
            </h1>
            <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre tw-text-white/[0.7]">
              {"Earn commissions through referrals. Invite contacts to sign\nup with your affiliate URL link. It’s that easy!"}
            </p>
            <div className="tw-text-[20px] md:tw-text-[24px] font-figtree-semibold tw-mt-8 tw-flex tw-items-center tw-gap-2">
              <img src={ic_star} className="md-max:tw-w-[23px]" />
              {`Join forces with us if you're a`}
            </div>
            <div className="tw-text-[16px] md:tw-text-[20px] tw-ml-2 tw-mt-4 tw-text-white/[0.7]">
              <div className="tw-mb-4 tw-flex tw-gap-4 tw-items-center ">
                <div className="tw-w-2 tw-h-2 tw-bg-[#FFB800] tw-rounded-full" />
                <div >{"Consultancy"}</div>
              </div>
              <div className="tw-mb-4 tw-flex tw-gap-4 tw-items-center">
                <div className="tw-w-2 tw-h-2 tw-bg-[#FFB800] tw-rounded-full" />
                <div >{"Media / advertising agency"}</div>
              </div>
              <div className="tw-mb-2 tw-flex tw-gap-4 tw-items-center">
                <div className="tw-w-2 tw-h-2 tw-bg-[#FFB800] tw-rounded-full" />
                <div >{"Content creator or influencer"}</div>
              </div>
            </div>
            <div className="md:tw-w-max tw-rounded-xl tw-bg-[#133A3A] tw-p-6 tw-mt-6" >
              <div className="tw-text-[18px] md:tw-text-[22px] !tw-font-light tw-mb-4 tw-flex tw-items-center tw-gap-2">
                <img src={ic_star} className="max-md:tw-hidden" />
                {`Omnichannel marketing for your clients`}
              </div>
              <p className="tw-text-[14px] md:tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-text-white/[0.62] !tw-font-light tw-mb-2 md:tw-whitespace-pre ">
                {"Erafold is a digital advertising agency and web\ndevelopment firm that partners with us to pitch Sukhibha\nofferings, inclusive of the broadcast feature to their clients"}
              </p>

              <p className="tw-text-[18px] md:tw-text-[22px] tw-text-white/[0.36] md:tw-whitespace-pre tw-italic  ">
                {"ERAFOLD  X  SUKHIBA"}
              </p>
            </div>
          </div>
          {/* <div className="md:tw-w-[44%]"> */}
          <div className=" max-lg:tw-mt-8 max-lg:tw-flex max-lg:tw-justify-center">
            <LazyLoadImage src={ImgPage2} className="max-lg:tw-mt-6" />
          </div>
        </div>
      </div>
      {/* Page 3 */}
      <div className="font-figtree tw-p-6 md:tw-py-12 tw-bg-white tw-text-landing-black ">
        <div
          className={`lg:tw-flex tw-justify-center tw-gap-10 xl:tw-gap-20 max-md:tw-flex-wrap `}
        >
          <div className=" max-lg:tw-mt-8 max-lg:tw-flex max-lg:tw-justify-center">
            <LazyLoadImage src={ImgPage3} className="tw-mt-6" />
          </div>
          <div className="md:tw-flex max-lg:tw-mt-8 tw-items-center tw-justify-center">
            <div >
              <h1 className="tw-text-[24px] md:tw-text-[40px] lg:tw-text-[36px] xl:tw-text-[40px] font-figtree-semibold tw-mb-2">
                Solution Partners
              </h1>
              <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre tw-font-light tw-text-black/[0.7]">
                {`Build with Sukhiba to grow your business on WhatsApp.\nIncrease your business’s technical capabilities.\nSolve for unique customer needs and use cases.`}
              </p>
              <div className="tw-text-[20px] md:tw-text-[24px] font-figtree-semibold tw-mt-6 tw-flex tw-items-center tw-gap-2">
                <img src={ic_star} className="max-md:tw-hidden" />
                {`Join forces with us if you're a`}
              </div>
              <div className="tw-text-[16px] md:tw-text-[20px] tw-ml-2 tw-mt-4 tw-text-black/[0.7]">
                <div className="tw-mb-4 tw-flex tw-gap-4 tw-items-center">
                  <div className="tw-w-2 tw-h-2 tw-bg-[#FFB800] tw-rounded-full" />
                  <div >{"Payments provider or gateway"}</div>
                </div>
                <div className="tw-mb-4 tw-flex tw-gap-4 tw-items-center">
                  <div className="tw-w-2 tw-h-2 tw-bg-[#FFB800] tw-rounded-full" />
                  <div >{"Media / advertising agency"}</div>
                </div>
                <div className="tw-mb-2 tw-flex tw-gap-4 tw-items-center">
                  <div className="tw-w-2 tw-h-2 tw-bg-[#FFB800] tw-rounded-full" />
                  <div >{"Reseller"}</div>
                </div>
              </div>
              <div className="md:tw-w-max md:tw--ml-3 tw-border tw-border-[#D1D1D1] tw-mt-4 tw-rounded-xl tw-p-6" >
                <div className="tw-text-[18px] md:tw-text-[22px] font-figtree tw-mb-2 tw-flex tw-items-center tw-gap-2">
                  {`Building personalized experiences with AI`}
                </div>
                <p className="tw-text-[14px] md:tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-mb-2 tw-text-[#4D4D4D] md:tw-whitespace-pre ">
                  {"Silverstone Tyres partnered with Sukhiba to build a custom\nconversational flow. Now, customers get custom tyre\nrecommendations and real-time support on WhatsApp powered\nby the Sukhiba AI Assistant."}
                </p>

                <p className="tw-text-[18px] md:tw-text-[22px] tw-text-black/[0.36] md:tw-whitespace-pre tw-italic">
                  {"SILVERSTONE TYRES  X  SUKHIBA"}
                </p>
              </div>
            </div>
          </div>


        </div>
      </div>
      <PartnerPopup show={show_partner_popup} setShow={setShowPartnerPopup} onFormSubmit={() => {
        setShowPartnerPopup(false)
        setShowSuccessPopup(true)
      }} />
      <SuccessPopUp show={show_success_popup} setShow={setShowSuccessPopup} />

    </div>
  );
}

export default PartnershipsPage;
