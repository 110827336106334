import { faArrowPointer, faCartPlus, faCommentDots, faPercentage, faTicketSimple, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ImgHome from "../../images/landing/b2b/b2b_home.webp"
import { useBroadcastedState } from "base/utils/events";
import bgPage1 from "../../images/landing/page1_bg.svg"
import bgPage2 from "../../images/landing/page2_bg.svg"
import bgBoxes from "../../images/landing/boxes_bg.svg"
import ic_star from "../../images/ic_landing_star.svg"
import ic_soft_star from "../../images/landing/ic_soft_star.svg"

import b2b_page2 from "../../images/landing/b2b/page_2.webp"
import img_page3 from "../../images/landing/b2b/page_3.webp"
import img_page4 from "../../images/landing/b2b/page_4.webp"
import img_page5 from "../../images/landing/b2b/page_5.webp"
import img_page7 from "../../images/landing/b2b/page_7.webp"

import ic_users from "../../images/ic_landing_users.svg"
import ic_swap from "../../images/landing/ic_swap.svg"
import ic_filter from "../../images/landing/ic_filter.svg"

import integration_1 from "../../images/landing/integration_1.png"
import integration_2 from "../../images/landing/integration_2.png"
import integration_3 from "../../images/landing/integration_3.png"
import integration_4 from "../../images/landing/integration_4.png"
import integration_5 from "../../images/landing/integration_5.png"



const features_list = ["Field Sales Monitoring", "Inventory Management", "Payments and Rewards", "Send Cart", "Activity Tracking"]
const integration_icons = [integration_1, integration_2, integration_3, integration_4, integration_5]

function Page2() {
  return <div
    id="features"
    style={{ backgroundImage: 'url(' + bgPage2 + ')', backgroundSize: 'cover' }}
    className="tw-p-8 md:tw-p-10 font-figtree tw-text-landing-black tw-grid tw-justify-items-center">
    <div className="tw-text-center">
      <div className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre tw-font-semibold tw-flex-1 tw-my-2">
        Plug and Play Resource Management
      </div>
      <div className="tw-text-[18px] md:tw-text-[22px]">
        Plug your ERP of choice & play with your inventory to identify trends and insights to drive sales      </div>
    </div>
    <div className={`tw-border-landing-gray lg:tw-flex tw-justify-center max-xl:tw-gap-4 xl:tw-gap-10 tw-my-8 md:tw-my-12`}>
      <div className="tw-px-4 xl:tw-px-8 tw-py-4 tw-text-center tw-bg-white tw-border tw-rounded-xl xl:tw-w-[520px]">
        <p className="tw-text-[18px] md:tw-text-[22px] tw-text-landing-green tw-mb-2">
          {"GET SET"}
        </p>
        <p className="tw-text-[24px] md:tw-text-[28px] tw-text-black tw-font-semibold tw-mb-2">
          {"Integrate Apps"}
        </p>
        <p className="tw-text-[14px] md:tw-text-[18px] tw-mb-2 md:tw-whitespace-pre tw-text-[#666666]">
          {"ERP and Catalog integrations include Zoho, Microsoft\nDynamics, Oracle, Sap, Shopify, Woo, Odoo. "}
        </p>
        <p className="tw-text-[14px] md:tw-text-[18px] tw-font-semibold tw-text-black tw-mb-2">
          {"and 20 more"}
        </p>

        <div className="tw-flex tw-gap-2 md:tw-gap-4 tw-mt-8 tw-justify-center">
          {integration_icons.map((icon, j) => (
            <div key={j}>
              <img
                style={{ width: 52, height: 52 }}
                src={icon}
              />
            </div>
          ))}
        </div>
        <div className="tw-text-[16px] md:tw-text-[22px] tw-flex tw-gap-6 tw-justify-center !font-figtree-medium tw-my-6">
          <div className="tw-border-t tw-border-landing-black tw-w-[100px] tw-mt-3 md:tw-mt-4" />OR<div className="tw-border-t tw-border-landing-black tw-w-[100px] tw-mt-3 md:tw-mt-4" />
        </div>
        <p className="tw-text-[24px] md:tw-text-[28px] tw-text-black font-figtree-semibold tw-mb-2">
          {"Set up a no-code storefront"}
        </p>
        <p className="tw-text-[14px] md:tw-text-[18px] tw-mb-2">
          {"Give retailers one-click access to your catalog"}
        </p>
      </div>

      <div className="max-lg:tw-mt-8 tw-pt-4 tw-text-center tw-bg-white tw-border tw-rounded-xl xl:tw-w-[520px]">
        <p className="tw-text-[16px] md:tw-text-[22px] tw-text-landing-green tw-mb-2 tw-px-4 md:tw-px-8 ">
          {"GO"}
        </p>
        <p className="tw-px-8 tw-text-[24px] md:tw-text-[28px] tw-text-black font-figtree-semibold tw-mb-2 tw-px-4 md:tw-px-8 ">
          {"Promote and Sell to optimize inventory"}
        </p>
        <p className="tw-text-[14px] md:tw-text-[18px] tw-px-8 tw-text-[#666666] md:tw-whitespace-pre tw-px-4 md:tw-px-8">
          {"Send promotions and discounts across retailers\nor cater to unique retailer segments and create\nengagement using AI-powered criteria."}
        </p>
        <img src={b2b_page2} className={"tw-w-wrap tw-rounded-b-xl"} />
      </div>

    </div>
  </div>
}

function B2BPage() {
  const [screen] = useBroadcastedState("tw_screen_size");

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none  font-figtree">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
        className="tw-relative max-lg:tw-p-8 lg:tw-p-10 lg:tw-flex md:tw-justify-center xl:tw-gap-16 tw-items-center">
        <div className="tw-text-landing-black">
          <div className="tw-text-[36px] md:tw-text-[60px] lg:tw-text-[46px] xl:tw-text-[60px] tw-font-semibold tw-leading-tight">
            <span className="tw-text-landing-green">Grow sales, </span>
            <span className="md:tw-whitespace-pre">without{"\n"}scaling your sales team</span>
          </div>
          <div className="md:tw-whitespace-pre tw-mt-4 tw-font-light tw-text-[18px] md:tw-text-[22px]">Boost sales team productivity with end-to-end customer{"\n"}management, commerce, payments processing, and more all{"\n"}on the unified WhatsApp business platform</div>
          <div className="tw-mt-8 tw-flex tw-gap-4 tw-items-center tw-text-center ">
            <div
              className="max-lg:tw-flex-1 tw-rounded-full tw-bg-landing-green tw-px-6 tw-text-white tw-py-3.5"
              onClick={() => {
                window.open("/contactus", "_self")
              }}>
              Schedule a demo
            </div>
          </div>
        </div>
        <div className="lg:tw-max-w-[46%] xl:tw-max-w-[40%] max-lg:tw-my-4 max-lg:tw-flex max-lg:tw-justify-center">
          <img src={ImgHome} className="" />
        </div>
      </div>
      <div className="tw-bg-[#093232] tw-py-4 tw-px-6 tw-gap-4 tw-flex w3-hide-scrollbar tw-overflow-x-auto xl:tw-justify-center">
        {
          features_list.map((feature, i) => {
            return <div key={i} className="max-xl:tw-mx-4 tw-flex tw-gap-2 tw-text-white tw-text-[18.5px] md:tw-text-[24.5px] tw-whitespace-nowrap">
              <img src={ic_star} className="tw-w-[22px]" /> {feature}
            </div>
          })
        }
      </div>
      {/* Page 2 */}
      <Page2 />

      {/* Page 3 */}
      <div
        className="font-figtree tw-p-8 lg:tw-py-16 tw-bg-[#052F2F] tw-text-white "
        style={{ backgroundImage: 'url(' + bgBoxes + ')' }}>
        <div
          className={`lg:tw-flex max-xl:tw-grid tw-items-center tw-justify-center tw-gap-8 xl:tw-gap-16  tw-mb-10 `}>
          <div className="">
            <div className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre !tw-font-medium tw-mb-2">
              {"Making"} <span className="tw-text-landing-yellow">Payments </span>
              {"Secure,\nStreamlined, and Simplified"}
              {/* <img src={ic_soft_star} className="md-max:tw-w-[23px]" /></span> */}
            </div>
            <p className="tw-text-[14px] md:tw-text-[22px] md:tw-whitespace-pre tw-text-white/[0.7]">
              {"Accept payments across major platforms and generate\ninstant sales orders anytime an order is placed "}
            </p>
            <div className="tw-text-[16px] md:tw-text-[20px] tw-ml-2 tw-mt-8 tw-text-white md:tw-whitespace-pre ">
              <div className="tw-mb-6 tw-flex tw-gap-4 tw-items-start">
                <FontAwesomeIcon icon={faWallet} color="#FFB800" className="tw-mt-1" />
                <div className="">{"Give credit where due"}
                  <div className="tw-text-[14px] tw-mt-2 tw-text-white/[0.7] md:tw-text-[18px] tw-font-light">
                    {"Add credits for key retailers based on trust and historical\npartnerships to strengthen your business relationships"}</div>
                </div>
              </div>
              <div className="tw-mb-6 tw-flex tw-gap-4 tw-items-start">
                <FontAwesomeIcon icon={faTicketSimple} color="#FFB800" className="tw-mt-1" />
                <div className="">{"Rewards and giveaways"}
                  <div className="tw-text-[14px] tw-mt-2 tw-text-white/[0.7] md:tw-text-[18px] tw-font-light">
                    {"Generate QR codes so customers can onboard, access, track,\nand redeem loyalty points within their WhatsApp wallet"}</div>
                </div>
              </div>
              <div className="tw-flex tw-gap-4 tw-items-start">
                <FontAwesomeIcon icon={faPercentage} color="#FFB800" className="tw-mt-1" />
                <div className="tw-ml-1">{"Distribute discounts"}
                  <div className="tw-text-[14px] tw-mt-2 tw-text-white/[0.7] md:tw-text-[18px] tw-font-light">
                    {"Create, manage, and track discounts across various broadcasts\nand for specific customers in a single dashboard"}</div>
                </div>
              </div>

            </div>
          </div>
          <div >
            <img src={img_page3} className="max-md:tw-mt-6"  />
          </div>
        </div>
      </div>
      {/* Page 4 */}
      <div
        className="font-figtree tw-p-8 md:tw-py-16 tw-bg-white tw-text-black ">
        <div
          className={`lg:tw-flex max-lg:tw-grid tw-items-center tw-justify-center tw-gap-8 xl:tw-gap-16 tw-mb-10 max-md:tw-flex-wrap `}>
          <div className="">
            <img src={img_page4} className="" />
          </div>
          <div className="max-lg:tw-mt-12">
            <div className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre !tw-font-medium tw-mb-2">
              {"Analytics and AI"}
            </div>
            <p className="tw-text-[14px] md:tw-text-[22px] md:tw-whitespace-pre">
              {"The AI Flow assistant seamlessly takes on the role\nof a customer support or sales assistant: "}
              <span className="tw-text-landing-green">{"answering\nquestions, sharing catalog details, and driving sales."}</span>
            </p>
            <div className="tw-text-[16px] md:tw-text-[20px] tw-ml-2 tw-mt-8 md:tw-whitespace-pre ">
              <div className="tw-mb-6 tw-flex tw-gap-4 tw-items-start">
                <img src={ic_users} className="tw-mt-1 tw-w-[26px]" />
                <div className="tw-text-[14px] md:tw-text-[18px]">
                  {"Keep on the pulse of the business as you track your\ninventory and your team's progress"}</div>
              </div>
              <div className="tw-mb-6 tw-flex tw-gap-4 tw-items-start">
                <img src={ic_swap} className="tw-mt-1 tw-w-[26px]" />
                <div className="tw-text-[14px] md:tw-text-[18px]">
                  {"Offer customers smart recommendations and cross-\nselling suggestions using AI"}</div>
              </div>
              <div className="tw-mb-6 tw-flex tw-gap-4 tw-items-start">
                <img src={ic_filter} className="tw-mt-1 tw-w-[26px]" />
                <div className="tw-text-[14px] md:tw-text-[18px]">
                  {"Segment retailers by attributes based on their\nengagement"}</div>
              </div>

              <div className="tw-mb-6 tw-flex tw-gap-4 tw-items-center tw-border tw-border-[#D1D1D1] tw-rounded-xl tw-p-4">
                <img src={ic_soft_star} className="tw-w-[35px]" />
                <div className="tw-text-[16px] md:tw-text-[22px]">
                  {"Be available to the world on "}<span className="tw-text-landing-green tw-font-medium">1 single number,</span>{"\nroute internally to the right sales support"}</div>
              </div>

            </div>
          </div>
        </div>

        <div
          className={`tw-flex  tw-items-center tw-justify-center tw-gap-8 xl:tw-gap-16 tw-mt-12 lg:tw-mt-32 max-lg:tw-flex-col-reverse`}>
          <div className="">
            <div className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre !tw-font-medium tw-mb-2">
              {"Transform Sales with Speed:\n"}
              <span className="tw-text-landing-green md:tw--ml-2"> {"Virtual Carts for Rapid Checkout"} </span>
            </div>
            <div className="tw-text-[#133A3A] tw-text-[18px] md:tw-text-[22px] tw-font-medium">Shorten Sales Cycles from 5 hours to 5 minutes</div>

            <div className="tw-my-6 tw-flex tw-gap-4 tw-items-center tw-bg-[#FFF5F5] tw-rounded-xl tw-p-4">
              <p className="tw-text-[16px] md:tw-text-[20px] md:tw-whitespace-pre tw-font-light">
                {"Today, sales staff may have"}
                <span className="tw-text-[#C11515]">{" 5-6 hour conversation windows\n"}</span>
                {"when placing orders with retailers, across multiple ERP and\ncommunication platforms."}
              </p>
            </div>

            <p className="tw-text-[16px] md:tw-text-[20px] md:tw-whitespace-pre tw-font-light">
              {"With Sukhiba's AI Assistant, you can boost sales productivity\nby turning incoming WhatsApp queries from retailers into an\ninteractive conversation and online cart that’s ready for\ncheckout, in under 5 minutes"}
            </p>
            <p className="tw-text-[16px] md:tw-text-[20px] tw-mt-6 md:tw-whitespace-pre tw-font-light">
              {"Draw from online catalogs sharing, historical purchases, and\nretailer location, to create a custom cart"}
            </p>
          </div>
          <div className="">
            <img src={img_page5} className="max-md:tw-mt-6" />
          </div>

        </div>
      </div>


      {/* Page 5 */}
      <div className="tw-p-8 md:tw-p-16 tw-text-center"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
      >
        <div className="tw-text-landing-black !tw-font-semibold tw-text-[28px] lg:tw-text-[40px] tw-leading-tight tw-tracking-wide">
          <span className="lg:tw-whitespace-pre">{`1 number,`}</span>
          <span className="tw-text-landing-green md:tw-whitespace-pre"> {`many conversations`}</span>
        </div>
        <div className="md:tw-whitespace-pre lg:tw-text-[22px] tw-font-light tw-text-landing-black tw-mt-4">
          {"Integrate multiple warehouses, locations, and prices\nvia a single number to provide custom pricing"}
        </div>
        <div className="tw-justify-center font-figtree tw-mt-12 md:tw-flex tw-gap-8 ">
          <div className="tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
              <FontAwesomeIcon icon={faCartPlus} className="tw-w-5 tw-text-landing-green" />
            </div>
            <div className="tw-text-[20px] font-figtree-semibold tw-text-black tw-my-2">Buy for Customer</div>
            <div className="tw-text-[16px] tw-font-light tw-text-[#666666]">{`Manage conversations across multiple\nretailers and sales agents with one\nWhatsApp number. Help retailers build\ncarts efficiently and edit orders to\ndrive to checkout`}</div>
          </div>
          <div className="max-md:tw-my-8 tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
              <FontAwesomeIcon icon={faCommentDots} className="tw-w-5 tw-text-landing-green" />
            </div>
            <div className="tw-text-[20px] font-figtree-semibold tw-text-black tw-my-2">Contact for Price</div>
            <div className="tw-text-[16px] tw-font-light tw-text-[#666666]">{`Maintain a personalized approach to\npricing inquiries by listing specific\nitems as 'contact for price' and\ncapture retailer leads, early in\nyour sales funnel`}</div>
          </div>
          <div className="tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
              <FontAwesomeIcon icon={faArrowPointer} className="tw-w-5 tw-text-landing-green" />
            </div>
            <div className="tw-text-[20px] font-figtree-semibold tw-text-black tw-my-2">One-Click Checkout</div>
            <div className="tw-text-[16px] tw-font-light tw-text-[#666666]">{`Empower retailers with a convenient\nshopping experience making it easier\nfor retailers to do business with you,\nwhile  building your relationship`}</div>
          </div>
        </div>

        <div className="tw-text-landing-black tw-mt-16 !tw-font-semibold tw-text-[28px] lg:tw-text-[40px] tw-leading-tight tw-tracking-wide">
          <span className="lg:tw-whitespace-pre">{`Track Field Activity and Optimize Logistics`}</span>
        </div>
        <div className="md:tw-whitespace-pre lg:tw-text-[22px] tw-font-light tw-text-landing-black tw-mt-4">
          {"Simplify and manage your sales and delivery processes"}
        </div>
        <div className="lg:tw-flex max-lg:tw-grid tw-items-center tw-mt-6 lg:tw-mt-12 tw-justify-center tw-gap-12">
          <div className="">
            <img src={img_page7} className="max-lg:tw-mt-6" />
          </div>

          <div className="font-figtree md:tw-mt-12 tw-text-start">
            <div className="tw-flex tw-items-center tw-gap-4 tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-4 md:tw-w-[547px]">
              <div className="tw-flex tw-px-4 tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
                <FontAwesomeIcon icon={faArrowPointer} className="tw-text-landing-green" />
              </div>
              <div>
                <div className="tw-text-[16px] md:tw-text-[20px] font-figtree-semibold tw-text-black tw-mb-1">Assign & Track Smart Routes</div>
                <div className="tw-text-[14px] md:tw-text-[18px] tw-font-light tw-text-[#666666]">{`Place staff on high-impact routes with efficient resource allocation and comprehensive geo-fencing`}</div>
              </div>
            </div>
            <div className="tw-my-4 tw-flex tw-items-center tw-bg-white tw-gap-4 tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-4 md:tw-w-[547px]">
              <div className="tw-flex tw-px-4 tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
                <FontAwesomeIcon icon={faArrowPointer} className="tw-w-5 tw-text-landing-green" />
              </div>
              <div>
                <div className="tw-text-[16px] md:tw-text-[20px] font-figtree-semibold tw-text-black tw-mb-1">Offer Transparency</div>
                <div className="tw-text-[14px] md:tw-text-[18px] tw-font-light tw-text-[#666666]">{`Live statuses on last-mile deliveries for retailers and\ncustomers`}</div>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-gap-4 tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-4 md:tw-w-[547px]">
              <div className="tw-flex tw-px-4 tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
                <FontAwesomeIcon icon={faArrowPointer} className="tw-w-5 tw-text-landing-green" />
              </div>
              <div>
                <div className="tw-text-[16px] md:tw-text-[20px] font-figtree-semibold tw-text-black tw-mb-1">Set Preferred Payments</div>
                <div className="tw-text-[14px] md:tw-text-[18px] tw-font-light tw-text-[#666666]">{`Set and direct payments to your preferred\npayment method`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B2BPage;
