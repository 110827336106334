import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactSwipe from 'react-swipe';
import axios from 'axios';
import { useCurrentUser } from "../app";
import { EmptyView, LoadingView } from "./status";
import {
    faCog, faTimes, faBellSlash, faBell, faPlus, faSmile, faMapLocation, faCircleArrowRight,
    faMinimize, faEllipsisV, faEdit, faChevronDown, faSpinner, faShare, faCheckDouble, faCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getByIds } from "../get_by_ids";
import { ThumbnailFileView } from "./misc";
import { LocalStorageCache, isEmptyObject, isFunction, isString, last, popKey, setKeyPath } from "../utils";
import { getRandomColor, useRerender } from '../utils/common';
import { playSound } from '../utils/audio';
import { DateView } from './date';
import { FileUploader, validateAndUploadFile } from '../file_uploader';
import { Popup, createPopupsContainer } from './popups';
import ProfileAvatar from "../images/avatar.png"
import { EMOJIS, MILLIS_IN_HOUR } from '../constants';
import { GenericException } from './errors';
import { broadcaster, useBroadcastedState } from '../utils/events';
import { URL_REGEX, getUKey, useOnScroll } from './utils';
import { EVENT_ALLSPARK_MESSAGE_RECEIVED, isAllsparkConnected } from '../allsparkrt';
import CHAT_DEFAULT_BG_IMAGE from '../images/chat_bg.jpg'
import NEW_PING_SOUND from "../audio/ping.mp3"
import "../css/chat.css"
import Clipboard from '../utils/clipboard';
import {binarySearch, sortedList} from '../utils/array';


const SESSION_USER_ADMIN = 10;
const SESSION_USER_MODERATOR = 15;
const SESSION_USER_GENERAL = 20;
const SESSION_USER_PENDING = 30
const SESSION_USER_BANNED = 40
const SESSION_USER_UNAUTHORIZED = 50



const INBOX_MESSAGE_TYPE_SIMPLE_MESSAGE = 1003;
const INBOX_MESSAGE_TYPE_SESSION_USER_UPDATE = 1010;
const INBOX_MESSAGE_TYPE_WA_MSG = 1018;
const INBOX_MESSAGE_TYPE_WA_REPLY_MSG = 1019;
const INBOX_MESSAGE_TYPE_WA_INCOMING_MSG = 1020;
const INBOX_MESSAGE_TYPE_WA_TEMPLATE_MSG = 1021;
const INBOX_MESSAGE_TYPE_WA_INFO = 1022;

const NOTIFICATION_FLAG_ONLY_WHEN_TAGGED = 20;
const NOTIFICATION_FLAG_ALL = 30;

const TYPE_ERROR = -1;
const TYPE_INBOX_ENTRY_ADDED = 13

var url_previews_cache = new LocalStorageCache("url_previews", 50);

broadcaster.add_event_listener(
    EVENT_ALLSPARK_MESSAGE_RECEIVED,
    (msg) => {
        if(broadcaster.broadcast_event("cs_" + msg.dest_session_id, msg) === 0){
            if(broadcaster.broadcast_event("chat_not_handled", msg) === 0){
                playSound(NEW_PING_SOUND);
                if(isString(msg.payload)){
                    const user_id = msg.src_id;
                    getByIds({"user_ids": [user_id]}).then(
                        (data) => {
                            Popup.toast(
                                <div onClick={() => ChatSession.open(msg.dest_session_id)} >
                                  {`${data.users[user_id]?.name || "🗨"}: ${msg.payload}`}
                                </div>,
                                6000
                            )
                        }
                    );
                }
            }
        }
    }
);

function DateViewWithInfo({im, session_data, cur_user_message}){
    const is_user_in_session = session_data.is_user_in_session;
    return (
        <div className='w3-flex w3-right'>
            {
                im.info
                ?   <div className="w3-tiny">
                        {
                            im.info.wa_r 
                            ?   <FontAwesomeIcon icon={faCheckDouble} className='w3-text-blue' />
                            :   im.info.wa_d
                                ?   <FontAwesomeIcon icon={faCheckDouble} />
                                :   im.info.wa_s || im.info.s1
                                    ?   <FontAwesomeIcon icon={faCheck} />
                                    :   im.info.wa_ns
                                        ?   <FontAwesomeIcon icon={faTimes} />
                                        :   null
                        }
                    </div>
                :   null
            }
            <DateView
                millis={im.created_at} className="w3-tiny w3-text-grey"
                onClick={
                    cur_user_message
                    || (
                        is_user_in_session?.session_user_type <= SESSION_USER_MODERATOR
                    )
                        ?   (evt) => {
                                evt.stopPropagation();
                                Popup.showContextMenu(
                                    evt.target,
                                    <InboxMessageInfo inbox_message={im} />
                                )
                            }
                        :   null
                }
            />
        </div>
    );
}

function InboxMessageInfo({inbox_message:im}){
    const [info, setInfo] = useState(null);
    const [errors, setErrors] = useState(null);

    useEffect(
        () => {
            axios.get(
                "/session/im_info",
                {"params": {"session_id": im.inbox_id, "created_at": im.created_at}}
            ).then(
                (resp) => {
                    resp.data.errors && setErrors(resp.data.errors);
                    resp.data.info && setInfo(resp.data.info);
                }
            )
        }, []
    );
    if(errors){
        return <GenericException ex={errors} />
    }
    if(!info){
        return <LoadingView title="" height="100px" />
    }
    if( Object.keys(info).length === 0){
        return <div className='w3-padding'>Sorry No information available</div>
    }
    return (
        <table className='w3-table'>
            <tbody>
                {
                    info.s0
                    ?   <tr><td>Templated</td><td>{info.s0}</td></tr>
                    :   null
                }
                {
                    info.s1
                    ?   <tr><td>Server Sent</td><td>{info.s1}</td></tr>
                    :   null
                }
                {
                    info.wa_f
                    ?   <tr><td>Facebook failed</td><td>{info.wa_f}</td></tr>
                    :   null
                }
                {
                    info.wa_ns
                    ?   <tr><td>Not Sent/No credits</td><td>{info.wa_ns}</td></tr>
                    :   null
                }
                {
                    info.wa_s
                    ?   <tr><td>Facebook Sent</td><td>{info.wa_s}</td></tr>
                    :   null
                }
                {
                    info.wa_d
                    ?   <tr><td>Facebook Delivered</td><td>{info.wa_d}</td></tr>
                    :   null
                }
                {
                    info.wa_r
                    ?   <tr><td>Facebook Read</td><td>{info.wa_r}</td></tr>
                    :   null
                }
                {
                    info.wa_click && Object.entries(info.wa_click).map(
                        ([k, v]) => <tr><td>Click: {k}</td><td>{v}</td></tr>
                    )
                }
            </tbody>             
        </table>
    );
}

function SimpleMessageContextMenu({im, session_data}){
    const copyMessage = () => {
        /* TODO: per message processing here */
        Clipboard.copy(im.payload);
    }

    return (
        <div className="w3-list w3-list-bordered">
            <div className='w3-padding-8' onClick={copyMessage}>Copy</div>
            {SimpleMessageContextMenu.Options && <SimpleMessageContextMenu.Options im={im} session_data={session_data} />}
        </div>
    );
}

function SimpleMessage({session_data, user, im, txt, action_txt, files, tags, created_at, header}){
    const cur_user_message = !im.src_id || im.src_id == user?._id;
    const is_user_in_session = session_data?.is_user_in_session;
    txt = txt || im.payload
         
    if(txt && (!im.is_scanned_for_links || im.txt !== txt)){
        /* one time check: super important */
        im.is_scanned_for_links = true;
        im.txt = txt.replace(
            URL_REGEX,
            (url) => `<a href="${url}" target="_blank">${url}</a>`
        );
    }
    const header_context = im.data?.context;
    return (
        <div className='w3-row w3-margin-8-16'>
            <div className={`${cur_user_message ? "w3-right": "w3-left"} w3-bounded w3-relative`}>
                {header}
                <div className={`w3-margin-topbottom-2 ${cur_user_message ? "app-chat-speech-bubble-right": "app-chat-speech-bubble-left"}`}>
                    <span className="app-chat-speech-bubble-tail-container"></span>    
                    <div className='w3-display-topright w3-padding-sides-8 w3-small'
                        onClick={
                            (evt) => Popup.showContextMenu(evt.target, <SimpleMessageContextMenu im={im} session_data={session_data}/>)
                        }
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    {
                        /* forwarded / frequently forwarded */
                        (header_context && (header_context.forwarded || header_context.frequently_forwarded))
                        ?  <div className='w3-display-container w3-margin-right'>
                                <FontAwesomeIcon icon={faShare} className='w3-text-gray w3-margin-left-8' />
                                <span className='w3-margin-right w3-text-gray'>
                                    {im.data.context.frequently_forwarded ? 'Forwarded many times' : 'Forwarded'}
                                </span>
                            </div>
                        : null
                    }
                    {
                        im.user
                        ?   <div className="w3-small"
                                style={{"color": getRandomColor(im.user.name), paddingLeft: 8, paddingRight: 28}}
                            >
                                {im.user.name}
                            </div>
                        :  null
                    }
                    {
                        files?.map(
                            (file, i) => <ThumbnailFileView file={file} key={i} />
                        )
                    }
                    {
                        im.data?.location ? <div className='w3-small w3-padding-4'>
                            <span className='w3-margin-right-8'>{im.data.location.address || "Location"}</span>
                            <FontAwesomeIcon icon={faMapLocation} className='w3-text-blue'
                                onClick={() => {
                                    window.open(`https://maps.google.com/?q=${im.data.location.latitude},${im.data.location.longitude}`, '_blank')
                                }} />
                        </div> : null
                    }
                    {
                        im.txt 
                        ?   <div className="w3-medium w3-padding-2-8 w3-can-wrap w3-word-wrap"
                                dangerouslySetInnerHTML={{__html: im.txt}} 
                            />
                        :   action_txt
                            ?   <div className="w3-small w3-text-grey w3-padding-2-8 w3-can-wrap w3-word-wrap"
                                    style={{fontStyle: 'italic'}}
                                    dangerouslySetInnerHTML={{__html: action_txt}} 
                                />
                            :   null
                    }
                    {
                        im?.data?.reactions
                        ?  <div className='w3-flex-inline w3-white w3-border w3-round-xxlarge'>
                            {
                                Object.entries(im.data.reactions).map(
                                    ([_user_id, reaction]) =>  <div key={_user_id}>{reaction.emoji}</div>
                                )
                            }
                        </div>
                        : null
                    }
                    {
                        tags?.length
                        ?   <div className="w3-padding-4 w3-small w3-flex-row-wrap w3-list-horizontal">
                                {
                                    tags.map(
                                        (tag, i) => <div key={i} className="w3-tag w3-round-xxlarge">{tag}</div>
                                    )
                                }
                            </div>
                        :   null
                    }
                </div>
                <DateViewWithInfo im={im} cur_user_message={cur_user_message} session_data={session_data} />                
            </div>
        </div>
    );
}

function InteractiveMessage({session_data, is_user_in_session, cur_user_message, user, im}){
    if(im.data.fb.interactive?.type == "button" || im.data.fb.interactive?.type == "list" ){
        return (
            <div className='w3-row w3-margin-8-16'>
                <div className={`${cur_user_message ? "w3-right": "w3-left"} w3-bounded`}>
                    <div className={`w3-margin-topbottom-2 ${cur_user_message ? "app-chat-speech-bubble-right": "app-chat-speech-bubble-left"}`}>
                        <span className="app-chat-speech-bubble-tail-container"></span>
                        {
                            im.user
                            ?   <div className="w3-small w3-padding-sides-8"
                                    style={{"color": getRandomColor(im.user.name)}}
                                >
                                    {im.user.name}
                                </div>
                            :   null
                        }
                        {
                            im.data.fb.interactive.header?.image?.link
                            ?   <img src={im.data.fb.interactive.header.image.link} className="w3-image" />
                            :   null
                        }
                        <div className="w3-medium w3-padding-2-8 w3-can-wrap w3-word-wrap">{im.data.fb.interactive.body?.text}</div>
                        <div className="w3-padding-4 w3-small w3-flex-row-wrap w3-list-horizontal">
                        {
                            im.data.fb.interactive.action?.buttons?.map(
                                (button_data) => {
                                    if(button_data.type == "reply"){
                                        let {id, title} = button_data.reply;
                                        return (
                                            <div key={id} className="w3-tag w3-round-xxlarge">{title}</div>
                                        )
                                    }
                                    return null;
                                }
                            )
                        }
                        {
                            im.data.fb.interactive.action?.sections?.map(
                                (sections_data) => {
                                    return <div key={getUKey(sections_data)}>
                                        <p>{sections_data.title}:</p>
                                        {sections_data.rows?.map((rows) => {
                                            let { id, title } = rows;
                                            return (
                                                <div key={id} className="w3-tag w3-round-xxlarge w3-margin-2">{title}</div>
                                            )
                                        })}
                                    </div>
                                }
                            )
                        }
                        </div>
                        {
                            im?.data?.reactions
                            ?  <div className='w3-flex-inline w3-white w3-border w3-round-xxlarge'>
                                {
                                    Object.entries(im.data.reactions).map(
                                        ([_user_id, reaction]) =>  <div key={_user_id}>{reaction.emoji}</div>
                                    )
                                }
                            </div>
                            : null
                        }
                    </div>
                    <DateViewWithInfo im={im} cur_user_message={cur_user_message} session_data={session_data} />                
                </div>
            </div>
        );
    }
}

function TemplateMessage({im, cur_user_message, session_data}){
    return (
        <div className="w3-center w3-row w3-margin-4">                
            <div className="w3-inline w3-2px-shadow w3-border w3-light-grey w3-padding-4-8"
                onClick={() => Popup.show("Payload", <pre>{JSON.stringify(im.data, null, 4)}</pre>)}
            >
                <div>Sent a templated message <b>{im.data.fb.template.name.toTitleCase()}</b></div>
                <DateViewWithInfo im={im} cur_user_message={cur_user_message} session_data={session_data} />
            </div>
        </div>
    );
}

/* TODO: BATCH THESE CALLS */
function UrlPreview({ url, image_url }) {
    const [url_preview, setUrlPreviews] = useState(null)
    useEffect(
        () => {
            if(!url || !isString(url) || !url.startsWith("http")) return;
            const url_preview = url_previews_cache.get(url);
            if(url_preview) setUrlPreviews(url_preview);
            else {
                axios.get(`/url_preview?url=${url}`).then(
                    (resp) => {
                        setUrlPreviews(resp.data);
                        url_previews_cache.set(url, resp.data);
                    }
                );
            }
        }, []
    );
    if(!url_preview && !image_url) return null;
    return (
        <div onClick={()=>{window.open(url)}}>
            {(url_preview?.image || image_url) && <img className=" w3-border"
                style={{
                    "objectFit": "cover",
                    "marginRight": "4px",
                }}
                src={url_preview?.image || image_url} />
            }
            <p className='w3-padding-2'>{url_preview?.title}</p>
            <p className='w3-padding-2 w3-tiny w3-text-grey'>{url_preview?.description}</p>
        </div>
    )
}

function InboxMessage({inbox_message: im, user, session_data}){
    const cur_user_message = !im.src_id || im.src_id == user?._id;
    const is_user_in_session = session_data.is_user_in_session;

    if(im._type == INBOX_MESSAGE_TYPE_SIMPLE_MESSAGE){
        return <SimpleMessage 
            user={user} im={im}
            session_data={session_data}
            files={im.payload1 && JSON.parse(im.payload1)}
            tags={im.data?.tags} created_at={im.created_at}
        />;
    }
    else if(im._type == INBOX_MESSAGE_TYPE_WA_TEMPLATE_MSG){
        return <TemplateMessage im={im}
            cur_user_message={cur_user_message}
            session_data={session_data}
        />
    }
    else if(im._type == INBOX_MESSAGE_TYPE_WA_INCOMING_MSG){

        const txt = im.data.txt?.trim();
        const product_items_len = im.data.order?.product_items?.length;
        const action_txt = !txt 
            ? product_items_len > 0 
                ? `Selected ${product_items_len} item${product_items_len > 1 ? 's' : ''} from WhatsApp catalog.`
                : ''
            : null;
        return <SimpleMessage
            user={user} im={im}
            session_data={session_data}
            txt={txt} files={im.data.media_urls}
            action_txt={action_txt}
            created_at={im.created_at}
            header={
                im.data.referral?.source_url 
                ?   <div className="w3-small w3-padding-sides-4-8 w3-pointer">
                        <UrlPreview url={im.data.referral?.source_url} image_url={im.data.referral?.image_url}/>
                        Refered from:&nbsp;
                        <a href={im.data.referral?.source_url} target="_blank"
                            rel="noopener noreferrer"
                        >
                            {im.data.referral?.source_url}
                        </a>
                    </div>
                : null
            }
        />
    }
    else if(im._type == INBOX_MESSAGE_TYPE_WA_MSG || im._type == INBOX_MESSAGE_TYPE_WA_REPLY_MSG){ /* sent by server */
        if(im.data?.fb?.type == "text"){
            return <SimpleMessage user={user} im={im}
                session_data={session_data} txt={im.data.fb.text.body}
                created_at={im.created_at}
            />
        }
        else if(im.data?.fb?.type == "image"){
            return <SimpleMessage user={user} im={im}
                session_data={session_data} txt={im.data.fb.image.caption}
                files={[im.data.fb.image.link]}
                created_at={im.created_at}
            />
        }
        else if(im.data?.fb?.type == "interactive"){
            return <InteractiveMessage user={user} im={im}
                cur_user_message={cur_user_message}
                is_user_in_session={is_user_in_session}
                session_data={session_data}
            />
        }
        else if(im.data?.fb?.type == "template"){
            return <TemplateMessage 
                im={im} 
                cur_user_message={cur_user_message}
                session_data={session_data}
            />
        }
    }
    else if(im._type == INBOX_MESSAGE_TYPE_SESSION_USER_UPDATE){ /*when new user added to session*/
        return (
            <div className="w3-center w3-row w3-margin-4">                
                <div className="w3-inline w3-2px-shadow w3-border w3-light-grey w3-padding-4-8">
                    <div><b>{im.user?.name}</b> {im.payload}</div>
                    <DateView millis={im.created_at} className="w3-right w3-tiny w3-text-grey" />
                </div>
            </div>
        );
    }
    else if (ChatSession.renderInboxMessage){
        let ret = ChatSession.renderInboxMessage(im, user, session_data);
        if(ret) return ret;
    }
}

function ChatMessagesAndComposeContainer({session_data, user, options, chat_window_el_ref}){
    const session = session_data.session;
    var inbox_messages = session_data.inbox_messages;
    if(!inbox_messages){
        session_data.inbox_messages = inbox_messages = [];
        session_data.has_more_inbox_messages = null; // load more
    }
    if(!session_data.already_added_messages) session_data.already_added_messages = {};
    const rerenderer = inbox_messages.rerender = useRerender();
    const scrollable_el_ref = useRef(null);
    const [polling_interval, setPollingInterval] = useState(60000);


    const adjustPollingInterval = () => {
        const last_inbox_message_at = last(inbox_messages)?.created_at || 0;
        setPollingInterval(
            last_inbox_message_at < new Date().getTime() - 2 * MILLIS_IN_HOUR 
            ? 60000 : 8000
        );
    }

    const addMessages = session_data.addMessages = (im_list) => {
        if(!im_list?.length) return;
        // add refs back into the array
        getByIds({"user_ids": im_list.map((im) => im.src_id)}).then(
            (data) => {
                /* set the user on the inbox message */
                inbox_messages.prev_scroll_height = scrollable_el_ref.current.scrollHeight; // save and restore
                sortedList(
                    inbox_messages,
                    im_list.filter((im, i) => {
                        im.user = data.users[im.src_id];
                        let existing_im = session_data.already_added_messages[im.created_at]
                        if(!existing_im){ // new message
                            session_data.already_added_messages[im.created_at] = im;
                            return true;
                        } else {
                            // update the existing message
                            let pos = binarySearch(
                                inbox_messages,
                                existing_im, 
                                (a, b) => a.created_at - b.created_at
                            );
                            /* only replace if it exsists in inbox_messages */
                            if(inbox_messages[pos]?.created_at == im.created_at) inbox_messages[pos] = im;
                            session_data.already_added_messages[im.created_at] = im;
                            return false;
                        }
                    }),
                    (a, b) => a.created_at - b.created_at
                );
                /* dynamically adjust polling interval */
                adjustPollingInterval();
                rerenderer();
            }
        )
    };

    const loadNewMessages = () => {
        if(inbox_messages.is_loading || session_data.is_dummy_session || session_data.is_inactive === true) return;
        inbox_messages.is_loading = true;

        axios.post(
            "/session/messages",
            {
                "greater_than_timestamp": last(inbox_messages)?.created_at || new Date().getTime(),
                "session_id": session._id
            }
        ).then((resp) => {
            let im_list = resp.data.inbox_messages || [];
            resp.data.refs && im_list.push(...resp.data.refs);
            addMessages(im_list);
        }).finally(() => inbox_messages.is_loading = false);
    };


    const loadPrevMessages = () => {
        if(inbox_messages.is_loading || session_data.has_more_inbox_messages === false) return;
        inbox_messages.is_loading = true;
        axios.post(
            "/session/messages",
            {
                "less_than_timestamp": inbox_messages?.next_less_than_timestamp,
                "session_id": session._id
            }
        ).then(
            (resp) => {
                if(resp.data.errors) return;
                session_data.has_more_inbox_messages = resp.data.has_more;
                inbox_messages.next_less_than_timestamp = resp.data.next_less_than_timestamp;
                let im_list = resp.data.inbox_messages || [];
                resp.data.refs && im_list.push(...resp.data.refs);
                addMessages(im_list);
            }
        ).finally(() => inbox_messages.is_loading = false);
    };


    useOnScroll(
        scrollable_el_ref.current, 
        (percent, scroll_direction) => {
            if(percent == 0){
                loadPrevMessages();
            } else if(percent > 95){
                inbox_messages.stick_scroll_to_bottom = true;
            } else{
                inbox_messages.stick_scroll_to_bottom = false;
            }
        },
        []
    );

    useEffect(
        () => {
            if(!scrollable_el_ref.current || session_data.is_inactive === true) return;
            if(!session_data.is_prev_messages_loaded) loadPrevMessages(); // initial load
            session_data.is_prev_messages_loaded = true;
        }, [session_data.is_inactive, scrollable_el_ref.current]
    );

    useEffect(
        () => {
            /* some init defaults */
            /* listner from allsparkrt */
            const im_listener = (msg) => {
                if(msg._type === INBOX_MESSAGE_TYPE_WA_INFO){
                    let info_data = JSON.parse(msg.payload);
                    let im = session_data.already_added_messages[msg.created_at];
                    im && (im.info= Object.assign(im.info || {}, info_data));
                    rerenderer();
                    return true;
                }
                /* add this message */
                addMessages([msg]);
                return true; // handled
            };
            broadcaster.add_event_listener("cs_" + session._id, im_listener);

            /*IMP: set this and cleanup to be used from elsewhere, otherwise it will dangle references and memory bloats*/
            session_data.chat_container_el = scrollable_el_ref.current;
            return () => {
                broadcaster.remove_event_listener("cs_" + session._id, im_listener);
                delete session_data.chat_container_el;
            }
        },
        [scrollable_el_ref.current]
    );

    useEffect(() => {
        /* poll if user not in session, or explicity asked for polling */
        var poll_messages_timer = null;
        if(!session_data.is_user_in_session || options?.polling === true || !isAllsparkConnected()){
            poll_messages_timer = setInterval(() => loadNewMessages(), polling_interval);
        }
        return () => {
            poll_messages_timer && clearInterval(poll_messages_timer);
        }
    }, [polling_interval]);


    useEffect(
        () => {
            /* reset scroll to saved state */
            if(scrollable_el_ref.current){
                if(inbox_messages.stick_scroll_to_bottom){
                    setTimeout(
                        () => {
                            scrollable_el_ref.current.scrollTo(0, scrollable_el_ref.current.scrollHeight)
                            // clear it otherwise when scrolling it will effect next time new messages loads in the next else loop
                            inbox_messages.prev_scroll_height = null; 
                        },
                        0
                    );
                }
                else if(inbox_messages.prev_scroll_height){
                    //restore scroll
                    setTimeout(
                        () => {
                            scrollable_el_ref.current.scrollTo(
                                0, 
                                scrollable_el_ref.current.scrollHeight - inbox_messages.prev_scroll_height
                            )
                            inbox_messages.prev_scroll_height = null;
                        },
                        0
                    );
                }
            }
        }, [inbox_messages.length, scrollable_el_ref.current]
    );

    
    return (
        <>
            <div className="w3-flex w3-flex-grow-scroll w3-margin-topbottom-8 w3-relative" ref={scrollable_el_ref}>
                <div className="w3-expand">
                    {
                        inbox_messages.length
                        ?   inbox_messages.map(
                                (im) => {
                                    return <InboxMessage
                                        key={getUKey(im)}
                                        session_data={session_data}
                                        inbox_message={im}
                                        user={user}
                                    />
                                }
                            )
                        :   session_data.has_more_inbox_messages
                            ?   <EmptyView title={"Loading.."} height="100%" />
                            :   <EmptyView title={"No Messages"} height="100%" />
                    }
                </div>
            </div>
            {
                options?.bottomStatusIndicator  // legacy
                ?   isFunction(options.bottomStatusIndicator)
                    ?   options.bottomStatusIndicator(session_data)
                    :   options.bottomStatusIndicator
                :   ChatSession.renderBottomStatusIndicator 
                    && ChatSession.renderBottomStatusIndicator(session_data)
            }
            <div className="w3-padding-4-8">
                {
                    user && isWriteAllowed(session_data)
                    ?   <ComposeMessage session_data={session_data}
                            user={user}
                            options={options}
                            chat_window_el_ref={chat_window_el_ref}
                        />
                    :   <div className="w3-center">
                            {
                                !user
                                ?   <span>
                                        You need <b onClick={(evt) => broadcaster.broadcast_event("do_login:chat", session_data, options)}>
                                            Login
                                        </b> to chat.
                                    </span>
                                :   <span>You cannot send messages here.</span>
                            }
                        </div>  
                }
            </div>
        </>
    );
}


const isReadAllowed = (session_data) => {
    const session = session_data.session;
    const is_user_in_session = session_data.is_user_in_session;
    return session.is_public
        || session.is_read_allowed
        || (is_user_in_session && is_user_in_session.session_user_type <= SESSION_USER_GENERAL)
};

const isWriteAllowed = (session_data) => {
    const session = session_data.session;
    const is_user_in_session = session_data.is_user_in_session;
    return session.is_public
        || (is_user_in_session && is_user_in_session.session_user_type <= session.write_permission)
}

function ComposeMessage({session_data, user, chat_window_el_ref}){
    const [to_send_text, setToSendText] = useState("");
    const [to_send_files, setToSendFiles] = useState([]);
    const text_el_ref = useRef();
    const MAX_ATTACHMENTS = 3;
    const [uploading, setUploading] = useState(null);

    const attachFilesPopup = () => {
        if (uploading) return;
        var popup = Popup.show(
            "Add Files",
            <FileUploader
                allowed_mime_types={[
                    "image/jpeg", 'image/png', 'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'video/mp4'
                ]} 
                files={to_send_files}
                max_files={MAX_ATTACHMENTS}
                onFilesUpdated={(files) => {setToSendFiles(files); popup.close()}}
            />,
            {"container": chat_window_el_ref.current}
        );
    }

    const showEmojisPopup = (evt) => {
        Popup.showContextMenu(
            evt.target,
            <div className='w3-flex-row-wrap w3-scroll-y w3-large w3-padding-8 app-smileys w3-center'
                style={{"maxHeight": "200px", "width": "200px"}}
                onClick={
                    (evt) => {
                        let text_el = text_el_ref.current;
                        let [start, end] = text_el.last_cursor || [0, 0];
                        text_el.focus();
                        setToSendText(to_send_text.slice(0, start) + evt.target.textContent + to_send_text.slice(end));
                        text_el.selectionStart = text_el.selectionEnd = start + 1;
                    }
                }
            >
                {
                    EMOJIS.map(
                        (smiley) => <span key={smiley}>{smiley}</span>
                    )
                }
            </div>
        )
    }
    const sendMessage = () => {
        if((!to_send_text && to_send_files.length === 0) || uploading) return;
        let data = {
            "dest_session_id": session_data.session._id,
            "payload": to_send_text,
            "_type": INBOX_MESSAGE_TYPE_SIMPLE_MESSAGE,
        };
        if(to_send_files.length){
            data["payload1"] = JSON.stringify(to_send_files);
        }
        if(session_data.is_dummy_session){
            data["is_dummy_session"] = true;
        }

        const im = Object.assign({}, data);
        /* local data on message */
        im.created_at = new Date().getTime();
        const local_message_id = im.local_id = "local__" + im.created_at;
        im.user = user;
        session_data.inbox_messages.stick_scroll_to_bottom = true;
        session_data.inbox_messages.push(im);
        session_data.inbox_messages.rerender();

        setToSendText(""); 
        setToSendFiles([]);

        axios.post("/session/send", im).then(
            (resp) => {
                resp = resp.data;
                let pos = 0;
                for(let i=session_data.inbox_messages.length - 1; i >= 0; i--){
                    if(session_data.inbox_messages[i].local_id == local_message_id){
                        pos = i;
                        break;
                    }
                }
                if(resp.type == TYPE_INBOX_ENTRY_ADDED){
                    if(resp.new_session_created){
                        /*update the new session created*/
                        Object.assign(session_data, resp.new_session_created);
                        session_data.is_dummy_session = false;
                        session_data.has_more_inbox_messages = false;
                    }
                    if(resp.is_user_in_session){
                        /* new user joined ? */
                        session_data.is_user_in_session = resp.is_user_in_session;
                    }
                    const im = resp.inbox_message;
                    im.user = user;
                    session_data.inbox_messages.splice(pos, 1); // remove local message
                    session_data.addMessages?.([im]);
                    broadcaster.broadcast_event('chat:message_sent', im, session_data);
                }
                else if(resp.type == TYPE_ERROR){
                    session_data.inbox_messages.splice(pos, 1);
                }
            }
        );
    }

    const onChatPaste = async (evt) => {
        const selected_files = evt.clipboardData.files;
        if (!selected_files?.length) return;
        if (selected_files.length > 1) {
            Popup.toast(
                <div className='tw-rounded tw-p-2 tw-text-sm'>Please copy & paste one file at a time.</div>,
                2500
            )
            evt.preventDefault();
            return;
        }
        if (to_send_files.length === MAX_ATTACHMENTS) {
            Popup.toast(
                <div className='tw-rounded tw-p-2 tw-text-sm'>You can only send upto 3 attachments</div>,
                2500
            )
            evt.preventDefault();
            return;
        }
        let file = selected_files[0];
        validateAndUploadFile({
            file_data_obj: file,
            allowed_mime_types: ["image/jpeg", 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'video/mp4'],
            files: to_send_files,
            setFiles: setToSendFiles,
            setStatus: setUploading,
        })
    }

    useEffect(
        () => {
            const debouced_timer = setTimeout(
                () => {
                    const event_wait_until = text_el_ref.current?.blocked_until || 0;
                    const cur_millis = new Date().getTime();
                    if(event_wait_until > cur_millis  || (!to_send_text && to_send_files.length === 0)){
                        return;
                    }
                    broadcaster.broadcast_event("chat:typing", session_data, text_el_ref.current);
                    text_el_ref.current.blocked_until = cur_millis + 10000; // don't raise another event until next 10 seconds
                }, 
                1000
            );
            return () => clearTimeout(debouced_timer);
        }, 
        [to_send_text, to_send_files]
    );
    
    return (
        <div className="w3-list-horizontal w3-flex-row w3-flex-vcenter">
            <div className='w3-xlarge w3-list w3-flex-col'>
                <FontAwesomeIcon icon={faSmile} onClick={showEmojisPopup} />
                <div className="w3-relative">    
                    <FontAwesomeIcon
                        icon={faPlus} className={to_send_files.length ? `w3-text-red` : ''} 
                        onClick={attachFilesPopup}
                    />
                    {to_send_files.length ? <div className='w3-tiny w3-absolute w3-text-white w3-red w3-round-medium w3-center' style={{top:-8, right: -5, width:16, height: 16}}>{to_send_files.length}</div> : null}                   
                </div>
            </div>
            <textarea
                className="w3-round w3-input w3-border-black w3-flex-grow-s1"
                value={to_send_text}
                onChange={(evt) => setToSendText(evt.target.value)}
                onTouchMoveCapture={(evt) => evt.stopPropagation()}
                onBlur={
                    (evt) => {
                        evt.target.last_cursor = [evt.target.selectionStart, evt.target.selectionEnd];
                    }
                }
                onPaste={onChatPaste}
                disabled={uploading}
                ref={text_el_ref}
            />
            {uploading ? <span className='w3-absolute w3-small' style={{left: '35%'}}><FontAwesomeIcon icon={faSpinner} className="w3-animate-spin"  />&nbsp; Uploading</span> : null}
            <FontAwesomeIcon icon={faCircleArrowRight} className="w3-xxlarge" onClick={sendMessage} />
        </div>
    );
}

function EditChatSession({session_data, afterUpdate, className}){
    const session = session_data.session;
    const ctx = useRef({"updates": {}}).current; // context
    const doUpdateChatSession = () => {
        if(ctx.is_loading) return;
        ctx.is_loading = true;
        axios.post("/session/update", {"session_id": session._id, ...ctx.updates}).then(
            (resp) => {
                if(resp.data.errors){
                    Popup.error(resp.data.errors);
                    return;
                }
                Object.assign(session, resp.data.session);
                afterUpdate && afterUpdate(session_data);
                ctx.updates = {};
            }
        ).finally(() => ctx.is_loading = false);
    }

    return <div className={className}>
        <div className='w3-margin-bottom'>
            <div>Title</div>
            <input type="text" className="w3-input w3-border w3-round" defaultValue={session.title} 
                onChange={(evt) => ctx.updates.title = evt.target.value}
            />
        </div>
        <div className='w3-margin-bottom'>
            <div>Description</div>
            <textarea className="w3-input w3-border w3-round" defaultValue={session.description} 
                onChange={(evt) => ctx.updates.description = evt.target.value}
            />
        </div>
        <div className='w3-margin-bottom'>
            <div>Group Image</div>
            <FileUploader files={session.image ? [session.image] : []} 
                onFilesUpdated={(files) => ctx.updates.image = files[0]?.url} 
            />
        </div>
        <div className='w3-margin-bottom'>
            <label className='w3-flex w3-list-horizontal-16'>
                <input type="checkbox" defaultChecked={!session.is_public} 
                    onChange={(evt) => {
                        if(!evt.target.checked){
                            if(!window.confirm("Are you sure you want to make this group public?")){
                                evt.target.checked = true;
                                return;
                            }
                        }
                        ctx.updates.is_public = !evt.target.checked;
                    }}
                    className='w3-check'
                />
                <div>Private Group</div>
            </label>  
        </div>
        <div className='w3-margin-bottom'>
            <label className='w3-flex w3-list-horizontal-16'>
                <input type="checkbox" defaultChecked={session.is_public || session.is_read_allowed} 
                    className='w3-check'
                    onChange={(evt) => {
                        if(evt.target.checked){
                            if(!window.confirm("Are you sure you want to allow non-members to read messages/posts in this group?")){
                                evt.target.checked = false;
                                return;
                            }
                        }
                        ctx.updates.is_read_allowed = evt.target.checked;
                    }}
                />
                <div>Allow Non-Members to read</div>
            </label>
        </div>
        <div>
            <div className='w3-button w3-green' onClick={doUpdateChatSession}>Update</div>
        </div>
    </div>
}

function ChatSessionSettings({session_data, options}){
    const is_user_in_session = session_data.is_user_in_session;
    const is_user_admin = is_user_in_session && is_user_in_session.session_user_type <= SESSION_USER_ADMIN;
    const doToggleNotficationMute = () => {
        axios.post(
            "/session/user/update",
            {
                "session_id": session_data.session._id,
                "notification_flag": (
                    is_user_in_session.notification_flag > NOTIFICATION_FLAG_ONLY_WHEN_TAGGED 
                        ? NOTIFICATION_FLAG_ONLY_WHEN_TAGGED : NOTIFICATION_FLAG_ALL
                )
            }
        ).then(
            (resp) => {
                resp.data.is_user_in_session && Object.assign(session_data.is_user_in_session, resp.data.is_user_in_session);
                session_data.rerender?.();
            }
        )
    }

    return (
        <div>
            {
                (ChatSessionSettings.customSettings && ChatSessionSettings.customSettings(session_data, options))
                || (
                    <div className='w3-list w3-list-bordered w3-bold w3-margin-top w3-border-bottom'>
                        {
                            is_user_in_session
                            ?   <div className="w3-row w3-padding-button" onClick={doToggleNotficationMute}>
                                    {
                                        is_user_in_session.notification_flag > NOTIFICATION_FLAG_ONLY_WHEN_TAGGED
                                        ?   <><FontAwesomeIcon icon={faBell} className="w3-text-red" /> Mute Notifications</>
                                        :   <><FontAwesomeIcon icon={faBellSlash} className="w3-text-green" /> Unmute Notifications</>
                                    }                                        
                                </div>
                            :   null
                        }
                        {
                            is_user_admin
                            ?   <div className="w3-row w3-padding-button"
                                    onClick={(evt) => {
                                        const popup = Popup.show(
                                            "", <EditChatSession session_data={session_data} afterUpdate={() => {popup.close()}} className="w3-padding"/>
                                        )
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} className="w3-text-blue" /> Edit
                                </div>
                            :  null
                        }
                    </div>            
                )
            }
            <div className='w3-padding-8'>
                <ChatSessionUsers session_data={session_data} options={options} />
            </div>
        </div>
    )

}

function ChatSession({
    session_ids, session_data_list:_session_data_list,
    onClose, options
}){
    /* current session */
    const [session_data_list, setSessionDataList] = useState(_session_data_list);
    const [session_data, setActiveSessionData] = useState(_session_data_list?.[0]);
    const [show_tabs, setShowTabs] = useState(null);
    const [is_minimized, setIsMinimized] = useState(false);
    const user = useCurrentUser();
    const chat_window_el_ref = useRef(null);
    const swipe_el_ref = useRef(null);
    const rerender = useRerender();
    const [ViewPort] = useBroadcastedState("viewport");
    session_data && (session_data.rerender= rerender); // so we can global rerender  
    
    /* load initial sessions */
    useEffect(
        () => {
            if(!session_data_list) return;
            setShowTabs(session_data_list.some((session_data) => session_data.tab_title));
    
        }, [session_data_list]
    )
    useEffect(
        () => {
            if(!session_ids?.length) return;
            getByIds({"session_ids": session_ids}).then(
                function(data){
                    let session_data_list = session_ids.map((session_id) => data.sessions[session_id])
                    setSessionDataList(session_data_list);
                    setActiveSessionData(session_data_list[0]);
                }
            );
        }, [session_ids]
    );

    const swipeOptions = useMemo( // necessary to prevent re-render of swipe component that resets it 
        () => {
            return {
                "continuous": false, // true doesn't work, it's sliding 2 slide at a time, annoying issue
                "callback": (index, elem) => {
                    let active_session = session_data_list[index]; //swipe_el_ref.current.getPos()
                    active_session && setActiveSessionData(active_session);
                }
            }
        }, [session_data_list]
    );

    if(!session_data) {
        return (
            <div className="w3-relative w3-white w3-flex-col">
                <LoadingView title="Loading..." />
            </div>
        )
    }
    if(is_minimized){
        return (
            <div className='w3-row w3-center w3-tiny w3-padding-8 w3-white w3-border w3-2px-shadow w3-margin-right-8 w3-pointer'
                style={{height: 'fit-content', alignSelf: 'flex-end'}}
                onClick={() => setIsMinimized(false)}
            >
                <div>{session_data.session?.title}</div>
                <div className='w3-text-red'>Click to Open</div>
            </div>
        );
    }
    const session = session_data.session;
    const is_user_in_session = session_data.is_user_in_session;

    return (
        <div className='w3-padding-2 w3-round-xlarge w3-border w3-bounded w3-shadow w3-white'>
            <div className="w3-relative w3-round-xlarge w3-bounded w3-overflow-hidden w3-right w3-white w3-flex-col"
                style={{
                    "width": "450px",
                    "height": (Math.min(800, ViewPort.HEIGHT) - 4) + "px",
                    "backgroundImage": `url(${options?.background_image || ChatSession.BACKGROUND_IMAGE || CHAT_DEFAULT_BG_IMAGE})`,
                    "backgroundRepeat": 'repeat',
                    "transform": "translateZ(0)",
                }}
                ref={chat_window_el_ref}
            >
                {/* header */}
                <div className={`w3-shadow w3-no-wrap w3-relative w3-flex w3-flex-vcenter ${ChatSession.theme?.header_classname || "w3-teal"}`}>
                    {
                        session?.image
                        ?   <img className="w3-circle w3-border" 
                                style={{
                                    "height" :"35px",
                                    "width": "35px",
                                    "objectFit": "cover",
                                    "marginRight": "4px",
                                }}
                                src={session.image.url || session.image}
                            />
                        :   null
                    }
                    {/* title */}
                    <div className="w3-bold w3-overflow-hidden w3-padding-left"
                        onClick={
                            options?.onTitleClick 
                            ? options.onTitleClick 
                            : () => Popup.show("", <div className='w3-padding'>{session.title}</div>)
                        }
                    >
                        {
                            (ChatSession.renderChatTitle && ChatSession.renderChatTitle(session_data))
                            || session.title
                        }
                    </div>
                    {/* settings */}
                    <div className="w3-margin-left-auto">
                        {
                            is_user_in_session && is_user_in_session.notification_flag <= NOTIFICATION_FLAG_ONLY_WHEN_TAGGED
                            ?   <FontAwesomeIcon
                                    icon={faBellSlash} className="w3-large w3-button" onClick={() => {}}
                                />
                            :   null
                        }
                        <FontAwesomeIcon icon={faCog} className="w3-large w3-button" onClick={() => {
                            ChatSession.Settings 
                                ?   Popup.open(
                                        "", 
                                        <ChatSession.Settings session_data={session_data} options={options} />,
                                        {"container": chat_window_el_ref.current, "className": "w3-absolute"}
                                    )
                                :   Popup.open(
                                        "",
                                        <ChatSessionSettings session_data={session_data} options={options} />,
                                        {"container": chat_window_el_ref.current, "className": "w3-absolute"}
                                    );
                        }}/>
                        <FontAwesomeIcon icon={faTimes}
                            className="w3-large w3-button"
                            onClick={
                                () => onClose && onClose()
                            }
                        />
                        <FontAwesomeIcon icon={faMinimize}
                            className="w3-large w3-button"
                            onClick={() => setIsMinimized(true)}
                        />
                    </div>
                </div>
                {
                    show_tabs
                    ?   <div className="w3-row w3-2px-shadow w3-white w3-flex w3-flex-row w3-scroll-x w3-hide-scrollbar">
                            {session_data_list.map((_session_data, i) => {
                                _session_data.is_inactive = _session_data.session._id != session_data.session._id;
                                return (
                                    <div className="w3-padding-button w3-bold w3-center w3-no-wrap"
                                        key={_session_data.session._id}
                                        style={{
                                            "borderRight": i < session_data_list.length - 1 ? "1px solid #ccc" : "none",
                                            "borderBottom": !_session_data.is_inactive ? "2px solid red" : "1px solid #ccc"
                                        }}
                                        onClick={() => {
                                            swipe_el_ref.current?.slide(i, 500);
                                            setActiveSessionData(_session_data)
                                        }}
                                    >
                                        {
                                            (ChatSession?.renderChatTab && ChatSession.renderChatTab(_session_data))
                                            ||  _session_data.tab_title || "Tab " + (i + 1)
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    :   null
                }
                <ReactSwipe swipeOptions={swipeOptions}
                    className='w3-flex w3-flex-grow-s1 w3-overflow-hidden'
                    ref={swipe_el_ref}
                    childCount={(session_data_list?.length || 0) + (options?.swipeViews?.length || 0)} // force dependency
                    style={{"wrapper": {"display": "flex"}}}
                >
                    {
                        session_data_list.map((_session_data) => {
                            _session_data.is_inactive = _session_data.session._id != session_data.session._id;
                            return <div className='w3-flex-col w3-relative' key={_session_data.session._id}>
                                <ChatMessagesAndComposeContainer
                                    session_data={_session_data}
                                    user={user}
                                    options={options}
                                    chat_window_el_ref={chat_window_el_ref}
                                />
                            </div>
                        })
                    }
                    {options?.swipeViews}
                </ReactSwipe>
            </div>
        </div>
    );
}


function ChatSessionUser({session_data, csu}){
    const is_user_in_session = session_data.is_user_in_session;
    const is_user_admin = is_user_in_session?.session_user_type <= SESSION_USER_ADMIN;
    const is_user_moderator = is_user_in_session?.session_user_type <= SESSION_USER_MODERATOR;
    const session = session_data.session;
    const refresh = useRerender();

    const doAction = (action) => {
        axios.post(
            "/session/user/update",
            {"session_id": session._id, "for_user_id": csu.user_id, "action": action}
        ).then(
            (resp) => {
                resp.data.session_user && Object.assign(csu, resp.data.session_user);
                refresh();  
            }
        )
    }

    const showOptions = (evt) => {
        Popup.showContextMenu(
            evt.target, 
            <div className="w3-list w3-white">
                {
                    csu.session_user_type == SESSION_USER_PENDING
                    &&  <>
                            <div className="w3-padding-8 w3-text-green" onClick={() => doAction("approve")}>Approve</div>
                            <div className="w3-padding-8 w3-text-red" onClick={() => doAction("reject")}>Reject</div>
                        </>                                
                }
                {
                    csu.session_user_type == SESSION_USER_BANNED
                    &&  <div className="w3-padding-8 w3-text-red" onClick={() => doAction("unblock")}>Unblock</div>
                }
                {
                    csu.session_user_type <= SESSION_USER_GENERAL
                    &&  <div className="w3-padding-8 w3-text-red" onClick={() => doAction("block")}>Block user</div>
                }
                {
                    is_user_admin && csu.session_user_type == SESSION_USER_GENERAL
                    ?  <div className="w3-padding-8 w3-text-red" onClick={() => doAction("make_moderator")}>Make Moderator</div>
                    :  null
                }
            </div>
        );
    }
    return (
        <div className='w3-flex-row w3-list-horizontal-16 w3-padding-top'>
            <div className="w3-col s2 w3-padding-sides-8">
                <img src={csu.user.image?.url || csu.user.image || ProfileAvatar} alt={csu.user.name} width="30px" height="auto" />
            </div>            
            <div className="w3-flex-grow-s1">
                <div className="w3-bold">{csu.user.name}</div>
                <div className='w3-flex'>
                    {
                        csu.session_user_type == SESSION_USER_ADMIN 
                        ? <div className='w3-green w3-tag'>Admin</div>
                        : csu.session_user_type == SESSION_USER_MODERATOR
                            ? <div className='w3-blue w3-tag'>Moderator</div>
                            :  csu.session_user_type == SESSION_USER_PENDING
                                ? <div className='w3-orange w3-tag'>Awaiting Approval</div>
                                :  csu.session_user_type >= SESSION_USER_BANNED
                                    ? <div className='w3-red w3-tag'>Blocked</div>
                                    : null
                    }
                    {
                        is_user_moderator && csu.data?.app
                        ? <div className='w3-yellow'>{csu.data.app}</div>
                        :  null
                    }
                </div>
            </div>
            {
                is_user_moderator
                ?   <FontAwesomeIcon icon={faEllipsisV} className="w3-padding-sides-16" onClick={showOptions}/>
                :   null
            }
        </div>
    )
}

function ChatSessionUsers({session_data, options}){
    const session = session_data.session;
    const [csu_list, setCSUList] = useState([]);
    const [session_users_count, setSessionUsersCount] = useState(0);
    const ctx = useRef({}).current;
    const session_users_list_el = useRef(null);

    const loadMore = () => {
        if(ctx.is_loading || ctx.has_more === false) return;
        ctx.is_loading = true;
        axios.post(
            "/session/users",
            {"session_id": session._id, "cursor": ctx.cursor || 0}
        ).then(
            async (resp) => {
                if(resp.data.errors) return;
                ctx.has_more = resp.data.has_more;
                ctx.cursor  = resp.data.cursor;
                resp.data.session_users_count && setSessionUsersCount(resp.data.session_users_count);
                if(!resp.data.session_users?.length) return;
                /* fetch users */
                let cache = await getByIds({"user_ids": resp.data.session_users.map((csu) => csu.user_id)})
                resp.data.session_users.forEach((csu) => {
                    csu.user = cache.users[csu.user_id];
                });
                setCSUList([...csu_list, ...resp.data.session_users]);
            }
        ).finally(() => {ctx.is_loading = false});
    };

    useEffect(() => {loadMore()}, [session._id]); // init
    useOnScroll(session_users_list_el.current, (percent) => {percent > 95 && loadMore();}, []); // scroll 

    return <div className='w3-row'>
        {
            session_users_count
            ?   <div><b>{session_users_count}</b> Members</div>
            :   null
        }
        <div className='w3-list'>
            {csu_list.map((csu) => <ChatSessionUser session_data={session_data} csu={csu} key={csu.user_id}/>)}
        </div>
    </div>
}

function chatWindowsContainer(){
    const ctx = createPopupsContainer(null, "chat_windows");
    // create a popup react element
    if(!ctx.styled){
        const el = ctx.el;
        el.style.position = "fixed";
        el.style.zIndex = "30";
        el.style.bottom = "0px";
        el.style.right = "0px";
        el.style.maxWidth = "100%";
        el.style.overscrollBehavior = "none";
        el.classList = "w3-flex-row w3-list-horizontal-8"
        ctx.styled = true;
    }
    return ctx;
}

function SessionListItem({session_data}){
    const rerender = useRerender();
    const is_user_in_session = session_data.is_user_in_session;
    const session = session_data.session;
    return (
        <div className={`w3-flex w3-relative w3-ripple1 ${is_user_in_session?.unseen_count ? "w3-light-grey": ""}`}
            key={session._id}
            onClick={() => {
                ChatSession.open(session_data);
                is_user_in_session.unseen_count= 0;
                rerender();
            }}
        >
            {
                is_user_in_session?.unseen_count 
                ?   <div className="w3-display-topright w3-padding-8">
                        <div className="w3-tiny w3-center w3-inline w3-red w3-circle w3-middle-align"
                            style={{"height": "20px", "width":"20px"}}
                        >
                            {is_user_in_session.unseen_count}
                        </div>
                    </div>
                :   null
            }
            <div className="w3-flex-grow w3-padding-8-4">
                <div className="title w3-bold">
                    {session.title}
                </div>
                <div className="w3-padding-topbottom-8 w3-small w3-text-light-grey">
                    {session.description}
                </div>
                <div>
                {
                    session_data.tab_title
                    ?   <div className="w3-tag" component="auto_bg_color">
                            {session_data.tab_title}
                        </div>
                    :   null
                }
                </div>
                <DateView className="w3-tiny w3-text-grey" millis={is_user_in_session?.updated_at || session.created_at} />
            </div>
            {
                session.image
                ?   <div className="w3-col w3-center s3 w3-padding-4">
                        <img src={session.image} alt="image" className='w3-circle' width="50px" height="auto" />
                    </div>
                :   null
            }
        </div>
    )
}

function LoadUserChats({onLoad, className}){
    const [session_data_list, setSessionDataList] = useState(null);
    const ctx = useRef({}).current;
    const load_more_chats = () => {
        if(ctx.is_loading || ctx.has_more === false) return;
        ctx.is_loading = true;
        axios.post("/user/chats", {"cursor": ctx.cursor}).then((resp) => {
            setSessionDataList(resp.data.sessions);
            ctx.cursor = resp.data.cursor;
            ctx.has_more = resp.data.has_more;
        }).finally(() => {ctx.is_loading = false});
    }

    useEffect(() => {load_more_chats()}, []); // init
    useOnScroll(ctx.scrolling_list_el, (percent) => {percent > 95 && load_more_chats();}, [ctx.scrolling_list_el]);

    return (
        <div className={`w3-pointer w3-bounded w3-scroll ${className || ''}`} style={{"width": "250px"}}>
            <div className='w3-row w3-scroll-y w3-list-bordered w3-list' ref={(el) => {ctx.scrolling_list_el= el;}}>
                {
                    session_data_list?.map(
                        (session_data) => <SessionListItem session_data={session_data} key={session_data.session._id}/>
                    ) || <LoadingView title={"Loading.."}  height="200px"/>
                }
            </div>
        </div>
    );
}

/* takes session_ids or array of session_data and render the tabs */
ChatSession.open = (sessions, options) => {
    if(!Array.isArray(sessions)){
        sessions = [sessions];
    }
    const session_ids = [];
    const session_data_list = sessions.filter(
        (session) => {return isString(session) ? (session_ids.push(session) && false) : true}
    );
    const _window_id = [
        ...(session_ids || []), 
        ...(session_data_list?.map(sd => sd.session._id) || [])
    ].sort().join("_");

    let container = chatWindowsContainer();
    if(_window_id in container.views) return;

    const ctx = {};
    /* reset overScrollBehaviour ans overscrolling is annoying n chat window */
    document.body.style.overscrollBehavior = "none";
    document.documentElement.style.overscrollBehavior = "none";

    ctx.close = () => {
        container.remove(_window_id);
        if(Object.entries(container.views).length == 0){
            /* all chat windows closed */
            document.body.style.overscrollBehavior = null;
            document.documentElement.style.overscrollBehavior = null;    
        }
    }

    container.add(
        _window_id,
        <ChatSession
            session_ids={session_ids}
            session_data_list={session_data_list}
            key={_window_id}
            options={options}
            onClose={ctx.close}
        />
    );
    
    return ctx;
}

ChatSession.closeAll = () => {
    let container = chatWindowsContainer();
    for(let key in container.views){
        container.remove(key);
    }
}

function SingleChatSessionPage() {
    var {session_id} = useParams();
    useEffect(() => {ChatSession.open(session_id)}, []);
    return <div className='w3-content w3-center'>
        <img 
            src="https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-02-28/ztc1iALAep1677594459__sukhiba.jpeg" className='w3-image'
        />
    </div>
}


export {
    ChatSession, LoadUserChats, SingleChatSessionPage,
    SESSION_USER_ADMIN, SESSION_USER_GENERAL, SESSION_USER_MODERATOR,
    INBOX_MESSAGE_TYPE_WA_MSG, INBOX_MESSAGE_TYPE_WA_TEMPLATE_MSG,
    INBOX_MESSAGE_TYPE_WA_INCOMING_MSG,
    isReadAllowed, isWriteAllowed, ChatSessionUsers, EditChatSession,
    InboxMessage, SimpleMessageContextMenu
};
