import React, { useEffect, useRef, useState } from 'react'
import bgPage1 from "../../images/landing/page1_bg.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import contact_us from '../../images/contact-us.png';
import { FormErrorText } from '../../components/ui/FormErrorText';
import axios from 'axios';
import { Popup } from 'base/ui/popups';
import SuccessPopUp from './SuccessPopUp';
import { LoadingOverlay } from 'base/ui/status';
import { PHONE_NUMBER_CODES } from '../../constants';

const ContactUsPage = () => {
    const ctx = useRef({}).current;
    const [show_error, setShowError] = useState(null);
    const [show_success_popup, setShowSuccessPopup] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    const [country_phone_code, setCountryPhoneCode] = useState();

    const updateField = (key) => {
        return (evt) => {
            ctx[key] = evt.target.value
            setShowError(null)
        }
    }
    useEffect(() => {
        axios.get("/user/location").then(
            (resp) => {
                if (resp.data) {
                    setCountryPhoneCode(resp.data.phone_code)
                }
            }
        )
    }, [])

    function submitForm() {
        if (!ctx.name?.length) {
            setShowError({ "name": true })
            return
        }
        if (!ctx.email?.length) {
            setShowError({ "email": true })
            return
        }
        if (!ctx.phone?.length) {
            setShowError({ "phone": true })
            return
        }
        const payload = {
            form_name: "contact_us",
            fields: {
                name: ctx.name,
                email: ctx.email,
                phone_number: ctx.phone,
                country_phone_code: country_phone_code,
                notes: ctx.notes,
            }
        }
        setIsLoading(true)
        axios.post("/company/contact", payload)
            .then(
                (resp) => {
                    setShowSuccessPopup(true)
                    document.getElementById("input_name").value = ""
                    document.getElementById("input_email").value = ""
                    document.getElementById("input_phone").value = ""
                    document.getElementById("input_notes").value = ""
                }
            ).finally(() => setIsLoading(false));
    }

    return (
        <div style={{ backgroundImage: 'url(' + bgPage1 + ')' }} className='tw-pb-20 tw-pt-6 tw-px-6 md:tw-px-14 lg:tw-px-24 xl:tw-px-44 2xl:tw-px-60 font-figtree'>
            {
                is_loading ? <LoadingOverlay /> : null
            }
            <div className='tw-bg-white tw-rounded-xl tw-border tw-flex max-md:tw-flex-col max-md:tw-flex-col-reverse'>
                <div className='md:tw-w-2/5 max-md:tw-border-t md:tw-border-r'>
                    <div className='tw-p-6 md:tw-p-8 lg:tw-p-10'>
                        <p className='tw-text-[20px] md:tw-text-[28px] tw-font-medium'>Get in touch</p>
                        <p className='tw-text-[14.4px] md:tw-text-[18px] tw-mt-1 tw-mb-6 tw-text-gray-500'>Fill up the form to get in touch with us</p>
                        <div className='tw-mb-4 tw-flex tw-items-center tw-gap-4'>
                            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
                                <FontAwesomeIcon icon={faEnvelope} className="tw-w-5 tw-text-landing-green" />
                            </div>
                            <p className='tw-text-[16px] md:tw-text-[20px]'>connect@sukhiba.com</p>
                        </div>
                        <div className='tw-mb-4 tw-flex tw-items-center tw-gap-4'>
                            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="tw-w-5 tw-text-landing-green" />
                            </div>
                            <p className='tw-text-[16px] md:tw-text-[20px]'>Nairobi, Kenya</p>
                        </div>
                    </div>
                    <div className=''><img src={contact_us} /></div>
                </div>
                <div className='md:tw-w-3/5 tw-p-6 md:tw-p-8 lg:tw-p-10'>
                    <p className='tw-text-[20px] md:tw-text-[28px] tw-font-medium'>Contact us</p>
                    <p className='tw-text-[14.4px] md:tw-text-[18px] tw-mt-1 tw-mb-6 tw-text-gray-500'>Fill up the form to get in touch with us</p>
                    <div className='tw-mb-4'>
                        <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Name</p>
                        <input id='input_name' className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Your Name'
                            onChange={updateField("name")} />
                        {show_error?.name ? <FormErrorText msg={"Please enter your name"} /> : null}
                    </div>
                    <div className='tw-mb-4'>
                        <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Email</p>
                        <input id='input_email' className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Email'
                            onChange={updateField("email")} />
                        {show_error?.email ? <FormErrorText msg={"Please enter valid email"} /> : null}
                    </div>
                    <div className='tw-mb-4'>
                        <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Phone</p>
                        <div className='tw-flex tw-border tw-rounded tw-text-sm tw-p-1 tw-w-full'>
                            <select className="tw-outline-none tw-text-md tw-p-2 tw-mr-1"
                                onChange={(e) => setCountryPhoneCode(e.target.value)}
                                value={country_phone_code}>
                                {PHONE_NUMBER_CODES.map(code => (
                                    <option key={code} value={code}>+{code}</option>
                                ))}
                            </select>
                            <input id='input_phone' className='tw-outline-none tw-px-2 tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Phone no.'
                                onChange={updateField("phone")} />
                        </div>

                        {show_error?.phone ? <FormErrorText msg={"Please enter valid phone number"} /> : null}
                    </div>
                    <div className='tw-mb-4'>
                        <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Note</p>
                        <textarea id='input_notes' rows={5} className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Any remarks'
                            onChange={updateField("notes")} />
                    </div>
                    <button className='tw-w-full tw-rounded-full tw-bg-landing-green tw-px-6 md:tw-px-12 tw-text-white tw-py-3.5 tw-tracking-wide'
                        onClick={submitForm}>Submit</button>
                </div>

            </div>
            <SuccessPopUp show={show_success_popup} setShow={setShowSuccessPopup} />
        </div>
    )
}

export default ContactUsPage