import React from 'react'
import ResponsivePopup from '../../components/ui/ResponsivePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import successAnim from '../../images/landing/submit_anim.gif'

const SuccessPopUp = ({show, setShow}) => {

    function HeaderGif() {
        return (
            <div className=" tw-flex tw-justify-between tw-pl-4 md:tw-pl-8">
                <FontAwesomeIcon icon={faX} width={24} height={24} className='tw-p-4 tw-cursor-pointer tw-ml-auto tw-font-light'
                    onClick={() => setShow(false)}
                />
            </div>
        )
    }

    return (
        <ResponsivePopup show={show} onClose={() => setShow(false)} title={'Sign Up for our Partner Referral Program'}
            titleRenderer={HeaderGif}>
            <div className='font-figtree tw-grid  tw-place-items-center tw-px-4 tw-pb-4 '>
                <img src={successAnim} />
                <p className='tw-text-[18px] tw-font-semibold md:tw-text-[28px] tw-mb-2 tw-text-landing-black'>We have received your request</p>
                <p className='tw-text-[14px] tw-font-light md:tw-text-[18px] tw-mb-1 tw-text-black/[0.7]'>Our team will contact you within next 24 hours to address your request</p>
            </div>
        </ResponsivePopup>
    )
}

export default SuccessPopUp