import { setBroadcastHookState } from "./utils/events";

var ViewPort = {
    "WIDTH":  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    "HEIGHT":  Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
}
/*js function to understand the screen size*/
function onResize() {
	ViewPort.WIDTH = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	ViewPort.HEIGHT = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    setBroadcastHookState("viewport", {...ViewPort});
};
window.addEventListener('resize', onResize, false);  
onResize();

var IS_CORDOVA_APP = !!window.cordova;
var IS_TOUCH_DEVICE = (
    !!('ontouchstart' in window) || !!("onorientationchange" in window) || !!('ontouchstart' in document.documentElement) || !!window.ontouchstart 
        || !!window.onmsgesturechange || (window.DocumentTouch && window.document instanceof window.DocumentTouch)
);

var IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

var IS_MOBILE = false; //initiate as false
(function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) {
    IS_MOBILE = true;
}
})(navigator.userAgent||navigator.vendor||window.opera||'');

const MILLIS_IN_MINUTE = 60 * 1000;
const MILLIS_IN_HOUR = 60 * MILLIS_IN_MINUTE;
const MILLIS_IN_A_DAY = 24 * MILLIS_IN_HOUR;


const WEB_PUSH_PUBLIC_KEY = "BOdNxyuUEKzdSB_JZr_YwDoWNc2DTSTLH0cI6DyJ_oulcjydmhrTVi9PQ17mVhmGmB7LURCAuRP_JUEMLWRv4PE";

const INT64_MAX = 9223372036854775807;

export {ViewPort, IS_TOUCH_DEVICE, IS_MOBILE, IS_DEV, MILLIS_IN_A_DAY, MILLIS_IN_HOUR, MILLIS_IN_MINUTE, WEB_PUSH_PUBLIC_KEY, INT64_MAX};


export const Constants = {
    TYPE_INBOX_MESSAGES: 12,
    TYPE_INBOX_ENTRY_ADDED: 13,

    //session stuff
    TYPE_SESSION_DATA: 14,
    TYPE_SESSIONS_LIST: 15,
    TYPE_SESSION_USERS_DATA: 22,

    //event types
    EVENT_ALLSPARK_MESSAGE_RECEIVED: 1,
    EVENT_BASE_DISPLAY_TEMPLATE_LOADED: 2,
    EVENT_USER_AVAILABLE: 3,


    INBOX_MESSAGE_TYPE_SESSION_ID: 1008,
    INBOX_MESSAGE_TYPE_SIMPLE_MESSAGE: 1003,
    INBOX_MESSAGE_TYPE_SPECIAL_MESSAGE: 1004,
    INBOX_MESSAGE_TYPE_SPECIAL_LONG_MESSAGE: 1005,
    INBOX_MESSAGE_TYPE_TEMPLATE: 1014,
    INBOX_MESSAGE_TYPE_NONE: 0,
    INBOX_MESSAGE_TYPE_USER_IS_TYPING: 555,
    INBOX_MESSAGE_TYPE_APP_MESSAGE: 1015,


    SESSION_USER_ADMIN: 10,
    SESSION_USER_MODERATOR: 15,
    SESSION_USER_GENERAL: 20,
    SESSION_USER_PENDING: 30,
    SESSION_USER_BANNED: 31,

    NOTIFICATION_FLAG_NONE: 10,
    NOTIFICATION_FLAG_MUTED: 11,
    NOTIFICATION_FLAG_ONLY_WHEN_TAGGED: 20,
    NOTIFICATION_FLAG_ALL: 30,
}


export const EMOJIS = '😁,😊,😂,❤️,🤣,👍,😭,🙏,😘,🥰,😍,🎉,💕,🥺,😅,🔥,☺️,🤦,♥️,🤷,🙄,😆,🤗,😉,🎂,🤔,👏,🙂,😳,🥳,😎,👌,💜,😔,💪,✨,💖,👀,😋,😏,😢,👉,💗,😩,💯,🌹,💞,🎈,💙,😃,😡,💐,😜,🙈,🤞,😄,🤤,🙌,🤪,❣️,😀,💋,💀,👇,💔,😌,💓,🤩,🙃,😬,😱,😴,🤭,😐,🌞,😒,😇,🌸,😈,🎶,✌️,🎊,🥵,😞,💚,☀️,🖤,💰,😚,👑,🎁,💥,🙋,☹️,😑,🥴,👈,💩,✅'.split(',');
