/* Item price and quanity display on the catalog items/cart items */
import React, { useEffect, useMemo, useState } from 'react';
import { useRerender } from 'base/utils/common';
import { Popup } from 'base/ui/popups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getDisplayPrice, getPrice, getSubProductDescription } from '../../../../helpers/StoreHelper';
import ResponsivePopup from '../../../ui/ResponsivePopup';
import ProductSubSelections from './ProductSubSelections';
import EditQuantity from './EditQuantity';
import Product from './Product';
import SubProduct from './SubProduct';
import useProductQuantity from '../useProductQuantity';

function ProductPriceQty({
    price,
    config_index,
    show_config_description,
    cart, order,
    delivery_route, catalog_product, setCp, buying_for_user, source
}) {  

    const {
        cp,
        qty, 
        order_qty, 
        sub_products,
        sub_product,
        updateQty, 
        updateCart,
        addNewSubProduct,
        updateSubProduct
    } = useProductQuantity({delivery_route, catalog_product, setCp, buying_for_user, cart, order, config_index, source})

    const {CustomPricingCache} = window;
    const rerennder = useRerender();
    const can_modify = !delivery_route.is_ordering_frozen;
    price = price || CustomPricingCache[cp._id] || cp.price;

    // Sub Product
    const [show_sub_selections, setShowSubSelections] = useState(false);
    const [show_sub_products, setShowSubProducts] = useState(false);
    const config_price = sub_product && sub_product.qty > 0 ? (sub_product.total_price / sub_product.qty) : 0;
    const config_description = useMemo(() => sub_product && show_config_description && getSubProductDescription(sub_product), [show_config_description, sub_product])

    const openEditQty = () => {
        var popup = Popup.slideUp(
            <EditQuantity qty={qty} updateQty={(qty) => {updateQty(qty); popup.close()}} max_qty_per_user={cp.max_qty_per_user} />,
            {title: 'Edit Quantity'}
        )
    }

    const onAddSubProduct = (config) => {
        addNewSubProduct(config);
        setShowSubSelections(false);
    }
    
    const onUpdateSubProduct = (config) => {
        updateSubProduct(config);
        setShowSubSelections(false);
    }

    const addSubProduct = () => {
        if (sub_product) {
            updateQty(qty + 1)
        } else {
            if (sub_products?.length > 0) {
                setShowSubProducts(true);
            } else {
                setShowSubSelections(true)
            }
        }
    }


    const removeSubProduct = () => {
        if (sub_product || sub_products?.length === 1) {
            updateQty(qty - 1);
        } else {
            setShowSubProducts(true);
        }
    }

    const adminUpdateCustomPrice = () => {
        let new_price = parseFloat(window.prompt("Enter new price", getPrice(delivery_route.currency, price)));
        if(new_price) {
            CustomPricingCache[cp._id] = new_price * 1000;
            if (qty > 0) {
                updateCart(0);
            }
            rerennder();
        }   
    }

    useEffect(() => {
        if (!sub_products.length && show_sub_products) {
            setShowSubProducts(false);
        }
    }, [sub_products])

    return (
        <>
        <div>
            {config_description /* showing sub-selections here only if editable and from cart */
                ?   <div className='hflex tw-bg-gray-bg tw-p-2 tw-gap-4 tw-rounded tw-mt-1 tw-mb-2' onClick={() => setShowSubSelections(true)}>
                        <div dangerouslySetInnerHTML={{__html: config_description}} className='tw-text-[13px] tw-text-gray-700 tw-leading-6' /> 
                        <FontAwesomeIcon icon={faChevronRight} className='tw-text-sm tw-text-gray-500' />
                    </div> 
                : null
            }
            <div className="hflex">
                <div onClick={delivery_route.is_user_manager && can_modify ? adminUpdateCustomPrice : null}>
                    <div>{getDisplayPrice(delivery_route.currency, (price + config_price))}</div>
                    {
                        cp.saving && cp.saving > 0 
                        ?   <div className='tw-text-gray-400 w3-strike tw-text-xs'>
                                {getDisplayPrice(delivery_route.currency, (price + cp.saving))}
                            </div>
                        :   null
                    }
                </div>
                <div className='tw-shrink-0'>
                    {
                        !can_modify
                        ?   qty 
                                ? <div className='tw-p-4'>x {qty}</div>
                                : ""
                        :   (!cp.qty || cp.qty < 0) && (!qty || qty < 0) && (!order_qty || order_qty < 0)
                                ?  <span className='tw-text-xs tw-text-red-600'>Out of stock</span>
                                :   <div className='tw-text-center'>
                                        {cp.sub_selections?.length
                                            ?   <Product.QtyButton qty={qty} 
                                                    onAdd={addSubProduct} 
                                                    onMinus={removeSubProduct} 
                                                />
                                            :   <Product.QtyButton qty={qty} 
                                                    onAdd={()=> updateQty(qty + 1)} 
                                                    onMinus={()=> updateQty(qty - 1)} 
                                                    onQtyClick={openEditQty} 
                                                />
                                        }
                                        {order_qty && order_qty !== qty ? <div className='tw-text-gray-400 tw-leading-[4px] tw-font-bold'><span className='tw-text-xxs'>Qty:</span> <span className='w3-strike tw-text-xs'>{order_qty}</span></div> : null}
                                        {   /* MAX PER USER */
                                            cp.max_qty_per_user
                                            ? <div className='tw-text-xxs tw-mt-1.5'>Maximum {cp.max_qty_per_user}</div>
                                            : null
                                        }
                                        {   /* STOCK QUANTITY */
                                            cp.qty > 10 && cp.qty < 30
                                            ?   <div className='tw-text-amber-600 tw-text-xs tw-mt-1'>{cp.qty} in stock</div>
                                            :   cp.qty > 0 && cp.qty <= 10
                                                ?  <div className='w3-text-red w3-tiny w3-round w3-no-wrap'>Only {cp.qty} left</div>
                                                :   null
                                        }
                                    </div>
                    }
                </div>
            </div>
        </div>
        <ResponsivePopup show={show_sub_selections} onClose={() => setShowSubSelections(false)} title={'Choose Add-ons'}>
            <ProductSubSelections delivery_route={delivery_route} sub_selections={cp.sub_selections} onAdd={onAddSubProduct} onUpdate={onUpdateSubProduct} config={sub_product} />
        </ResponsivePopup>
        <ResponsivePopup show={show_sub_products} onClose={() => setShowSubProducts(false)} title={'Repeat Add-ons ?'} style_props={{md_max_width: '50%'}}>
            <div className='tw-pb-10'>
                <div className='tw-pt-2 tw-pb-8'>
                    <div className='tw-px-4 tw-flex tw-gap-4 tw-items-center tw-mb-4'>
                        <Product.Image grid={false} cp={cp} delivery_route={delivery_route} />
                        <Product.Title cp={cp} className={`tw-mb-1 tw-text-sm`} />
                    </div>
                    <p className='tw-px-4 tw-text-xs tw-text-gray-600'>You can choose from your previously selected add-ons or add new.</p>
                    <div className='tw-px-4 tw-pt-4'>{
                        sub_products.map(([config_index]) => (
                            <div className='tw-border tw-rounded tw-mb-4' key={config_index}>
                                <SubProduct config_index={config_index} cart={cart} order={order}
                                    delivery_route={delivery_route} catalog_product={cp} setCp={setCp} buying_for_user={buying_for_user}
                                />
                            </div>
                        ))
                    }
                    </div>
                </div>
                <div className='tw-p-4 tw-absolute tw-bottom-0 tw-w-full tw-bg-white md:tw-text-center'>
                    <button className='tw-text-sm max-md:tw-w-full tw-border tw-border-secondary-lighter tw-text-secondary-lighter tw-rounded tw-px-4 tw-py-2' onClick={() => {setShowSubProducts(false); setShowSubSelections(true)}}>+ Select New Add-ons</button>
                </div>
            </div>
        </ResponsivePopup>
        </>
    )
}

export default ProductPriceQty