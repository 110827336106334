import { faCog, faIdBadge, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useCurrentUser } from 'base/app';
import { getByIds } from 'base/get_by_ids';
import { ChatSession, INBOX_MESSAGE_TYPE_WA_INCOMING_MSG } from 'base/ui/chat';
import { EditableTable } from 'base/ui/editable_table';
import { GenericException } from 'base/ui/errors';
import { SimpleTableInput, TagsInput, UserBadge } from 'base/ui/misc';
import { LoadingOverlay } from 'base/ui/status';
import { SuggestedField } from 'base/ui/suggested_field';
import { useOnScroll } from 'base/ui/utils';
import { last } from 'base/utils';
import { defined, useRerender, useRerenderWithValue } from 'base/utils/common';
import { broadcaster, useBroadcastedState, useLocalStorageState } from 'base/utils/events';
import { useEffect, useRef, useState } from 'react';
import './css/calendar.css';
import './setupTailwind';
import { NumberBadge } from './components/ui/commonUI';
import SearchBar from './components/ui/SearchBar';
import { Popup } from 'base/ui/popups';
import { MILLIS_IN_A_DAY } from 'base/constants';
import { DateView } from 'base/ui/date';

/*
  there is a check box - is staff,
  if selected should show the employee if checkbox,
  if org_user.employee_id is present checkbox autochecked
*/
function EditEmployeeId({admin_org_user, org_user, updates, org}) {
  const [is_staff, setIsStaff] = useState(!!org_user.employee_id);
  const [employee_id, setEmployeeId] = useState(org_user.employee_id || "");
  const current_user = useCurrentUser();

  if(!admin_org_user?.roles.admin && !current_user.roles.org_manager) return;
  return (
	<fieldset className={`tw-flex tw-flex-col tw-gap-2 ${is_staff ? "" : "tw-border-0"}`}>
	  <legend className={`tw-flex tw-gap-2 tw-text-sm tw-ml-2`}>
		<input className="w3-check" type='checkbox' checked={is_staff}
		  onChange={(e) => {
			setIsStaff(e.target.checked);
			updates.employee_id = e.target.checked ? employee_id : "";
		  }}
		/>
		<div>Is Staff</div>
	  </legend>
	  {
		is_staff
		? <div className='tw-space-y-2 tw-p-2'>
			<input type='text' className='w3-input'
			  defaultValue={employee_id}
			  placeholder='Employee ID'
			  onChange={(e) => {setEmployeeId(e.target.value); updates.employee_id = e.target.value}} 
			/>
			<div>Manager</div>
			<SuggestedField
			  props={{
				  "show_results_on_render": true,
				  "endpoint": `/api/admin/org/${org._id}/users?action=search&is_staff=true`,
				  "results_key_path": "org_users",
				  "title_format": "{name}",
				  "max_selections": 1,
				  "placeholder": "Search Manager",
				  "search_result_className": '!tw-font-normal tw-mt-2 !tw-shadow-none tw-divide-y tw-pl-2',
			  }}
			  selected={org_user.manager_user && [org_user.manager_user]}
			  onSelect={(manager_users) => {updates.manager_user_id = manager_users[0]?.user_id}}
			/>
			<div>Roles</div>
			<SimpleTableInput
			  rows={
				Object.entries(org_user.roles || {"": ["*"]})
				  .map(([role, permissions]) => [role, permissions.join(", ")])
			  }
			  nCols={2}
			  placeholders={['Role', 'Permissions']}
			  onAction={(action, _row, _rows) => {updates.roles = _rows}}
			/>
		  </div>
		: null
	  }
	</fieldset>
  )
}

function AddUserNameAndPhoneNumber({org_user, updates}) {
  return (
	<div className='tw-flex tw-flex-col tw-gap-2'>
	  <input type='text' className='w3-input' defaultValue={org_user.name || ""} onChange={(e) => {updates.name = e.target.value}} 
		placeholder='Name'
	  />
	  <input type='text' className='w3-input' defaultValue={org_user.email || ""} onChange={(e) => {updates.email = e.target.value}} 
		pattern='[0-9]{8, 13}' placeholder='Phone Number'
	  />
	</div>
  )
}

/* subscriptions */
function SubscriptionsPage({org_id, subscriptions:_subscriptions}) {

	const [subscriptions, setSubscriptions] = useState([]);
	useEffect(
		() => {
			setSubscriptions(
				Object.entries(
					_subscriptions 
					|| {"7_grade_fees": {"description": "7th Grade Fees", "ticket_type": "7th_grade", "amount": 100, "period": "monthly", "active": true}}
				).map(([subscription_id, subscription]) => {
					return {...subscription, "_id": subscription_id};
				})
			);
		}, []
	);

	return <EditableTable 
		cols={[
			{"title": "Subscription Id", "key": "_id"},
			{"title": "description", "key": "description"},
			{"title": "ticket_type", "key": "ticket_type"},
			{"title": "amount", "key": "amount"},
			{"title": "period", "key": "period"},
			{"title": "active", "key": "active"},
		]}
		rows={subscriptions}
		callbacks={{
			"onUpdate": async (subscription, updates) => {
				const resp = await axios.post(
					`/api/admin/org/${org_id}/subscriptions?action=update`,
					{"subscription_id": subscription._id, ...updates}
				);
				return [resp.data.subscription, resp.data.errors];
			}
		}}
	/>
}


/*
	OrgUsers
	filters -> search_text, is_staff, subscribers
*/
function OrgUsersPage({_org_id}){
	const [admin_org_user, setAdminOrgUser] = useState(null);
	const [org_users, setOrgUsers] = useState([]);
	const [org_id] = useLocalStorageState('org_id', _org_id || 'test');
	const ctx = useRef({"filters": {}}).current;
	const rerender = useRerender();
	const [filters_updated, setFiltersUpdated] = useRerenderWithValue();
	const [org, setOrg] = useState({});
	const usersRef = useRef(null);


	const fetchAdditionalData = async (org_user) => {
		let manager_user_id = org_user?.manager_user_id;
		if(manager_user_id){
			let cache = await getByIds({"user_ids": [manager_user_id]});
			org_user.manager_user = cache.users[manager_user_id];
		}
	}

	const onOrgUsersSearchReponse = (resp) => {
		let user_ids_to_fetch = [];
		resp.data.org_users.forEach(org_user => {org_user.manager_user_id && user_ids_to_fetch.push(org_user.manager_user_id)});
		ctx.has_more_users = resp.data.has_more_users;
		ctx.next_users_cursor = resp.data.next_users_cursor;
		getByIds({"user_ids": user_ids_to_fetch}).then((data) => {
		ctx.org_users = ctx.org_users || [];
		for(let org_user of resp.data.org_users) {
			org_user.manager_user = data.users[org_user.manager_user_id];
			ctx.org_users.push(org_user);
		}
		setOrgUsers([...ctx.org_users]);
		});
	}

	/* init */
	useEffect(
		() => {
			if (ctx.is_loading) return;
			ctx.is_loading = true;
			rerender();
			axios.post(`/api/admin/org/${org_id}/users`).then(
				(resp) => {
					if(resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
					resp.data.org && setOrg(resp.data.org);
					resp.data.admin_org_user && setAdminOrgUser(resp.data.admin_org_user);
					onOrgUsersSearchReponse(resp);
				}
			).finally(
				() => {
					ctx.is_loading = false;
					rerender();
				}
			);
		}, []
	);

	/* fetch more users */
	const searchAndFetchMoreUsers = () => {
		if (ctx.has_more_users === false || ctx.is_loading) return;
		ctx.is_loading = true;
		rerender();    
		const params = {
			"search_text": ctx.filters.search_text,
			"cursor": ctx.next_users_cursor
		}
		axios.post(`/api/admin/org/${org_id}/users?action=search`, params).then(
		(resp) => {
			if(resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
			onOrgUsersSearchReponse(resp);
		}
		).finally(() => {ctx.is_loading = false; rerender();});
	}

	/* refetch when filters changed */
	useEffect(
		() => {
		ctx.has_more_users = true;
		ctx.org_users = [];
		searchAndFetchMoreUsers();
		}, [filters_updated]
	); 

	/* auto load more on scroll */
	useOnScroll(
		usersRef.current, 
		(percent) => {percent === 100 && searchAndFetchMoreUsers()},
		[org_users]
	);

	/* handlers */
	const doCreateUser = () => {
		const new_row = {"__is_new": true, "__is_editing": true};
		setOrgUsers([new_row, ...org_users]);
	}

	const openChat = (org_user) => {
		ChatSession.open(
			`wcs_${org_user.user_id}_${org.wa_business_number}`,
			{
				"bottomStatusIndicator": (session_data) => {
					return <CsmCsChatSupportButtons 
						user_id={org_user.user_id} 
						session_data={session_data} 
						cs_activity_endpoint={`/api/admin/org/${org_id}/users`}
						ctx={ctx}
						onChatResolved={
							(needs_resolve) => {
								org_user.cs_unseen_count = 0;
								org_user.rerender?.(); // from editable table
							}
						}
					/>
				}
			}
		);
	}

	const showTicketSideView = (ticket_id) => {}

	const onUsersSettingsClick = (evt) => {
		Popup.showContextMenu(
			evt.currentTarget,
			<div className="tw-p-2">
			  <div onClick={() => Popup.show("Subscriptions", <SubscriptionsPage org={org}/>)}>Subscriptions</div>
			</div>
		);
	}

	
	if(!ctx.org_users) return <LoadingOverlay />

	return (
		<div className='tw-p-4'>
			<div className='hflex tw-flex-wrap tw-gap-x-4 tw-gap-y-2'>
				<div>
				<div className='tw-text-lg tw-font-semibold'>Users</div>
				<div className='tw-text-xs tw-text-gray-500 tw-mt-2'></div>
				</div>
				<div className='tw-ml-auto tw-flex tw-gap-4 tw-items-center'>
					<button onClick={onUsersSettingsClick} className="tw-pr-4">
						<FontAwesomeIcon icon={faCog} />                
					</button>
					<button className='btn-primary tw-text-xs tw-shrink-0' onClick={() => doCreateUser()}>
					+ Create User
					</button>
				</div>
			</div>
			<div className='tw-flex tw-gap-4 tw-mt-6 tw-flex-wrap-reverse'>
				<SearchBar placeholder={'Search Users'}
				onSearch={((search_text) => {ctx.filters.search_text= search_text; setFiltersUpdated()})}
				className={'tw-grow lg:tw-max-w-[40%]'}
				/>
			</div>
			{/* TODO: Place filter selection values here */}
			{/* Table View */}
			<div className={"tw-mt-4 lg:tw-max-h-[75vh] tw-overflow-y-auto tw-w-full"} ref={usersRef}>
				<EditableTable
				cols={[
					{
						"header": 'Name',
						"render": (org_user) => {
							if(!org_user.user_id) return null;
							return (
							<div className='tw-text-sm'>
								<UserBadge user={org_user} />
								{
								org_user.active_ticket_ids?.length > 0
								? <div>
									<div className='tw-text-xs tw-text-gray-500 tw-mt-1'>Active Tickets:</div>
									{
										org_user.map(
										ticket_id => (
											<div key={ticket_id}
											onClick={() => showTicketSideView(ticket_id)}
											>#{ticket_id}</div>
										)
										)
									}
									</div>
								: null
								}
							</div>
							)
						},
						"editor": (org_user, updates) => {
							if(org_user.user_id) return <UserBadge user={org_user} />;
							return <AddUserNameAndPhoneNumber org_user={org_user} updates={updates} />
						}
					},
					{
						"header": "Tags",
						"render": (org_user) => {
							if(!org_user.tags) return null;
							return (
								<div className='tw-text-sm' onClick={(e) => e.stopPropagation()}>
									<div className='tw-flex tw-gap-2 tw-flex-wrap tw-text-xs tw-font-medium'>
										{
											Object.entries(org_user.tags)?.map(
												([tag, value]) => (
													<div className='tw-bg-gray-bg tw-px-2 tw-py-1 tw-rounded-full' key={tag}
														title={value}
													>
														{tag}
													</div>
												)
											)
										}
									</div>
									{
										org_user.system_tags && Object.keys(org_user.system_tags).length > 0
										? 	<div className='tw-border-t'>
												<div className='tw-flex tw-gap-2 tw-flex-wrap tw-text-xs tw-font-medium'>
													{
														Object.entries(org_user.system_tags)?.map(
															([tag, value]) => (
																<div className='tw-bg-gray-bg tw-px-2 tw-py-1 tw-rounded-full' key={tag}
																	title={value}
																>
																	{tag}
																</div>
															)
														)
													}
												</div>
											</div>
										: null
									}
								</div>
							)
						},
						"editor": (org_user, updates) => {
							return <div>
								<SimpleTableInput
									rows={Object.entries(org_user.tags || {})}
									nCols={2}
									placeholders={['Tag', 'Value']}
									onAction={(action, _row, _rows) => {updates.tags = _rows}}
								/>
							</div>
						}
					},
					{
						/* Employee */
						"key": "employee_id",
						"header": "Employee",
						"render": (org_user) => {
							if(!org_user.employee_id) return null;
							return (
							<div>
								<div><FontAwesomeIcon icon={faIdBadge} /> {org_user.employee_id}</div>
								{
								org_user.manager_user
								?  <>Manager: <div className='tw-rounded-3xl tw-bg-gray-500 tw-px-2 tw-py-1 tw-inline tw-text-white'>
									{org_user.manager_user.name}
									</div></>
								: null
								}
								{
								org_user.roles
								? <div className='tw-text-xs tw-text-gray-500'>
									{Object.keys(org_user.roles).join(", ")}
									</div>
								: null
								}
							</div>
							)
						},
						"editor": (org_user, updates) => {
							return <EditEmployeeId org_user={org_user} updates={updates} admin_org_user={admin_org_user} org={org}/>
						}
					},
					{
						/* CUSTOMER SUPPORT */
						"render": (org_user) => (
							<div className='tw-relative tw-w-fit tw-p-2 tw-cursor-pointer'
								onClick={(e) => {e.stopPropagation(); openChat(org_user)}} 
							>
								{
									org_user.cs_unseen_count > 0
									? <NumberBadge val={org_user.cs_unseen_count} />
									: null
								}
								<FontAwesomeIcon icon={faMessage} className='tw-text-sm tw-text-gray-700 tw-absolute tw-left-0' />
							</div>
						)
					},
				]}
				rows={org_users} 
				callbacks={{
					"onUpdate": async (org_user, updates) => {
						let resp = null;

						if(updates.tags){
							updates.tags = Object.fromEntries(
								updates.tags.map(([tag, value]) => [tag, parseInt(value) || 0])
							);
						}

						if(!org_user.user_id){
							resp = await axios.post(
								`/api/admin/org/${org_id}/users?action=create_user`,
								{...updates, "user_id": org_user.user_id}
							);
						}
						else {
							resp = await axios.post(
								`/api/admin/org/${org_id}/users?action=update_user`,
								{...updates, "user_id": org_user.user_id}
							);
						}
						await fetchAdditionalData(resp.data.org_user);
						return [resp.data.org_user, resp.data.errors]; 
					},
					"onDelete": async (org_user) => {
						if(!org_user.user_id) return [true, null]; 
						const resp = await axios.post(
							`/api/admin/org/${org_id}/users?action=delete_user`,
							{"user_id": org_user.user_id}
						);
						return [resp.data.deleted, resp.data.errors];
					}
				}}
				/>
			</div>
		</div>
  	)
}

function CsmCsChatSupportButtons({
	org_id, user_id, session_data, onChatResolved,
}){
	return <div className='tw-flex tw-flex-row-wrap tw-font-white tw-text-sm tw-align-center'>
		<CsSupportChatButtons
			user_id={user_id} 
			session_data={session_data} 
			cs_activity_endpoint={`/api/admin/org/${org_id}/users`}
			onChatResolved={onChatResolved}
		/>
	</div>
}
/* 
	Chat support buttons
	- Resolve chat
	- Toggle bot active
	- Force take over customer support
*/
function CsSupportChatButtons({
	user_id, session_data, onChatResolved,
	cs_activity_endpoint: CS_ACTIVITY_ENDPOINT, 
	/* optional */
	ctx:_ctx
}){

	const [is_page_visible] = useBroadcastedState("visibility_changed", true);

	const [needs_resolve, _setNeedsResolve] = useState(session_data.needs_resolve);
	const [is_bot_active, setIsBotActive] = useState(session_data.is_bot_active);

	const [cs_staff_id, setCsStaffId] = useState(session_data.cs_staff_id);
	const [cs_staff_user, setCsStaffUser] = useState(null);
	const last_cs_active_at = session_data.cs_active_at || 0;

	const ctx = useRef(_ctx || {}).current;
	const user = useCurrentUser();

	const setNeedsResolve = (needs_resolve) => {
		_setNeedsResolve(needs_resolve);
		session_data.needs_resolve = needs_resolve;
	}

	const ping_cs_active = (force) => {
		if(session_data.last_cs_ping_at > now_millis - 5 * 1000) return;
		session_data.last_cs_ping_at = now_millis;
		const last_user_im_created_at = last(
			session_data.inbox_messages,
			(im) => {return im.src_id === user_id && im._type === INBOX_MESSAGE_TYPE_WA_INCOMING_MSG}
		)?.created_at;

		axios.post(
			CS_ACTIVITY_ENDPOINT,
			{
				"user_id": user_id, "action": "cs_active","force": force,
				"last_user_im_created_at": last_user_im_created_at
			}
		).then((resp) => {
			if(defined(resp.data.needs_resolve)) setNeedsResolve(resp.data.needs_resolve);
			setCsStaffId(resp.data.cs_staff_id);
		});
	}

	useEffect(
		() => {
			if(!cs_staff_id) return;
			getByIds({"user_ids": [cs_staff_id]}).then((cache) => {
				setCsStaffUser(cache.users[cs_staff_id]);
			});
		}, 
		[cs_staff_id]
	);

	const mark_cs_inactive = () => {
		axios.post(
			CS_ACTIVITY_ENDPOINT,
			{"user_id": user_id, "action": "cs_inactive"}
		);
	}

	const resolveChat = () => {
		axios.post(
			CS_ACTIVITY_ENDPOINT,
			{"user_id": user_id, "action": "resolve"}
		).then((resp) => {
			setNeedsResolve(resp.data.needs_resolve);
			onChatResolved && onChatResolved(resp.data.needs_resolve);
		});
	}  
	
	const _onCsChatActivity = (session_data) => {
		if(!session_data.session._id.startsWith("wcs_")) return;
		ctx.is_cs_active = true;
		ping_cs_active();
		setIsBotActive(false);
		if(ctx.cs_active_timer) clearInterval(ctx.cs_active_timer);
		/* */
		ctx.cs_active_timer = setInterval(() => ctx.is_cs_active && ping_cs_active(), 10 * 60 * 1000);
	}

	const toggleBotActive = () => {
		if (!is_bot_active) {
			mark_cs_inactive();
			ctx.is_cs_active = false;
			session_data.is_bot_active = true;
		} else {
			ping_cs_active();
			ctx.is_cs_active = true;
			session_data.is_bot_active = false;
		}  
		setIsBotActive(session_data.is_bot_active);
	}

	useEffect(
		() => {
			const onCsChatTyping = (session_data, el) => _onCsChatActivity(session_data);
			const onCsMessageSent = (im, session_data) => _onCsChatActivity(session_data);
			broadcaster.add_event_listener("chat:typing", onCsChatTyping);
			broadcaster.add_event_listener("chat:message_sent", onCsMessageSent);
			broadcaster.add_event_listener("chat:cs_activity", _onCsChatActivity); 
			return () => {
				clearInterval(ctx.cs_active_timer);
				broadcaster.remove_event_listener("chat:typing", onCsChatTyping);
				broadcaster.remove_event_listener("chat:message_sent", onCsMessageSent);
				broadcaster.remove_event_listener("chat:cs_activity", _onCsChatActivity);
			}
		}, []
	);

	useEffect(() => {ctx.is_cs_active && ping_cs_active()}, [is_page_visible]);


	const now_millis = new Date().getTime();
	return <>
		{
			needs_resolve
			?   <div className='tw-grow tw-px-4 tw-py-1 tw-bg-black'
					onClick={resolveChat}
				>☐ Resolve Chat</div>
			:   null
		}
		<div className={`tw-shrink-0 tw-px-4 tw-py-1 tw-bg-blue-500 ${!needs_resolve ? 'tw-min-w-[30%]' : ''}`}
			onClick={() => toggleBotActive()}
		>
			{!is_bot_active ? "☐" : "✅"} BOT
		</div>
		{
			/* recently active by another user */
		   last_cs_active_at > now_millis - 5 * 60 * 1000 && cs_staff_user && user && cs_staff_user._id !== user._id
			?   <div className='tw-bg-red-600 tw-flex tw-grow tw-items-center tw-flex-row tw-clear-both'>
					<div className='tw-pl-2'>{cs_staff_user.name} is handling this chat</div>
					<div className='tw-p-1 tw-pr-4 tw-ml-auto' onClick={() => ping_cs_active(true)}>Force</div>
				</div> /* change it to tailwind */
			: null
		}
	</>
}



export { OrgUsersPage, CsmCsChatSupportButtons, CsSupportChatButtons };
