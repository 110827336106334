import React, { useEffect } from 'react'
import { broadcaster, useBroadcastedState } from 'base/utils/events';
import { cache } from '../../../user';
import axios from 'axios';
import { sendStaffLocation, useStaffCheckIn } from '../../../useStaffLocation';
import { Popup } from 'base/ui/popups';
import { GenericException } from 'base/ui/errors';
import OrderSuccess from './OrderSuccess';
import { track_event } from '../../../analytics';
import NeedPhonePopup from '../shared/NeedPhonePopup';
import { openWhatsapp, useRerender } from 'base/utils/common';

const useCheckout = ({cart, delivery_route, buying_for_user, onCheckoutComplete}) => {
    const [is_page_visible] = useBroadcastedState("visibility_changed", true);
    const {checkin_route_id} = useStaffCheckIn();
    const rerender = useRerender();

    const onOrderSuccess = (resp) => {
        broadcaster.broadcast_event('order_updated', resp.data.order)
        cart.buying_for_user && setStaffLocationInfoOrders(resp.data.order);
        broadcaster.broadcast_event('cart_updated', {});
        /* auto logged in user */
        if (resp.data.user) {
            broadcaster.broadcast_event('user_updated', resp.data.user);
        }
        resp.data.order?._id && Popup.slideUp(
            <OrderSuccess order={resp.data.order} />,
            {remove_others: true}
        );
    }

    const setStaffLocationInfoOrders = (order) => {
        checkin_route_id === delivery_route?._id && sendStaffLocation(checkin_route_id, {order_id: order._id, buying_for_user_id: buying_for_user?._id});
    }

    const doCheckout = (user_number_params) => {
        var ui_block = Popup.blockUi(
            <span className='w3-center w3-bold'>Checking out..</span>
        );

        track_event("checkout");
        axios.post(
            `/api/checkout/${delivery_route._id}`, 
            {
                "cart_id": cart._id, // existing
                ...cart.settings,
                ...(user_number_params || {}),
            }
        ).then(
            (resp) => {
                if(resp.data.errors){
                    if (resp.data.errors.need_phone_user || resp.data.errors.invalid_phone_number) {
                        const popup = Popup.slideUp(
                            <NeedPhonePopup
                                description={'Please provide your WhatsApp Phone Number to receive Order Confirmation.'}
                                default_values={user_number_params}
                                invalid_phone_number={!!user_number_params}
                                submit_btn_text={'Checkout'}
                                onSubmit={(user_number_params) => {doCheckout(user_number_params); popup.close()}} 
                            />
                        )
                    } else {
                        Popup.show("errors while checking out", <GenericException ex={resp.data.errors} />)
                    }
                    return;
                }
                onCheckoutComplete && onCheckoutComplete();
                if(resp.data.confirmation_sent){
                    Popup.show(
                        "Order Confirmation",
                        <div className='w3-padding'>{resp.data.confirmation_sent}</div>
                    );
                    
                    // keep polling this cart every 8 seconds
                    cart.poll(new Date().getTime() + 5 * 60 * 1000);
                    return;
                }
                /* if order was created */
                if(resp.data.order){
                    onOrderSuccess(resp);
                    return;
                }
                if (resp.data.wa_business_number) {
                    openWhatsapp(resp.data.wa_business_number, resp.data.wa_message);
                }
                ui_block.remove();
            }
        ).catch(
            (ex) => {
                if (ex.response?.data) {
                    Popup.toast(<div>{ex.response.data}</div>)
                }
            }
        )
        .finally(
            () => ui_block.remove()
        )
    }

    /*
        Set this on the cart, so we don't have to pass down the chain.
        Also for popups, don't passdown callbacks, when they are opened, they are cached
        at the time of opening, and doesn't work as expected
    */
    if(cart) {
        cart.doCheckout = doCheckout;
        cart.buying_for_user = buying_for_user;
        cart.poll = (until) => {
            until && cache.set(`poll_cart_${cart._id}`, until);
            cart._poll = new Date().getTime(); // start polling
            rerender();
        }
    }

    /* cache cart change */
    useEffect(
        () => {
            if(!delivery_route) return;  // only cache after initial load
            cache.set([delivery_route._id, buying_for_user?._id, "cart_id"], cart?._id);
        }, [delivery_route?._id, cart?._id, buying_for_user?._id]
    );

    /* cart polling */
    useEffect(
        () => {
            if(!cart?._id || !cart?._poll || !is_page_visible || cart?.delivery_route_id !== delivery_route?._id) return;
            /* should poll ? */
            let should_poll_until = cache.get(`poll_cart_${cart._id}`);
            if(should_poll_until && should_poll_until < new Date().getTime()) return;
            axios.get(`/api/poll/checkout/${delivery_route._id}/${cart._id}`).then(
                (resp) => {
                    if(resp.data.order){
                        onOrderSuccess(resp);
                    } else {
                        if(resp.data.cart){
                            broadcaster.broadcast_event('cart_updated', resp.data.cart);
                        }
                        if (should_poll_until) {
                            setTimeout(cart.poll, 8000);
                        }
                    }
                }
            );
        }, [is_page_visible, cart?._poll]
    );
}

export default useCheckout