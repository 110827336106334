import React from "react";
import { useBroadcastedState } from "base/utils/events";
import b2c_page2 from "../../images/landing/b2c/page2.webp"
import b2c_page1 from "../../images/landing/b2c/page1.webp"
import feature1 from "../../images/landing/b2c/feature1.webp"
import feature2 from "../../images/landing/b2c/feature2.webp"
import feature3 from "../../images/landing/b2c/feature3.webp"
import darling1 from "../../images/landing/b2c/darling1.webp"
import darling2 from "../../images/landing/b2c/darling2.webp"

import bgPage1 from "../../images/landing/page1_bg.svg"
import bgPage2 from "../../images/landing/page2_bg.svg"
import bgBoxes from "../../images/landing/boxes_bg.svg"

import ic_users from "../../images/ic_landing_users.svg"
import ic_swap from "../../images/landing/ic_swap.svg"
import ic_filter from "../../images/landing/ic_filter.svg"
import ic_star from "../../images/ic_landing_star.svg"
import ic_soft_star from "../../images/landing/ic_soft_star.svg"

import integration_1 from "../../images/landing/integration_1.png"
import integration_2 from "../../images/landing/integration_2.png"
import integration_3 from "../../images/landing/integration_3.png"
import integration_4 from "../../images/landing/integration_4.png"
import integration_5 from "../../images/landing/integration_5.png"

const darlingSectionData = [
  {
    title: "Faster Onboarding",
    desc: "Through Sukhiba Connect they were able to onboard all salons within 5 mins.",
  },
  {
    title: "Broadcasted deals",
    desc: "Sales Staff sends broadcast of all deals and messages on salons on WhatsApp and are able to get orders on WhatsApp",
  },
  {
    title: "Notifying Salons",
    desc: "They are able to intimate salons every time their van passes in the area to get advance sales.",
  },
  {
    title: "1 min booking",
    desc: "Salons are able to book orders within 1 min on WhatsApp and also engage with their community in a seamless manner",
  },
];

const features_list = ["Take the market to your customers", "Set up a No-Code Storefront", "Grow Revenue"]

const integration_icons = [integration_1, integration_2, integration_3, integration_4, integration_5]

function Page2() {
  return <div
    id="features"
    style={{ backgroundImage: 'url(' + bgPage2 + ')', backgroundSize: 'cover' }}
    className="md:tw-px-16 max-md:tw-px-8 tw-py-16 font-figtree tw-text-landing-black  ">
    <div className={`tw-border-landing-gray lg:tw-flex max-lg:tw-grid tw-justify-center tw-gap-10 tw-my-2`}>
      <div className="tw-px-8 tw-py-8 tw-text-center tw-bg-white tw-border tw-rounded-xl lg:tw-w-[520px]">
        <p className="tw-text-[22px] tw-text-landing-green !tw-font-medium tw-mb-2">
          {"GET SET"}
        </p>
        <p className="tw-text-[20px] md:tw-text-[28px] tw-text-black !tw-font-medium tw-mb-2">
          {"Set up a no-code storefront"}
        </p>
        <p className="tw-text-[14.4px] md:tw-text-[18px] tw-mb-2 md:tw-whitespace-pre tw-text-[#666666]">
          {"Directly upload your product catalog in a CSV or\nsheet, and you're ready to go"}
        </p>

        <div className="tw-text-[16px] md:tw-text-[22px] tw-flex tw-gap-6 tw-justify-center !font-figtree-medium tw-my-6">
          <div className="tw-border-t tw-border-landing-black tw-w-[100px] tw-mt-3 md:tw-mt-4" />OR<div className="tw-border-t tw-border-landing-black tw-w-[100px] tw-mt-3 md:tw-mt-4" />
        </div>
        <p className="tw-text-[20px] md:tw-text-[28px] tw-text-black !tw-font-medium tw-mb-2">
          {"Or integrate with"}
        </p>
        <p className="tw-text-[14px] md:tw-text-[18px] tw-mb-2  md:tw-whitespace-pre">
          {"Zoho, Microsoft Dynamics, Oracle, Sap,\nShopify, Woo, Odoo. "}
        </p>
        <p className="tw-text-[14px] md:tw-text-[18px] tw-font-semibold tw-text-black tw-mb-2">
          {"and 20 more"}
        </p>

        <div className="tw-flex tw-gap-2 md:tw-gap-4 tw-mt-8 tw-justify-center">
          {integration_icons.map((icon, j) => (
            <div key={j}>
              <img
                style={{ width: 52, height: 52 }}
                src={icon}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="tw-relative max-lg:tw-mt-4 tw-pt-8 tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-landing-gray lg:tw-w-[520px]">
        <p className="tw-px-8  tw-text-[22px] tw-text-landing-green !tw-font-medium tw-mb-2">
          {"GO"}
        </p>
        <p className="tw-text-[20px] md:tw-text-[28px] tw-px-8  tw-text-black !tw-font-medium tw-mb-2">
          {"Take the Market to your Customers"}
        </p>
        <p className="tw-text-[14.4px] md:tw-text-[18px] tw-mb-12 md:tw-whitespace-pre  tw-px-8  tw-text-[#666666]">
          {"Meet and greet customers where they are already having\nconversations, to bring them great deals and value."}
        </p>
        <img src={b2c_page2} className={"tw-w-wrap tw-rounded-b-xl"} />
      </div>
    </div>
  </div>
}

function Page3() {
  return <div
    className="font-figtree tw-p-8 md:tw-py-16 tw-bg-[#052F2F] tw-text-white "
    style={{ backgroundImage: 'url(' + bgBoxes + ')', backgroundRepeat: 'repeat' }}>
    <div
      className={`lg:tw-flex max-lg:tw-grid tw-justify-center tw-items-center tw-gap-8 xl:tw-gap-12 `}>
      <div className="">
        <div className="tw-text-[24px] md:tw-text-[40px] lg:tw-text-[32px] xl:tw-text-[40px] tw-font-semibold !tw-font-medium ">
          Add the secret ingredient
        </div>
        <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px]  xl:tw-text-[22px] md:tw-whitespace-pre !tw-font-light tw-text-white/[0.7] tw-mt-4">
          {"Send broadcast notifications to all customers. Use custom\n" +
            "templates, or personalize your messages based on select\n" +
            "customer behaviors with Sukhiba's AI Assistant"}
        </p>
        <div className="md:tw-flex tw-justify-start xl:tw-justify-between tw-mt-8 tw-gap-16">
          <div className="">
            <div className="tw-text-[36px] md:tw-text-[48px] lg:tw-text-[36px] xl:tw-text-[48px] tw-text-landing-yellow tw-font-semibold tw-flex tw-items-center tw-gap-4">
              {`95%`}
              <img src={ic_soft_star} className="tw-w-[27px] md:tw-w-[36px]" />
            </div>
            <div className="tw-text-[18px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre !tw-font-light tw-text-white/[0.7]">
              {"of WhatsApp messages are\nopened within 3 minutes of\nsending."}
            </div>
          </div>
          <div className="max-md:tw-mt-8">
            <div className="tw-text-[36px] md:tw-text-[48px] lg:tw-text-[36px] xl:tw-text-[48px] tw-text-landing-yellow tw-font-semibold tw-flex tw-items-center tw-gap-4">
              {`85%`}
              <img src={ic_soft_star} className="tw-text-[27px] md:tw-w-[36px]" />
            </div>
            <div className="tw-text-[18px] md:tw-text-[22px]lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre !tw-font-light tw-text-white/[0.7]">
              {"consumers express interest in\nreceiving communications\nfrom brands via WhatsApp."}
            </div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-justify-center">
        <img src={feature1} className="max-md:tw-mt-8" />
      </div>
    </div>
  </div>
}
function Page4() {
  return <div
    className="font-figtree md:tw-p-16 max-lg:tw-p-12 tw-bg-white tw-text-landing-black">
    <div
      className={`md:tw-grid lg:tw-flex tw-justify-center tw-gap-4 md:tw-gap-12 `}>
      <div className="">
        <img src={feature3} className="max-lg:tw-mt-6" />
      </div>
      <div className="">
        <div className="tw-text-[22px] md:tw-text-[40px] lg:tw-text-[32px] xl:tw-text-[40px] tw-text-black max-lg:tw-mt-8 !tw-font-medium md:tw-whitespace-pre">
          {"Worry less about who's running\nthe show & focus more on\nbusiness growth."}
        </div>
        <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre !tw-font-light tw-mt-4">
          {"From support tickets and customer requests, to booking\nappointments, the Sukhiba AI Assistant has your back."}
        </p>
        <div className="tw-flex tw-items-start tw-mt-4 tw-text-[14px] md:tw-text-[20px] lg:tw-text-[18px] xl:tw-text-[20px] tw-text-black tw-gap-2">
          <img src={ic_swap} className="tw-w-[16px] tw-mt-1 lg:tw-mt-0.5 xl:tw-mt-1 md:tw-w-[24px]" />
          <div>Track and assign ownership to create support tickets</div>
        </div>
        <div className="tw-flex tw-items-start tw-mt-4 tw-text-[14px] md:tw-text-[20px] lg:tw-text-[18px] xl:tw-text-[20px] tw-text-black tw-gap-2">
          <img src={ic_users} className="tw-w-[16px] tw-mt-1 lg:tw-mt-0.5 xl:tw-mt-1 md:tw-w-[24px]" />
          <div>View all your customer requests in a single inbox</div>
        </div>
        <div className="tw-flex tw-items-start tw-mt-4 tw-text-[14px] md:tw-text-[20px] lg:tw-text-[18px] xl:tw-text-[20px] tw-text-black tw-gap-2">
          <img src={ic_filter} className="tw-w-[16px] tw-mt-1 lg:tw-mt-0.5 xl:tw-mt-1 md:tw-w-[24px]" />
          <div className="max-xl:tw-whitespace-pre">{"Auto-generate appointment bookings so customers\nare penciled in faster"}</div>
        </div>
        <div className="tw-flex tw-mt-4 tw-text-[14px]  lg:tw-text-[16px] xl:tw-text-[20px] md:tw-text-[22px] tw-p-4 tw-text-black md:tw-whitespace-pre tw-gap-4 tw-border tw-rounded-2xl tw-border-[#D1D1D1]">
          <img src={ic_soft_star} className="tw-w-[22px] md:tw-w-[35px]" />
          <div>{"Be available to customers on a single number,\nget their messages at unique stores based on location"}</div>
        </div>
      </div>
    </div>
  </div>
}
function Page5() {
  return <div
    className="font-figtree tw-px-8 tw-pb-8 md:tw-p-16 tw-bg-white tw-text-landing-black">
    <div
      className={`tw-flex tw-justify-center tw-items-center max-md:tw-flex-wrap tw-gap-12 max-lg:tw-flex-col-reverse`}>
      <div className="">
        <div className="tw-text-[22px] md:tw-text-[40px] lg:tw-text-[32px] xl:tw-text-[40px] tw-text-black !tw-font-medium md:tw-whitespace-pre">
          {"Personalize communication,\nat scale"}
        </div>
        <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre !tw-font-light tw-mt-4">
          {"Harness the power of AI to answer questions on your\ncatalog and recommend product/shopping options"}
        </p>
        <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] md:tw-whitespace-pre !tw-font-light tw-mt-4">
          {"Take the guesswork out of segmentation and generate\ncustom marketing messages based on customer behaviors"}
        </p>
      </div>
      <div className="">
        <img src={feature2} className="max-md:tw-mt-6" />
      </div>

    </div>

  </div>
}
function Page6() {
  return <div
    style={{ backgroundImage: 'url(' + bgPage2 + ')', backgroundSize: 'cover' }}
    className="tw-px-12 tw-py-12 font-figtree tw-text-landing-black tw-text-center ">

    <div className="tw-text-[40px] !tw-font-semibold tw-display-block xl:tw-whitespace-pre tw-leading-tight">
      {"How Darling Group increased\nsales per staff by 50% using Sukhiba Connect"}
    </div>

    <div className="tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] tw-text-black/[0.7] tw-display-block xl:tw-whitespace-pre tw-mt-4">
      <span className="tw-text-red-500">Initial Problem</span>
      {" : Their sales staff could at max visit 40 salons per day and were unable to scale this since there\nare more than 500K salons in Kenya alone."}
    </div>

    <div className="tw-flex tw-mt-8 max-lg:tw-flex-col tw-text-start tw-justify-center tw-gap-16 lg:tw-gap-8 xl:tw-gap-16 tw-items-center ">
      <div className="tw-w-[260px]">
        <img src={
          "https://storage.googleapis.com/sukhiba//user_uploads/2023-05-31/1685540732V8uJUWPw0u__darling.png"
        } />
        <p className="tw-text-[16px] lg:tw-text-[14px] xl:tw-text-[16px] tw-text-black/[0.7] md:tw-mt-4 tw-leading-tight">
          {"Darling sells to salons across Africa.\nThey do this by hiring sales staff\nwho visits salons & takes orders."}
        </p>
      </div>
      <div>
      <div className="md:tw-flex tw-gap-8 lg:tw-gap-2 xl:tw-gap-8">
          <div className="tw-bg-white tw-display-block tw-rounded-2xl tw-border tw-border-[#EBEBEB] md:tw-w-[372px] tw-p-4 tw-text-start">
            <div className="tw-text-[24px] lg:tw-text-[22px] xl:tw-text-[24px] tw-text-black tw-font-medium">{darlingSectionData[0].title}</div>
            <div className="tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-text-black/[0.7] tw-mt-2">{darlingSectionData[0].desc}</div>
          </div>
          <div className="tw-bg-white tw-display-block tw-rounded-2xl tw-border tw-border-[#EBEBEB] md:tw-w-[372px] tw-p-4 tw-text-start">
            <div className="tw-text-[24px] lg:tw-text-[22px] xl:tw-text-[24px] tw-text-black tw-font-medium">{darlingSectionData[1].title}</div>
            <div className="tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-text-black/[0.7] tw-mt-2">{darlingSectionData[1].desc}</div>
          </div>
      </div>
      <div className="tw-mt-2 xl:tw-mt-8 md:tw-flex tw-gap-8 lg:tw-gap-2 xl:tw-gap-8">
          <div className="tw-bg-white tw-display-block tw-rounded-2xl tw-border tw-border-[#EBEBEB] md:tw-w-[372px] tw-p-4 tw-text-start">
            <div className="tw-text-[24px] lg:tw-text-[22px] xl:tw-text-[24px] tw-text-black tw-font-medium">{darlingSectionData[2].title}</div>
            <div className="tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-text-black/[0.7] tw-mt-2">{darlingSectionData[2].desc}</div>
          </div>
          <div className="tw-bg-white tw-display-block tw-rounded-2xl tw-border tw-border-[#EBEBEB] md:tw-w-[372px] tw-p-4 tw-text-start">
            <div className="tw-text-[24px] lg:tw-text-[22px] xl:tw-text-[24px] tw-text-black tw-font-medium">{darlingSectionData[3].title}</div>
            <div className="tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-text-black/[0.7] tw-mt-2">{darlingSectionData[3].desc}</div>
          </div>
      </div>
      </div>
      {/* <div className=" tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8">
        {darlingSectionData?.map((item, i) => (
          <div key={item.title} className="tw-bg-white tw-display-block tw-rounded-2xl tw-border tw-border-[#EBEBEB] md:tw-w-[372px] tw-p-4 tw-text-start">
            <div className="tw-text-[24px] lg:tw-text-[22px] xl:tw-text-[24px] tw-text-black tw-font-medium">{item.title}</div>
            <div className="tw-text-[18px] lg:tw-text-[16px] xl:tw-text-[18px] tw-text-black/[0.7] tw-mt-2">{item.desc}</div>
          </div>
        ))}
      </div> */}
    </div>

  </div>
}

function Page7() {
  return <div className="tw-px-12 tw-py-12 font-figtree tw-bg-white tw-text-landing-black">
    <div className="tw-flex tw-mt-8 max-lg:tw-flex-col tw-justify-center tw-gap-16 md:tw-gap-24 tw-items-center">
      <div className="md:tw-text-center md:tw-w-[526px]">
        <img src={darling1} />
        <div className="tw-text-[22px] md:tw-text-[40px] lg:tw-text-[22px] xl:tw-text-[40px] tw-text-black tw-font-semibold tw-mt-4">Fast, Flexible Payments</div>
        <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] tw-text-black/[0.7] md:tw-mt-4 tw-leading-tight">
          {"Accept payments via WhatsApp across providers. Track and manage payment statuses online with quick dashboards Accept QR Payments, Credit/Debit Card, and 18+ Payment Methods."}
        </p>
      </div>
      <div className="md:tw-text-center md:tw-w-[526px]">
        <img src={darling2} />
        <div className="tw-text-[22px] md:tw-text-[40px] lg:tw-text-[22px] xl:tw-text-[40px] tw-text-black tw-font-semibold tw-mt-4">Rewards and Loyalty</div>
        <p className="tw-text-[14px] md:tw-text-[22px] lg:tw-text-[18px] xl:tw-text-[22px] tw-text-black/[0.7] md:tw-mt-4 tw-leading-tight">
          {"Build a scan to sign-up approach to your loyalty program and coupon distribution using QR codes  Process refunds, send credits and rewards directly to a customer's WhatsApp wallet."}
        </p>
      </div>
    </div>

  </div>
}

function B2CPage() {
  const [screen] = useBroadcastedState("tw_screen_size");

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none font-figtree ">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
        className="tw-relative tw-py-12 tw-px-10 lg:tw-flex tw-items-center tw-justify-center md:tw-gap-8 xl:tw-gap-12">
        <div className="tw-text-landing-black">
          <div className="tw-text-[30px] md:tw-text-[48px] !tw-font-semibold lg:tw-text-[60px] tw-leading-tight">
            One <span className="tw-text-landing-green lg:tw-whitespace-pre">Conversation{"\n"}Channel</span>
            <span className="lg:tw-whitespace-pre">, a Lifetime{"\n"}of Growth</span>
          </div>
          <div className="md:tw-whitespace-pre tw-font-light tw-mt-4 tw-text-[22px]">Unlock consistent revenue growth by introducing{"\n"}WhatsApp into your marketing mix</div>
          <div className="tw-mt-8 tw-flex tw-text-center tw-text-[20px]">
            <div
              className="max-md:tw-flex-1 tw-rounded-full tw-bg-landing-green tw-px-8 tw-text-white md:tw-py-3.5 max-md:tw-py-2"
              onClick={() => {
                window.open("/contactus", "_self")
              }}>
              Schedule a demo
            </div>
          </div>
        </div>
        <div className="lg:tw-w-[600px]  max-lg:tw-mt-8">
          <img src={b2c_page1} />
        </div>
      </div>
      <div className="tw-bg-[#093232] tw-p-4 tw-gap-4 tw-flex w3-hide-scrollbar tw-overflow-x-auto md:tw-justify-center">
        {
          features_list.map((feature, i) => {
            return <div key={i} className="max-lg:tw-mx-4 tw-flex tw-gap-2 tw-text-white tw-text-[18.5px] md:tw-text-[24.5px] tw-whitespace-nowrap">
              <img src={ic_star} className="tw-w-[22px]" /> {feature}
            </div>
          })
        }
      </div>
      {/* Page 2 */}
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      {
        screen.lg ? <Page6 /> : null
      }
      <Page7 />

    </div>
  );
}

export default B2CPage;
