import React, { useEffect, useState, useRef } from "react";
import { faBars, faCamera, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserActionIcons } from "../user";
import OnboardingPopup from "../components/features/login/LoginPopup";
import OnboardingForm from "../components/features/login/OnboardingForm";
import { useRerender } from "base/utils/common";
import { useOnScroll } from "base/ui/utils";
import SideSheet from "../components/ui/SideSheet";
import SukhibaLogo from "../images/ic_logo_sukhiba.svg"
import { useBroadcastedState } from "base/utils/events";
import HomePage from "./landing/HomePage";
import B2BPage from "./landing/B2BPage";
import B2CPage from "./landing/B2CPage";
import InstagramIcon from "../images/instagram.svg"
import WebIcon from "../images/web.svg"
import TwitterIcon from "../images/twitter.svg"
import LinkedinIcon from "../images/landing/ic_social_linkedin.svg"
import YoutubeIcon from "../images/youtube.svg"
import IndustriesPage from "./landing/IndustriesPage";
import PartnershipsPage from "./landing/PartnershipsPage";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import bgPage1 from "../images/landing/page1_bg.svg"
import bgBoxes from "../images/landing/boxes_bg.svg"
import ContactUsPage from "./landing/ContactUsPage";
import PartnerPopup from "./landing/PartnerPopup";
import SuccessPopUp from "./landing/SuccessPopUp";
import ThankyouPage from "./landing/ThankyouPage";

const LandingTabs = {
  HOME: 'Home',
  B2B: 'b2b',
  B2C: 'b2c',
  Industries: 'industries',
  Partnerships: 'partnerships',
  'Contact Us': 'contactus',
  ThankYou: 'thankyou'
}

function getTabFromLocation(location) {
  const paths = location.pathname.split("/")
  const path_name = paths[paths.length - 1]  
  var tab = LandingTabs.HOME
  path_name.toLowerCase() === "home"
    ? tab = LandingTabs.HOME
    : path_name.toLowerCase() === "b2b"
      ? tab = LandingTabs.B2B
      : path_name.toLowerCase() === "b2c"
        ? tab = LandingTabs.B2C
        : path_name.toLowerCase() === "industries"
          ? tab = LandingTabs.Industries
          : path_name.toLowerCase() === "partnerships"
            ? tab = LandingTabs.Partnerships
            : path_name.toLowerCase() === "thankyou"
              ? tab = LandingTabs.ThankYou
              : path_name.toLowerCase() === "contactus"
                ? tab = LandingTabs['Contact Us']
                : tab = LandingTabs.HOME
  return tab
}

function LandingPage() {
  const [show_menu, setShowMenu] = useState(false);
  const [search_params, setSearchParams] = useSearchParams();
  const ctx = useRef({}).current;
  const [show_onboarding, setOnboarding] = useState(false);
  const rerender = useRerender();
  const [screen] = useBroadcastedState("tw_screen_size");
  const [selected_tab, setSelectedTab] = useState(getTabFromLocation(useLocation()))
  const [show_partner_popup, setShowPartnerPopup] = useState(false);
  const [show_success_popup, setShowSuccessPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.scrollBehavior = "smooth";
    return () => (document.body.style.scrollBehavior = null);
  }, []);

  useEffect(() => {
    // search_params.set('t', selected_tab);
    // setSearchParams(search_params)
    document.body.scrollTop = 0;
    if (selected_tab === "thankyou") {
      navigate("/" + selected_tab, { replace: true })
    } else { navigate("/" + selected_tab, { replace: true }) }
  }, [selected_tab])


  useOnScroll(
    document.body, (percent) => {
      if (percent > 10) {
        if (!ctx.show_scroll_top) {
          ctx.show_scroll_top = true;
          rerender();
        }
      } else {
        if (ctx.show_scroll_top) {
          ctx.show_scroll_top = false;
          rerender();
        }
      }
    }, []
  )

  const Menu = ({ classname, onItemClick, sidebar }) => (
    <>
      {
        sidebar
          ? <>
            <p className={`${classname} tw-py-1 tw-font-bold tw-text-xl`}>Menu</p>
            <div className={classname} onClick={() => {
              setSelectedTab(LandingTabs.HOME)
              onItemClick && onItemClick()
            }}>
              Home
            </div>
          </>
          : null
      }
      {
        Object.keys(LandingTabs).map(id => {
          return id !== "HOME" && id !== "Contact Us" && id !== "ThankYou"
            ? <div key={id} className={`font-figtree ${classname} ${selected_tab === LandingTabs[id] ? 'tw-text-landing-green' : ''} `} onClick={() => {
              setSelectedTab(LandingTabs[id])
              onItemClick && onItemClick()
            }}>
              {id}
            </div>
            : null
        })
      }
    </>
  );

  function scheduleDemoViaWhatsapp() {
    var message = "Hi, I want to request for a demo of your service.";
    if (ctx.name) {
      message = message + "\n Name: " + ctx.name;
    }
    if (ctx.email) {
      message = message + "\n Email: " + ctx.email;
    }
    if (ctx.phone) {
      message = message + "\n Phone: " + ctx.phone;
    }
    const url = "https://wa.me/+254750926135?text=" + message;
    window.open(url, "_blank");
  }

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none">
      <div
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
        className="tw-sticky tw-top-0 tw-flex tw-px-8 tw-py-6 lg:tw-px-16 tw-text-black tw-justify-between tw-items-center tw-z-10">
        <div className="tw-cursor-pointer" onClick={() => setSelectedTab(LandingTabs.HOME)}>
          <img src={SukhibaLogo} className="tw-h-[33px]" />
        </div>
        <div className="font-figtree max-lg:tw-hidden tw-flex tw-gap-6 tw-items-center tw-text-[15px]">
          <Menu classname={" tw-text-landing-black tw-cursor-pointer"} />
          <div
            className="max-lg:tw-flex-1 tw-cursor-pointer font-figtree tw-text-center tw-rounded-full tw-bg-landing-green tw-px-3 tw-text-white tw-py-2"
            onClick={() => {
              setSelectedTab(LandingTabs['Contact Us'])
            }}>
            Schedule a Demo
          </div>
        </div>
        <div className="lg:tw-hidden">
          <FontAwesomeIcon
            icon={faBars}
            className="tw-p-4 tw--m-4 tw-h-6"
            color="black"
            onClick={() => setShowMenu(true)}
          />
        </div>
      </div>
      <SideSheet show={show_menu} onClose={() => setShowMenu(false)}>
        <div className="tw-text-sm tw-tracking-widest tw-whitespace-nowrap">
          <Menu
            classname={"font-figtree-regular tw-block tw-p-4"}
            onItemClick={() => setShowMenu(false)}
            sidebar={true}
          />
          <div
            className="tw-block tw-m-2 tw-text-center font-inter tw-rounded-full tw-bg-landing-green tw-px-3 tw-text-white tw-py-2"
            onClick={() => {
              setShowMenu(false)
              setSelectedTab(LandingTabs['Contact Us'])
            }}>
            Schedule a Demo
          </div>
        </div>
      </SideSheet>

      {/* Page 1 */}
      {
        selected_tab === LandingTabs.HOME
          ? <HomePage />
          : selected_tab === LandingTabs.B2B
            ? <B2BPage />
            : selected_tab === LandingTabs.B2C
              ? <B2CPage />
              : selected_tab === LandingTabs.Industries
                ? <IndustriesPage />
                : selected_tab === LandingTabs.Partnerships
                  ? <PartnershipsPage />
                  : selected_tab === LandingTabs['Contact Us']
                    ? <ContactUsPage />
                    : selected_tab === LandingTabs.ThankYou
                      ? <ThankyouPage />
                      : null
      }
      {/* Page 4 */}
      {
        selected_tab !== LandingTabs.HOME && selected_tab !== LandingTabs['Contact Us'] && selected_tab !== LandingTabs.ThankYou ?
          <div
            className="font-figtree tw-p-12 tw-bg-[#052F2F] tw-text-white tw-text-center"
            style={{ backgroundImage: 'url(' + bgBoxes + ')', backgroundRepeat: 'repeat' }}
          >
            <h1 className="tw-text-[28px] md:tw-text-[36px] font-figtree-semibold md:tw-whitespace-pre tw-leading-tight">
              {"See what Sukhiba can do for your\nbusiness on WhatsApp"}
            </h1>

            <div className="tw-justify-center tw-pt-6 tw-pb-2 tw-text-center tw-text-md">
              <button
                className="tw-rounded-full tw-bg-landing-green tw-text-white tw-px-9 tw-py-3.5"
                onClick={() => {
                  if (selected_tab === "partnerships") {
                    setShowPartnerPopup(true)
                  } else {
                    setSelectedTab(LandingTabs['Contact Us'])
                  }
                }}>
                {selected_tab === "partnerships" ? "Partner With Us" : "Schedule a demo"}
              </button>
            </div>
          </div>
          : null
      }
      {/* Footer */}
      <div className="font-figtree tw-px-8 md:tw-px-12 tw-py-12 tw-border tw-border-t" style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}>
        <div className="tw-flex max-md:tw-flex-col tw-gap-12 tw-justify-between lg:tw-justify-around">
          <div className="tw-flex tw-flex-col tw-items-center">
            <img src={SukhibaLogo} className="tw-h-[33px] tw-w-fit" />
            <div className="tw-flex tw-items-center tw-mt-10 md:tw-mt-12 tw-gap-6">
              {/* <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
                onClick={() => {
                  window.open("https://www.instagram.com/sukhibaconnect")
                }}
              >
                <img src={InstagramIcon} className="tw-h-5" />
              </div>
              <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
                onClick={() => {
                  window.open("https://sukhiba.com")
                }}
              >
                <img src={WebIcon} className="tw-h-5" />
              </div> */}
              {/* <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
                onClick={() => {
                  window.open("https://twitter.com/sukhiba")
                }}
              >
                <img src={TwitterIcon} className="tw-h-4" />
              </div> */}
              <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
                onClick={() => {
                  window.open("https://www.linkedin.com/company/69195454/")
                }}
              >
                <img src={LinkedinIcon} className="tw-h-40" />
              </div>
              <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
                onClick={() => {
                  window.open("https://www.youtube.com/@sukhiba")
                }}
              >
                <img src={YoutubeIcon} className="tw-h-4" />
              </div>
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-12">
            <div>
              <p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold">Products</p>
              {
                Object.keys(LandingTabs).map(id => {
                  return id !== "HOME" && id !== 'Contact Us' && id !== 'ThankYou'
                    ? <p key={id} className={"tw-text-[14px] md:tw-text-[16px] tw-mt-4 tw-font-medium tw-text-gray-500 tw-cursor-pointer"} onClick={() => {
                      setSelectedTab(LandingTabs[id]);
                    }}>
                      {id}
                    </p>
                    : null
                })
              }
            </div>
            <div>
              <p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold">Terms</p>
              <p className="tw-text-[14px] md:tw-text-[16px] tw-mt-4 tw-font-medium tw-text-gray-500">
                <a href="/tnc/" target="_blank">
                  Terms & Conditions
                </a>
              </p>
              <p className="tw-text-[14px] md:tw-text-[16px] tw-mt-4 tw-font-medium tw-text-gray-500">
                <a href="/privacy_policy/" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </div>
            {/* <div>
              <p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold">Company</p>
              <p className={"tw-text-[14px] md:tw-text-[16px] tw-mt-4 tw-font-medium tw-text-gray-500 tw-cursor-pointer"} onClick={() => {
                setSelectedTab(LandingTabs['Contact Us']);
                document.body.scrollTop = 0;
              }}>
                Contact Us
              </p>
            </div> */}
            <div>
              <p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold">Contact</p>
              <div className="tw-mt-4">
                {/* <p className="tw-text-[16px] md:tw-text-[18px]">Email</p> */}
                <p className=" tw-text-gray-500">connect@sukhiba.com</p>
              </div>
              <div className="tw-mt-4">
                {/* <p className="tw-text-[16px] md:tw-text-[18px]">Phone</p> */}
                <p className="tw-text-gray-500"> +254 750 926 135</p>
              </div>
            </div>
          </div>


        </div>
      </div>

      {/* Page 6 */}
      {/* <div
        id="forsme"
        className="tw-px-10 md:tw-px-16 lg:tw-px-28 tw-py-16 lg:tw-py-20 tw-bg-gray-bg"
      >
        <h2 className="font-inter tw-text-[7vw] md:tw-text-[5vw] lg:tw-text-[40px] lg:tw-whitespace-pre">
          Join as{" "}
          <span className="tw-text-primary-dark">
          SME{'\n'}
          </span>
          using Sukhiba Connect
        </h2>
        <h6 className="font-helvetica tw-mt-8">
          Unlock exponential growth for your brand by leveraging the power of <span className="tw-text-primary-dark tw-font-bold">WhatsApp, connecting, engaging, and selling more to your customers</span> in the places they already thrive
        </h6>
        <ul className="tw-my-8 tw-list-none tw-list">
          <li
            className="font-helvetica tw-mt-2 tw-flex tw-gap-4 tw-text-[18px] lg:tw-text-[20px] tw-leading-7"
          >
            <img
              style={{ width: 14, height: 14, marginTop:4 }}
              src={
                "https://storage.googleapis.com/sukhiba//user_uploads/2023-05-31/1685541044XmvtJwEEIK__bullet.png"
              }
            />{" "}
            Set-up your store on WhatsApp
          </li>
          <li
            className="font-helvetica tw-mt-2 tw-flex tw-gap-4 tw-text-[18px] lg:tw-text-[20px] tw-leading-7"
          >
            <img
              style={{ width: 14, height: 14, marginTop:4 }}
              src={
                "https://storage.googleapis.com/sukhiba//user_uploads/2023-05-31/1685541044XmvtJwEEIK__bullet.png"
              }
            />{" "}
            See Analytics to measure success
          </li>
          <li
            className="font-helvetica tw-mt-2 tw-flex tw-gap-4 tw-text-[18px] lg:tw-text-[20px] tw-leading-7"
          >
            <img
              style={{ width: 14, height: 14, marginTop:4 }}
              src={
                "https://storage.googleapis.com/sukhiba//user_uploads/2023-05-31/1685541044XmvtJwEEIK__bullet.png"
              }
            />{" "}
            Run Campaigns for your customers
          </li>
        </ul>
        <div className="tw-text-center"><button className="tw-bg-yellow-500 tw-drop-shadow tw-rounded tw-px-8 tw-py-2 tw-text-md" onClick={() => setOnboarding(true)}>CREATE SHOP</button></div>
      </div> */}
      {/* Contact */}
      {/* <div
        id="contactus"
        className="tw-text-black tw-gap-20 tw-px-10 md:tw-px-20 lg:tw-px-32 tw-py-10 tw-flex max-md:tw-flex-col-reverse"
        style={{ background:{bgPage1} }}
      >
        <div className="tw-flex lg:tw-pt-10 tw-gap-2">
          <div>
            <img src={SukhibaLogo} />
            <div className="tw-flex tw-gap-10 tw-mt-12">
              <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px]">
                <img
                  src={
                    "https://storage.googleapis.com/sukhiba//user_uploads/2023-06-01/1685609208v4NME5Cg3Y__facebook__FontAwesome.png"
                  }
                  className="tw-h-5"
                  onClick={() => {
                    window.open("https://www.facebook.com/SUKHIBA")
                  }}
                />
              </div>
              <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px]">
                <img
                  src={
                    "https://storage.googleapis.com/sukhiba//user_uploads/2023-06-01/1685609376ufDCmzRjfz__twitter__FontAwesome.png"
                  }
                  className="tw-h-5"
                  onClick={() => {
                    window.open("https://twitter.com/sukhiba")
                  }}
                />
              </div>
              <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px]">
                <img
                  src={
                    "https://storage.googleapis.com/sukhiba//user_uploads/2023-06-01/1685609334joWYANTIR5__socialinstagram__simpleline.png"
                  }
                  className="tw-h-5"
                  onClick={() => {
                    window.open("https://www.instagram.com/sukhibaconnect")
                  }}
                />
              </div>
            </div>
            <div className=" tw-mt-8 tw-text-black">
                <div>&copy; Sukhiba Inc.</div>
              </div>
              <UserActionIcons className={"tw-mt-4 tw-items-start tw-grid tw-grid-cols-2 tw-text-black"}/>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-text-sm tw-text-black">
            <a href="#features" className=" tw-underline">
              Features
            </a>
            <a href="#ccommerce" className=" tw-underline">
              C-Commerce
            </a>
            <a href="#casestudy" className=" tw-underline">
              Case Study
            </a>
            <a href="/tnc/" className=" tw-underline">
              Terms & Conditions
            </a>
            <a href="/privacy_policy/" className=" tw-underline">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="tw-flex-1">
          <div className="font-inter tw-text-[7vw] md:tw-text-[5vw] lg:tw-text-[40px]">
            <span className="tw-font-bold tw-text-yellow-500">
              Interested
            </span>{" "}
            in working with us?
          </div>
          <div className="tw-my-10">
            <input
              id="form_name"
              onChange={(e) => {
                ctx.name = e.target.value;
              }}
              className="tw-outline-0 tw-border-b tw-border-gray-400 tw-bg-transparent tw-py-2 tw-w-full tw-mb-10"
              placeholder="Your Name"
            />
            <input
              id="form_email"
              onChange={(e) => {
                ctx.email = e.target.value;
              }}
              className="tw-outline-0 tw-border-b tw-border-gray-400 tw-bg-transparent tw-py-2 tw-w-full tw-mb-10"
              placeholder="Your Email"
            />
            <input
              id="form_number"
              onChange={(e) => {
                ctx.phone = e.target.value;
              }}
              className="tw-outline-0 tw-border-b tw-border-gray-400 tw-bg-transparent tw-py-2 tw-w-full"
              placeholder="Your WhatsApp Number"
            />
          </div>
          <button
            className="font-helvetica tw-mt-4 tw-border tw-border-gray-400 tw-rounded tw-w-full tw-py-3 tw-text-sm tw-tracking-widest"
            onClick={() => {
              scheduleDemoViaWhatsapp();
            }}
          >
            SCHEDULE A DEMO
          </button>
        </div>
      </div> */}
      {
        ctx.show_scroll_top
          ? <div className="tw-fixed tw-bottom-10 tw-right-10 tw-text-white tw-bg-black tw-bg-opacity-40 tw-rounded-full tw-w-8 tw-h-8 flex-box"
            onClick={() => document.body.scrollTop = 0}
          >
            <FontAwesomeIcon className="" icon={faChevronUp} />
          </div>
          : null
      }
      <OnboardingPopup is_create_shop={true} show={show_onboarding} onClose={() => setOnboarding(false)} zIndex={30}><OnboardingForm /></OnboardingPopup>
      <PartnerPopup show={show_partner_popup} setShow={setShowPartnerPopup} onFormSubmit={() => {
        setShowPartnerPopup(false)
        setShowSuccessPopup(true)
      }} />
      <SuccessPopUp show={show_success_popup} setShow={setShowSuccessPopup} />
    </div>
  );
}

export default LandingPage;
