import React, { useEffect, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { GenericException } from 'base/ui/errors';
import { Popup } from 'base/ui/popups';
import { useCurrentUser } from 'base/app';
import LoginForm from './LoginForm';

const OnboardingForm = () => {
    const user = useCurrentUser();
    const [form_data, setFormData] = useState({country_phone_code: '254'}); // shop_name, shop_code, email, phone_number
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState(null);
    const [is_success, setSuccess] = useState(null);
    const [is_loading, setLoading] = useState(false)
    const ctx = useRef({}).current;

    useEffect(() => {
        if (ctx.login_popup && user) {
            ctx.login_popup.close();
            createShop();
        }
    }, [user, ctx.login_popup])

    const createShop = () => {
        if (is_loading) return;
        setLoading(true);
        axios.post('/api/onboard/shop', form_data)
        .then((resp) => {
            if (resp.data.errors) {
                Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                return;
            }
            setSuccess(resp.data.registered)
        }).finally(() => setLoading(false))
    }

    const onSubmit = () => {
        if (!user?._id) {
            ctx.login_popup = Popup.slideUp(
                <div className='tw-p-4 tw-bg-white'>
                    <p className='tw-text-center tw-mt-4 tw-text-primary tw-border-b tw-pb-2'>Last step!&nbsp;&nbsp;Login to verify your number.</p>
                    <LoginForm />
                </div>
            );
            return
        }
        createShop();
    }

    const onFormDataChange = (key, error_key) => {
        return (evt) => {
            setFormData({...form_data, [key]: evt.target.value});
            error_key ? setErrors({...errors, [error_key]: false}) : setErrors(null);
        }
    }

    const isValidEmail = (email) => {
        if (!email) return;
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    }

    const disable_next1_btn = !form_data.shop_name || errors?.invalid_shop_name;
    const disable_next2_btn = !form_data.shop_description || errors?.invalid_shop_description;
    const disable_create_btn = !form_data.email_id || !isValidEmail(form_data.email_id);

    return (
        is_success === null
        ?   step === 1
            ?   <>
                    <div className='tw-text-md font-inter tw-border-b tw-pb-2'>Step-1: Enter your Shop Name</div>
                    <div className='md:tw-p-8 tw-text-black'>
                        <div className='tw-mt-8'>
                            {/* Shop Name */}
                            {/* <div className='tw-text-sm tw-mb-1 tw-mt-6'>Shop Name <span className='tw-text-red-500'>*</span></div> */}
                            <div className='tw-rounded tw-border tw-flex tw-px-4 tw-py-2 tw-w-full '>
                                <input type='text' className='tw-flex-1 tw-outline-0 tw-text-md' defaultValue={form_data.shop_name}
                                    placeholder='Shop Name' onChange={onFormDataChange('shop_name')}
                                />
                            </div>
                            {errors?.invalid_shop_name ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Please enter Shop Name</div> : null}
                            <button
                                className={`tw-relative flex-box tw-bg-yellow-500 tw-w-full tw-py-2 tw-rounded tw-text-sm tw-mt-8`}
                                onClick={() => disable_next1_btn ? setErrors({invalid_shop_name: true}) : setStep(2)}
                            >   
                                NEXT
                                <FontAwesomeIcon icon={faArrowRight} className='tw-absolute tw-right-4' />
                            </button>
                        </div>
                    </div>
                </>
            :   step === 2
                ?   <>
                        <button className='tw-text-gray-600 tw-text-xs tw-mb-8' onClick={() => setStep(1)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;Back</button>
                        <div className='tw-text-md font-inter tw-border-b tw-pb-2 w3-animate-right'>Step-2: Enter your Shop Details</div>
                        <div className='md:tw-p-8 tw-text-black'>
                            <div className='tw-mt-8'>
                                {/* Shop description */}
                                <div className='tw-text-sm tw-mb-1 tw-mt-6'>What are you selling? <span className='tw-text-red-500'>*</span></div>
                                <div className='tw-rounded tw-border tw-flex tw-px-4 tw-py-2 tw-w-full '>
                                    <textarea rows={3} className='tw-flex-1 tw-outline-0 tw-text-md' defaultValue={form_data.shop_description}
                                        placeholder='stationery, fmcg, groceries, etc.,' onChange={onFormDataChange('shop_description')}
                                    />
                                </div>
                                {errors?.invalid_shop_description ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Please enter Shop Description</div> : null}
                                {/* Shop code */}
                                <div className='tw-text-sm tw-mb-1 tw-mt-6'>Unique Shop Code</div>
                                <div className='tw-rounded tw-border tw-flex tw-w-full'>
                                    <span className='tw-text-xs tw-p-2 tw-bg-gray-200'>https://sukhiba.com/</span>
                                    <input type='text' className='tw-px-2 tw-min-w-0 tw-grow tw-outline-0 tw-text-md' defaultValue={form_data.shop_code}
                                        placeholder='' onChange={onFormDataChange('shop_code')}
                                    />
                                </div>
                                <button
                                    className={`tw-relative flex-box tw-bg-yellow-500 tw-w-full tw-py-2 tw-rounded tw-text-sm tw-mt-8`}
                                    onClick={() => disable_next2_btn ? setErrors({invalid_shop_description: true}) : setStep(3)}
                                >   
                                    NEXT
                                    <FontAwesomeIcon icon={faArrowRight} className='tw-absolute tw-right-4' />
                                </button>
                            </div>
                        </div>
                    </>
                :   <>
                        <button className='tw-text-gray-600 tw-text-xs tw-mb-8' onClick={() => setStep(2)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;Back</button>
                        <div className='tw-text-md font-inter tw-border-b tw-pb-2 w3-animate-right'>Step-3: Enter your Email</div>
                        <div className='md:tw-p-8 tw-text-black'>
                            <div className='tw-mt-8'>
                                <div className='tw-rounded tw-border tw-px-4 tw-py-2 tw-w-full tw-mt-6'>
                                    <input type='email' className='tw-min-w-0 tw-w-full tw-outline-0 tw-text-md tw-appearance-none' 
                                        placeholder='Eg., example@gmail.com' defaultValue={form_data.email}
                                        onChange={onFormDataChange('email_id', 'invalid_email')} 
                                    />
                                </div>
                                {errors?.invalid_email ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Please enter a valid Email</div> : null}
                                <button
                                    disabled={is_loading}
                                    className={`tw-relative flex-box tw-bg-yellow-500 tw-w-full tw-py-2 tw-rounded tw-text-sm tw-mt-8`}
                                    onClick={() => disable_create_btn ? setErrors({invalid_email: true}) : onSubmit()}
                                >   
                                    CREATE SHOP
                                    {is_loading ? <FontAwesomeIcon icon={faSpinner} className='tw-animate-spin tw-ml-4' /> : null}
                                </button>
                            </div>
                        </div>
                    </>
        :   is_success
            ?   <div className='tw-text-center tw-px-4'>
                    <video src="https://storage.googleapis.com/sukhiba/mobilefresh/icons/order_success.mp4"
                        autoPlay={true} loop={false} controls={false} 
                    />
                    <div className='tw-text-lg font-inter'>Your request has been placed!</div>
                    <div className='tw-text-sm tw-text-gray-500 tw-mt-6'>We will get back to you within 24 hrs</div>
                </div>
            :   <div className='tw-text-center tw-px-4'>
                    <FontAwesomeIcon icon={faCircleXmark} className='tw-text-red-600 tw-w-10 tw-h-10' />
                    <div className='tw-text-lg font-inter'>Sorry! Something went wrong.</div>
                    <div className='tw-text-sm tw-text-gray-500 tw-mt-6'>Please try again after some time.</div>
                </div>
        
    )
}

export default OnboardingForm