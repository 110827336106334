import { faBox, faCreditCard, faShop, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useBroadcastedState } from "base/utils/events";
import bgPage1 from "../../images/landing/page1_bg.svg"
import bgPage2 from "../../images/landing/page2_bg.svg"
import bgBoxes from "../../images/landing/boxes_bg.svg"

import ic_star from "../../images/ic_landing_star.svg"
import ic_scan from "../../images/ic_landing_scan.svg"
import ic_chart from "../../images/ic_landing_chart.svg"
import ic_users from "../../images/ic_landing_users.svg"

import ImgPage2 from "../../images/landing/industries/page2.webp"

import imgFeature1 from "../../images/landing/industries/feature_1.webp"
import imgFeature2 from "../../images/landing/industries/feature_2.webp"
import imgFeature3 from "../../images/landing/industries/feature_3.webp"
import imgFeature4 from "../../images/landing/industries/feature_4.webp"

import imgPage4_1 from "../../images/landing/industries/page4_1.webp"
import imgPage4_2 from "../../images/landing/industries/page4_2.webp"
import imgPage4_3 from "../../images/landing/industries/page4_3.webp"


const features = [
  {
    img: imgFeature1,
    title: "A new way to sell: ",
    description: "Recreate your sales route online and reimagine routine interactions",
  },
  {
    img: imgFeature2,
    title: "AI Catalog: ",
    description: "Send AI-powered answers to customer's asks on your catalog and increase average order value",
  },
  {
    img: imgFeature4,
    title: "Optimized Logistics: ",
    description: "Simplify your teams order lists, route maps, deliveries and more with logistics services",
  },
  {
    img: imgFeature3,
    title: "Buy-for-Customer: ",
    description: "Share virtual carts filled with the right products so retailers can checkout faster",
  },
];

const page4_features = [
  {
    img: imgPage4_1,
    title: "Beauty & Fashion",
    description: "Delight your customers from their first contact with your\nbrand to the next sale, all via WhatsApp",
    points: [
      {
        title: "Sukhiba Flow",
        desc: "Create custom conversation flows for your customers’\nspecific skincare or beauty needs."
      },
      {
        title: "Personalized Shopping",
        desc: "Build quizzes to discover individual needs and recommend\nthe best product fits to your customers. "
      },
      {
        title: "Loyalty and Rewards",
        desc: "Engage customers with special discount codes and a quick\nscan-to-join loyalty program."
      }
    ]
  },
  {
    img: imgPage4_2,
    title: "Healthcare",
    description: "Communicate to keep patients and providers\nup-to-date, securely",
    points: [
      {
        title: "Never miss an appointment",
        desc: "Automatically book appointments and add patients to your\ncalendar without the back and forth."
      },
      {
        title: "Send Reminders",
        desc: "Send reminders to patients with details of their\nappointments and timings to keep them updated."
      },
    ]
  }, {
    img: imgPage4_3,
    title: "Hotels and Travel",
    description: "Delight your customers from their first contact with your\nbrand to the next sale, all via WhatsApp",
    points: [
      {
        title: "Streamlined Booking",
        desc: "Give customers staying at your properties the flexibility to\nregister, book services like spas and more via WhatsApp."
      },
      {
        title: "Spontaneous Service",
        desc: "Service customers who have grievances with a robust\nticketing and ticket assignment system."
      },
      {
        title: "Local Attractions",
        desc: "Broadcast automated promotion recommendations from AI\non things to do in the area, services for travel bookings,\nconcert tickets, cultural attractions, and more."
      }
    ]
  }
];

const features_list = [
  "Healthcare",
  "Ecommerce",
  "Travel",
  "Industry",
  "Real Estate",
  "Hotels",
]


function IndustriesPage() {
  const [screen] = useBroadcastedState("tw_screen_size");

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none font-figtree">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
        className="tw-relative tw-px-8 tw-pt-8 tw-pb-14 tw-text-center tw-items-center">
        <div className="tw-text-landing-black !tw-font-semibold  ">
          <div className="tw-text-[30px] lg:tw-text-[38px] xl:tw-text-[48px] 2xl:tw-text-[56px] tw-leading-tight tw-tracking-wide">
            <span className="lg:tw-whitespace-pre">{`Whether you're an enterprise business, or a mom\nand pop store,`}</span>
            <span className="tw-text-landing-green md:tw-whitespace-pre"> {`we've got you covered.`}</span>
          </div>
        </div>
        <div className="md:tw-whitespace-pre lg:tw-text-[22px] tw-font-light tw-text-landing-black tw-mt-4">Tailor-made support: From Enterprise to Growing Businesses</div>
        <div className="tw-flex tw-justify-center tw-mt-12 tw-items-center tw-text-center tw-text-[20px]">
          <div
            className=" tw-w-max tw-rounded-full tw-bg-landing-green tw-px-8 tw-text-white tw-py-3.5 tw-cursor-pointer"
            onClick={() => {
              window.open("/contactus", "_self")
            }}>
            Schedule a demo
          </div>
        </div>
        <div className="tw-justify-center font-figtree tw-mt-16 md:tw-flex tw-gap-8 ">
          <div className="tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
              <FontAwesomeIcon icon={faShop} className="tw-w-5 tw-text-landing-green" />
            </div>
            <div className="tw-text-[24px] font-figtree-semibold tw-text-black tw-my-2">Storefront</div>
            <div className="tw-text-[18px] tw-font-light tw-text-[#666666]">{`Directly upload your product catalog in a CSV or sheet, and you're ready to go`}</div>
          </div>
          <div className="max-md:tw-my-8 tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
              <FontAwesomeIcon icon={faCreditCard} className="tw-w-5 tw-text-landing-green" />
            </div>
            <div className="tw-text-[24px] font-figtree-semibold tw-text-black tw-my-2">Payments</div>
            <div className="tw-text-[18px] tw-font-light tw-text-[#666666]">{`Accept payments fast to move from enquiries to orders`}</div>
          </div>
          <div className="tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
              <FontAwesomeIcon icon={faBox} className="tw-w-5 tw-text-landing-green" />
            </div>
            <div className="tw-text-[24px] font-figtree-semibold tw-text-black tw-my-2">Inventory</div>
            <div className="tw-text-[18px] tw-font-light tw-text-[#666666]">{`Stay in sync with your catalog with real-time stock updates`}</div>
          </div>
        </div>
      </div>
      <div className="tw-bg-[#093232] tw-py-4 tw-px-6 tw-gap-4 tw-flex w3-hide-scrollbar tw-overflow-x-auto lg:tw-justify-center">
        {
          features_list.map((feature, i) => {
            return <div key={i} className="max-lg:tw-mx-4 tw-flex tw-gap-2 tw-text-white tw-text-[18.5px] md:tw-text-[24.5px] tw-whitespace-nowrap">
              <img src={ic_star} className="tw-w-[22px]" /> {feature}
            </div>
          })
        }
      </div>
      {/* Page 2 */}
      <div
        style={{ backgroundImage: 'url(' + bgPage2 + ')', backgroundSize: 'cover' }}
        className="font-figtree lg:tw-pl-16 lg:tw-py-16 tw-bg-gray-bg "
      >
        <div
          className={`tw-flex tw-items-center tw-justify-center tw-text-landing-black tw-mb-10 max-lg:tw-flex-wrap `}
        >
          <div className="max-lg:tw-p-12">
            <h1 className="tw-text-[24px] md:tw-text-[40px] !tw-font-medium tw-text-black tw-mb-4">
              Grow your<span className="tw-text-landing-green"> Ecommerce Business</span>
            </h1>
            <div className="tw-mb-4 tw-flex tw-gap-4 tw-items-start">
              <img src={ic_users} className="max-md:tw-w-[16px] tw-mt-1" />
              <div className="lg:tw-whitespace-pre tw-text-[14px] md:tw-text-[22px]">{"Build relationships by engaging with customers\nwhere they are most comfortable having\nconversations"}</div>
            </div>
            <div className="tw-mb-4 tw-flex tw-gap-4 tw-items-start">
              <img src={ic_scan} className="max-md:tw-w-[16px] tw-mt-1" />
              <div className="lg:tw-whitespace-pre tw-text-[14px] md:tw-text-[22px]">{"Try a unique approach to loyalty programs\nwith a scan to signup flow"}</div>
            </div>
            <div className="tw-flex tw-gap-4 tw-items-start">
              <img src={ic_chart} className="max-md:tw-w-[16px] tw-mt-1" />
              <div className="lg:tw-whitespace-pre tw-text-[14px] md:tw-text-[22px]">{"Experience the joy of seeing your customers grow "}</div>
            </div>
          </div>
          <div className="max-lg:tw-mt-8">
            <img src={ImgPage2} className="" />
          </div>
        </div>
      </div>
      {/* Page 3 */}
      <div
        className="tw-p-8 lg:tw-p-16 tw-bg-[#052F2F] tw-text-white "
        style={{ backgroundImage: 'url(' + bgBoxes + ')', backgroundRepeat: 'repeat' }}>
        <div className="tw-text-center tw-font-semibold tw-leading-tight tw-tracking-wider">
          <div className="tw-text-[22px] md:tw-text-[40px] tw-text-landing-yellow">Get Distribution Savvy, </div>
          <div className="tw-text-[22px] md:tw-text-[40px] tw-text-white ">
            {`Spread the Wealth`}
          </div>
          <div className="lg:tw-flex max-lg:tw-grid tw-gap-8 tw-justify-center tw-mt-12 ">
            <div className="tw-relative tw-bg-white tw-rounded-2xl md:tw-max-w-[575px] md:tw-h-[424px]">
              <div className="tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                <span className="tw-text-landing-green">{features[0].title}</span>{features[0].description}
              </div>
              <img src={features[0].img} className=" tw-w-max md:tw-absolute tw-bottom-0 tw--mt-20 " />
            </div>
            <div className="tw-relative tw-bg-white tw-rounded-2xl md:tw-max-w-[575px] md:tw-h-[424px]">
              <div className="tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                <span className="tw-text-landing-green">{features[1].title}</span>{features[1].description}
              </div>
              <img src={features[1].img} className=" tw-w-max md:tw-absolute tw-bottom-0 tw--mt-20 " />
            </div>
          </div>
          <div className="lg:tw-flex max-lg:tw-grid tw-gap-8 tw-justify-center tw-mt-8 ">
            <div className="tw-relative tw-bg-white tw-rounded-2xl md:tw-max-w-[575px] md:tw-h-[424px]">
              <div className="tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                <span className="tw-text-landing-green">{features[2].title}</span>{features[2].description}
              </div>
              <img src={features[2].img} className=" tw-w-max md:tw-absolute tw-bottom-0 tw--mt-20 " />
            </div>
            <div className="tw-relative tw-bg-white tw-rounded-2xl md:tw-max-w-[575px] md:tw-h-[424px]">
              <div className="tw-text-[18px] md:tw-text-[24px] !tw-font-medium tw-text-[#002632] tw-m-8 tw-text-start">
                <span className="tw-text-landing-green">{features[3].title}</span>{features[3].description}
              </div>
              <img src={features[3].img} className=" tw-w-max md:tw-absolute tw-bottom-0 tw--mt-20 " />
            </div>
          </div>
        </div>
      </div>
      {/* Page 4 */}
      <div
        className="font-figtree tw-p-8 md:tw-pl-8 md:tw-py-16 tw-bg-[#052F2F] tw-text-white "
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}>
        {
          page4_features.map((feature, i) => {
            return <div key={i}
              className={`lg:tw-flex max-lg:tw-grid tw-items-center tw-text-landing-black tw-justify-center md:tw-gap-16 xl:tw-gap-32 tw-mb-10 max-md:tw-flex-wrap ${i == 1 ? "tw-flex-row-reverse" : ""} max-md:tw-flex-col-reverse`}>
              <div className="">
                <h1 className="tw-text-[24px] md:tw-text-[40px] !tw-font-medium tw-mb-2">
                  {feature.title}
                </h1>
                <p className="tw-text-[16px] md:tw-text-[22px] md:tw-whitespace-pre !tw-font-light">
                  {feature.description}
                </p>
                {
                  feature.points.map((point, i) => {
                    return <div key={i} className="tw--ml-2 tw-rounded-2xl tw-bg-white tw-border tw-border-[#E1E1E1] tw-py-4 tw-px-6 tw-mt-4" >
                      <div className="tw-text-[20px] md:tw-text-[24px] !tw-font-medium tw-mb-1">
                        {point.title}
                      </div>
                      <p className="tw-text-[15px] md:tw-text-[18px] !tw-font-light md:tw-whitespace-pre ">
                        {point.desc}
                      </p>
                    </div>
                  })
                }
              </div>
              <div className="">
                <img src={feature.img} className="max-md:tw-mt-6" />
              </div>
            </div>

          })
        }
      </div>
    </div>
  );
}

export default IndustriesPage;
