import React, { useState } from "react";
import { LogoScroller } from "../../logoScroller";
import { useBroadcastedState } from "base/utils/events";

import ClientLogo1 from "../../images/landing/client_logos/ic_client_1.png"
import ClientLogo2 from "../../images/landing/client_logos/ic_client_2.png"
import ClientLogo3 from "../../images/landing/client_logos/ic_client_3.png"
import ClientLogo4 from "../../images/landing/client_logos/ic_client_4.png"
import ClientLogo5 from "../../images/landing/client_logos/ic_client_5.png"
import ClientLogo6 from "../../images/landing/client_logos/ic_client_6.png"
import ClientLogo7 from "../../images/landing/client_logos/ic_client_7.png"
import ClientLogo8 from "../../images/landing/client_logos/ic_client_8.png"
import ClientLogo9 from "../../images/landing/client_logos/ic_client_9.png"
import ClientLogo10 from "../../images/landing/client_logos/ic_client_10.png"
import ClientLogo11 from "../../images/landing/client_logos/ic_client_11.png"
import ClientLogo12 from "../../images/landing/client_logos/ic_client_12.png"
import ClientLogo13 from "../../images/landing/client_logos/ic_client_13.png"
import ClientLogo14 from "../../images/landing/client_logos/ic_client_14.png"


import bgPage1 from "../../images/landing/page1_bg.svg"
import lottieConfetti from "../../images/landing/bookdemo/lottie_confetti.json"


import logo_godrej from "../../images/landing/home/logo_godrej.png"
import logo_uncover from "../../images/landing/home/logo_uncover.png"
import Lottie from "lottie-react";

const client_logos = [
  ClientLogo1, ClientLogo2, ClientLogo3, ClientLogo4, ClientLogo5, ClientLogo6, ClientLogo7,
  ClientLogo8, ClientLogo9, ClientLogo10, ClientLogo11, ClientLogo12, ClientLogo13, ClientLogo14
].map((image, i) => ({
  id: i,
  image
}));



const brandsData = [
  {
    title: "Tapping into an already existing consumer behavior with an elevated user journey is genius.",
    desc: "We are excited to have partnered with Sukhiba to launch WhatsApp " +
      "commerce in Kenya. Our customers can receive broadcast newsletters, " +
      "chat to an expert, browse catalogs, add to cart, and pay all within " +
      "WhatsApp. In such a short space of time, our WhatsApp revenue is 3x " +
      "our email revenue and a third of our total e-commerce revenue. We are " +
      "working to replicate this model in Nigeria.",
    name: "Horace Cole",
    designation: "COO of Uncover",
    image: logo_uncover,
  },
  {
    title: "We are much more efficient.",
    desc: "At a glance we can instantly see how we are performing, " +
      "in a very easy and intuitive way. The live chats and loyalty program " +
      "have been a game changer and led us to tripling our sales in just 3 months." +
      "Conversations can be turned into orders in a very personalized manner." +
      "With the analytics dashboard we can see what needs to be done and when.",
    name: "Joyce Watitu",
    designation: "Brand Manager",
    image: logo_godrej,
  },
];


function HomePageTestimonials() {

  const selected_tab = 0

  return <div
    style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
    className="tw-p-6 font-figtree tw-text-landing-black tw-bg-[#133A3A] tw-grid tw-justify-items-center">
    <div className="tw-text-center">
      <h1 className="tw-text-[36px] max-sm:tw-text-[24px] md:tw-text-[48px] tw-font-semibold md:tw-whitespace-pre  tw-text-landing-black">{"What a business like yours\nthinks about Sukhiba"}</h1>
    </div>
    <div
      className={`lg:tw-flex max-lg:tw-grid tw-grid-cols-1 tw-items-center lg:tw-w-[90%] xl:tw-w-[70%] tw-rounded-xl lg:tw-justify-center tw-justify-items-center`}
      >
      <img src={brandsData[selected_tab].image} className=" tw-rounded-l-xl " />
      <div className="tw-p-6 lg:tw-p-12 tw-rounded-r-xl lg:tw-w-[70%] xl:tw-w-[60%] max-sm:tw-h-[426px] max-lg:tw-h-[306px] lg:tw-h-[536px] xl:tw-h-[586px] 2xl:tw-h-[566px]">
        <div className="tw-text-[22px] max-sm:tw-text-[18px] md:tw-text-[28px] tw-font-semibold tw-text-[#202020] tw-my-4 tw-leading-tight">
          {brandsData[selected_tab].title}
        </div>
        {brandsData[selected_tab].desc ? (
          <div className="tw-text-[#202020] tw-text-[14px] lg:tw-text-[16px] xl:tw-text-[18px] 2xl:tw-text-[22px] tw-font-light tw-mb-2">
            {brandsData[selected_tab].desc}
          </div>
        ) : null}

        {brandsData[selected_tab].name ? (
          <div className="tw-text-[#202020] tw-mt-8 tw-text-[18px] tw-font-semibold">
            {brandsData[selected_tab].name}
          </div>
        ) : null}
        {brandsData[selected_tab].designation ? (
          <div className="tw-text-[#808080] tw-text-[14px] tw-font-medium ">
            {brandsData[selected_tab].designation}
          </div>
        ) : null}
      </div>
      
    </div>
    <div className="tw-justify-center tw-text-center tw-text-md tw-mb-8">
      <button className="tw-rounded-full tw-bg-landing-green tw-text-white tw-px-9 tw-py-3.5"
        onClick={() => { window.open("https://storage.googleapis.com/sukhiba/mobilefresh/uploads/2024-07-26/UncoverSukhiba%20CaseStudy%202024.pdf") }}>
        {"Download full length case study"}
      </button>
    </div>
  </div>
}


function ThankyouPage() {
  const [screen] = useBroadcastedState("tw_screen_size");
  const [show_confetti, setShowConfetti] = useState(true) 

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none font-figtree">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
        className="tw-relative tw-px-8 tw-pt-20 tw-pb-14 tw-text-center tw-items-center">
        <div className="tw-text-landing-black !tw-font-semibold  ">
          <div className="tw-text-[30px] lg:tw-text-[38px] xl:tw-text-[48px] 2xl:tw-text-[56px] tw-leading-tight tw-tracking-wide md:tw-mt-8">
            <span className="lg:tw-whitespace-pre tw-text-landing-green">{`Your demo is booked.`}</span>
            <p className="tw-text-black md:tw-whitespace-pre"> {`We’ll talk supercharging your sales soon!`}</p>
          </div>
        </div>
        {/* <div className="md:tw-whitespace-pre lg:tw-text-[22px] tw-font-light tw-text-landing-black tw-mt-8">{"Invite details will be in your inbox,\nor add them directly to your calendar "}
          <span className="tw-cursor-pointer" onClick={() => {
            window.open("https://meetings-eu1.hubspot.com/meetings/athiong-o?uuid=71064d29-7625-4e65-ad81-334815038fa4")
          }}>here.</span></div> */}
        <div className="md:tw-mt-44 tw-mt-16 tw-text-[22px] md:tw-text-[26px]  tw-text-landing-green md:tw-whitespace-pre">
          {"Join 85+ Shopify and WooCommerce stores "}
          <span className="tw-text-black">already connected with Sukhiba.</span>
        </div>
      </div>
      {
        show_confetti
          ? <div className="tw-flex tw-justify-center">
            <Lottie animationData={lottieConfetti} loop={false} onComplete={() => setShowConfetti(false)} className="tw-absolute tw-top-20" />
          </div>
          : null
      }

      {/* client logos */}
      <div className="tw-bg-[#093232] tw-p-2">
        <LogoScroller images={client_logos} speed={15000} />
      </div>
      <HomePageTestimonials />
      



    </div>
  );
}

export default ThankyouPage;
