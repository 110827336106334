import React, { useRef, useState } from 'react'
import ResponsivePopup from '../../components/ui/ResponsivePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { Popup } from 'base/ui/popups';
import { FormErrorText } from '../../components/ui/FormErrorText';
import { LoadingOverlay } from 'base/ui/status';

const PartnerPopup = ({ show, setShow, onFormSubmit }) => {
    const ctx = useRef({}).current;
    const [show_error, setShowError] = useState(null);
    const [is_loading, setIsLoading] = useState(false);


    const updateField = (key) => {
        return (evt) => {
            ctx[key] = evt.target.value
            setShowError(null)
        }
    }

    function submitForm() {

        if (!ctx.company_name?.length) {
            setShowError({ "company_name": true })
            return
        }
        if (!ctx.industry?.length) {
            setShowError({ "industry": true })
            return
        }
        if (!ctx.company_email?.length) {
            setShowError({ "company_email": true })
            return
        }
        if (!ctx.interested_in?.length) {
            setShowError({ "interested_in": true })
            return
        }
        const payload = {
            form_name: "partnership",
            fields: {
                company_name: ctx.company_name,
                industry: ctx.industry,
                email: ctx.company_email,
                interested_in: ctx.interested_in,
                notes: ctx.notes,
            }
        }
        setIsLoading(true)
        axios.post("/company/contact", payload)
            .then(
                (resp) => {
                    onFormSubmit(true)
                }
            ).finally(() => setIsLoading(false));
    }

    function PartnerWithUsTitle() {
        return (
            <div className="font-figtree tw-w-full tw-flex tw-justify-between tw-pl-4 md:tw-pl-8">
                <p className='tw-py-6 tw-text-[20px] md:tw-text-[28px] tw-font-medium tw-text-landing-black tw-tracking-wide'>Sign Up for our Partner Referral Program</p>
                <FontAwesomeIcon icon={faX} width={24} height={24} className='tw-p-4 tw-cursor-pointer tw-ml-auto tw-font-light'
                    onClick={() => setShow(false)}
                />
            </div>
        )
    }

    return (
        <ResponsivePopup show={show} onClose={() => setShow(false)} title={'Sign Up for our Partner Referral Program'}
            titleRenderer={PartnerWithUsTitle}
        >
            {
                is_loading ? <LoadingOverlay /> : null
            }
            <div className='font-figtree tw-grid tw-gap-x-6 md:tw-grid-cols-2 tw-px-4 md:tw-px-8 tw-pb-4 md:tw-pb-8'>
                <div className='tw-mb-6'>
                    <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Company Name</p>
                    <input className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Company Name'
                        onChange={updateField("company_name")} />
                    {show_error?.company_name ? <FormErrorText msg={"Please enter company name"} /> : null}
                </div>
                <div className='tw-mb-6'>
                    <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Industry</p>
                    <input className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Industry'
                        onChange={updateField("industry")} />
                    {show_error?.industry ? <FormErrorText msg={"Please enter industry"} /> : null}
                </div>
                <div className='tw-mb-6'>
                    <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Email</p>
                    <input className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Company Email'
                        onChange={updateField("company_email")} />
                    {show_error?.company_email ? <FormErrorText msg={"Please enter company email"} /> : null}
                </div>
                <div className='tw-mb-6'>
                    <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Interested in</p>
                    <input className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Interested in'
                        onChange={updateField("interested_in")} />
                    {show_error?.interested_in ? <FormErrorText msg={"Please enter interested in"} /> : null}
                </div>
                <div className='tw-mb-6 md:tw-col-span-2'>
                    <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1'>Note</p>
                    <textarea rows={3} className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Any remarks'
                        onChange={updateField("notes")} />
                </div>
                <button className='md:tw-col-span-2 tw-w-full tw-rounded-full tw-bg-landing-green tw-px-6 md:tw-px-12 tw-text-white tw-py-3.5 tw-tracking-wide'
                    onClick={submitForm}>Submit</button>
            </div>
        </ResponsivePopup>
    )
}

export default PartnerPopup