import React, { useEffect, useRef, useState } from "react";
import { LogoScroller } from "../../logoScroller";
import { useBroadcastedState } from "base/utils/events";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SukhibaLogo from "../../images/ic_sukhiba_logo_light.svg"
import ic_check_green from "../../images/landing/ic_check_green.png"
import AILogo from "../../images/landing/bookdemo/ic_ai.png"
import AlertLogo from "../../images/landing/bookdemo/ic_alerts.png"
import StoreLogo from "../../images/landing/bookdemo/ic_store.png"
import Uncover from "../../images/landing/bookdemo/uncover.webp"

import ClientLogo1 from "../../images/landing/client_logos/ic_client_1.png"
import ClientLogo2 from "../../images/landing/client_logos/ic_client_2.png"
import ClientLogo3 from "../../images/landing/client_logos/ic_client_3.png"
import ClientLogo4 from "../../images/landing/client_logos/ic_client_4.png"
import ClientLogo5 from "../../images/landing/client_logos/ic_client_5.png"
import ClientLogo6 from "../../images/landing/client_logos/ic_client_6.png"
import ClientLogo7 from "../../images/landing/client_logos/ic_client_7.png"
import ClientLogo8 from "../../images/landing/client_logos/ic_client_8.png"
import ClientLogo9 from "../../images/landing/client_logos/ic_client_9.png"
import ClientLogo10 from "../../images/landing/client_logos/ic_client_10.png"
import ClientLogo11 from "../../images/landing/client_logos/ic_client_11.png"
import ClientLogo12 from "../../images/landing/client_logos/ic_client_12.png"
import ClientLogo13 from "../../images/landing/client_logos/ic_client_13.png"
import ClientLogo14 from "../../images/landing/client_logos/ic_client_14.png"


import bgPage1 from "../../images/landing/page1_bg.svg"
import bgPage2 from "../../images/landing/page2_bg.svg"
import bgBoxes from "../../images/landing/boxes_bg.svg"
import ImgHome from "../../images/landing/bookdemo/group_ppl.webp"
import homeBack from "../../images/landing/bookdemo/home_back.webp"
import homeBackMobile from "../../images/landing/bookdemo/home_back_mobile.webp"
import axios from "axios";
import { FormErrorText } from "../../components/ui/FormErrorText";
import { LoadingOverlay } from "base/ui/status";
import { PHONE_NUMBER_CODES } from "../../constants";


const client_logos = [
  ClientLogo1, ClientLogo2, ClientLogo3, ClientLogo4, ClientLogo5, ClientLogo6, ClientLogo7,
  ClientLogo8, ClientLogo9, ClientLogo10, ClientLogo11, ClientLogo12, ClientLogo13, ClientLogo14
].map((image, i) => ({
  id: i,
  image
}));



function Page2() {

  return <div
    id="features"
    style={{ backgroundImage: 'url(' + bgPage2 + ')', backgroundSize: 'cover' }}
    className=" font-figtree tw-text-landing-black tw-grid tw-justify-center tw-p-8 lg:tw-p-16">
    <div className="tw-text-center  tw-font-medium">
      <h1 className="tw-text-[18px] md:tw-text-[28px] tw-text-landing-green">Why choose Sukhiba</h1>
      <div className="tw-text-[24px] md:tw-text-[40px] md:tw-whitespace-pre tw-flex-1 tw-my-2 tw-leading-tight">
        Grow your sales without growing your team
      </div>
    </div>

    <div className="tw-justify-center font-figtree tw-mt-16 md:tw-flex tw-gap-8 ">
      <div className="tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
        <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
          <img src={StoreLogo} className="tw-w-5 tw-text-landing-green" />
        </div>
        <div className="tw-text-[24px] font-figtree-semibold tw-text-black tw-my-2">Create a WhatsApp online shop in minutes</div>
        <div className="tw-text-[16px] tw-font-light tw-text-[#666666]">{`Reach more customers, more efficiently with targeted broadcast messages`}</div>
      </div>
      <div className="max-md:tw-my-8 tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
        <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
          <img src={AlertLogo} className="tw-w-5 tw-text-landing-green" />
        </div>
        <div className="tw-text-[24px] font-figtree-semibold tw-text-black tw-my-2">3x sales revenue with broadcast messages</div>
        <div className="tw-text-[16px] tw-font-light tw-text-[#666666]">{`Upload your product catalog OR connect to your your Shopify or WooCommerce store`}</div>
      </div>
      <div className="tw-grid tw-justify-items-center tw-text-center tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-6 md:tw-w-[302px]">
        <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] ">
          <img src={AILogo} className="tw-w-5 tw-text-landing-green" />
        </div>
        <div className="tw-text-[24px] font-figtree-semibold tw-text-black tw-my-2">Automate your customer support</div>
        <div className="tw-text-[16px] tw-font-light tw-text-[#666666]">{`Create custom AI flows that handle customer inquiries 24/7\n`}</div>
      </div>
    </div>
  </div>
}

function Page3() {
  return <div
    className="font-figtree tw-p-8 md:tw-py-16 tw-bg-[#052F2F] tw-text-white "
    style={{ backgroundImage: 'url(' + bgBoxes + ')', backgroundRepeat: 'repeat' }}>
    <div
      className={`md:tw-flex tw-items-center tw-justify-center tw-gap-8 xl:tw-gap-16 tw-mb-10 max-lg:tw-flex-wrap `}>
      <div className="">
        <h1 className="md:tw-whitespace-pre tw-text-[24px] md:tw-text-[40px] lg:tw-text-[36px] xl:tw-text-[40px] font-figtree-semibold !tw-font-medium	tw-font tw-mb-2">
          {"What Uncover achieved\nwith Sukhiba"}
        </h1>

        <div className="tw-text-[16px] md:tw-text-[20px] md:tw-ml-2 tw-mt-4 tw-text-white">
          <div className="tw-mb-4 tw-flex tw-gap-4 ">
            <div className="tw-w-2 tw-h-2 tw-p-1 tw-bg-[#FFB800] tw-rounded-full tw-mt-2.5" />
            <div><span className="tw-text-[#FFB800]">{"3x WhatsApp revenue\n"}</span>
              <p className="md:tw-whitespace-pre ">{"Used the Broadcast feature to promote new\nreleases and special offers"}</p></div>
          </div>
          <div className="tw-mb-4 tw-flex tw-gap-4 ">
            <div className="tw-w-2 tw-h-2 tw-p-1 tw-bg-[#FFB800] tw-rounded-full tw-mt-2.5" />
            <div><span className="tw-text-[#FFB800]">{"Increased customer engagement & loyalty\n"}</span>
              <p className="md:tw-whitespace-pre ">{"Offered interactive product education, quizzes\n& recommendations on WhatsApp"}</p></div>
          </div>
          <div className="tw-mb-4 tw-flex tw-gap-4 ">
            <div className="tw-w-2 tw-h-2 tw-p-1 tw-bg-[#FFB800] tw-rounded-full tw-mt-2.5" />
            <div><span className="tw-text-[#FFB800]">{"Reducing abandoned carts and drop-off rates\n"}</span>
              <p className="md:tw-whitespace-pre ">{"Allowed customers to browse catalog, ask\nquestions, place orders and pay directly in the chat"}</p></div>
          </div>
        </div>
        <div className="md:tw-w-max tw-rounded-xl tw-bg-[#133A3A] tw-p-6 tw-mt-6" >

          <p className="tw-text-[13px] md:tw-text-[16px] lg:tw-text-[14px] xl:tw-text-[16px] tw-text-white/[0.62] !tw-font-light tw-mb-2 md:tw-whitespace-pre ">
            {`“Tapping into an already existing consumer\nbehavior with an elevated user journey is genius.”`}
          </p>

          <p className="tw-text-[12px] md:tw-text-[14px] tw-text-[#688181] md:tw-whitespace-pre tw-italic  ">
            {" – Jade Oyateru, COO "}
          </p>
        </div>
      </div>
      {/* <div className="md:tw-w-[44%]"> */}
      <div className=" max-lg:tw-mt-8 max-lg:tw-flex max-lg:tw-justify-center">
        <LazyLoadImage src={Uncover} className="max-lg:tw-mt-6 tw-w-[85%]" />
      </div>
    </div>
  </div>
}

function BookADemo() {
  const ctx = useRef({}).current;
  const [show_error, setShowError] = useState(null);
  const [is_loading, setIsLoading] = useState(false);
  const [country_phone_code, setCountryPhoneCode] = useState();

  const updateField = (key) => {
    return (evt) => {
      ctx[key] = evt.target.value
      setShowError(null)
    }
  }

  useEffect(() => {
    axios.get("/user/location").then(
      (resp) => {
        if (resp.data) {
          setCountryPhoneCode(resp.data.phone_code)
        }
      }
    )
  }, [])

  useEffect(() => {
    //Loading fonts
    import('../../css/landing.css')
}, []);

  function submitForm() {
    if (!ctx.firstname?.length) {
      setShowError({ "name": true })
      return
    }
    if (!ctx.email?.length) {
      setShowError({ "email": true })
      return
    }
    if (!ctx.company?.length) {
      setShowError({ "company": true })
      return
    }

    const payload = {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ctx.email
        },
        {
          "objectTypeId": "0-1",
          "name": "firstname",
          "value": ctx.firstname
        },        
        {
          "objectTypeId": "0-1",
          "name": "company",
          "value": ctx.company
        },
        
      ],
      "context": {
        "pageUri": window.location.href,
        "pageName": "BookDemo"
      }
    }

    if (ctx.website) {
      payload.fields.push(
        {
          "objectTypeId": "0-2",
          "name": "website",
          "value": ctx.website
        }
      )
    }
    if (ctx.mobilephone) {
      payload.fields.push(
        {
          "objectTypeId": "0-1",
          "name": "mobilephone",
          "value": `${country_phone_code}${ctx.mobilephone}`
        },
      )
    }
    setIsLoading(true)
    
    const form_url = "https://api.hsforms.com/submissions/v3/integration/submit/145005588/cf72cb39-37d1-4d4f-b2bd-34971fdccb03"
    axios.post(form_url, payload, {
      headers: {
        'Authorizaition': 'Bearer pat-eu1-90657ca5-66fb-42eb-81ee-7e69714a86ac',
        'Content-Type': 'application/json'
      }
    })
      .then(
        (resp) => {
          window.open("thankyou", "_self")
          document.getElementById("input_email").value = ""
          document.getElementById("input_phone").value = ""
          document.getElementById("input_name").value = ""
          document.getElementById("input_company").value = ""
          document.getElementById("input_website").value = ""
        }
      ).finally(() => setIsLoading(false));
  }
  return <div className='tw-bg-white tw-rounded-xl tw-p-6 '>
    {
      is_loading ? <LoadingOverlay /> : null
    }
    <p className='tw-text-[20px] md:tw-text-[28px] tw-font-medium'>Book a demo with us today</p>
    <div className='tw-my-4'>
      <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1 tw-text-[#202223]'>Name*</p>
      <input id='input_name' className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Your Name'
        onChange={updateField("firstname")} />
      {show_error?.name ? <FormErrorText msg={"Please enter your name"} /> : null}
    </div>
    <div className='tw-mb-4'>
      <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1 tw-text-[#202223]'>Email*</p>
      <input id='input_email' className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Email'
        onChange={updateField("email")} />
      {show_error?.email ? <FormErrorText msg={"Please enter valid email"} /> : null}
    </div>
    <div className='tw-mb-4'>
      <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1 tw-text-[#202223]'>Phone</p>
      <div className="tw-flex tw-border tw-rounded tw-text-sm tw-p-1 tw-w-full">
      <select className="tw-outline-none tw-text-md tw-p-2 tw-mr-1"
        onChange={(e) => setCountryPhoneCode(e.target.value)}
        value={country_phone_code}>
        {PHONE_NUMBER_CODES.map(code => (
          <option key={code} value={code}>+{code}</option>
        ))}
      </select>
      <input id='input_phone' className='tw-outline-none tw-text-md tw-p-2 tw-mr-1 tw-w-full' placeholder='Enter Phone Number'
        onChange={updateField("mobilephone")} />
      </div>
      {show_error?.phone ? <FormErrorText msg={"Please enter valid phone number"} /> : null}
    </div>
    <div className="tw-grid tw-grid-cols-2 tw-gap-4">

      <div className='tw-mb-4'>
        <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1 tw-text-[#202223]'>Company Name*</p>
        <input id='input_company' className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' placeholder='Enter Company Name'
          onChange={updateField("company")} />
        {show_error?.company ? <FormErrorText msg={"Please enter company name"} /> : null}
      </div>
      <div className='tw-mb-4'>
        <p className='tw-text-[11px] md:tw-text-[14px] tw-mb-1 tw-text-[#202223]'>Company Website</p>
        <input id='input_website' className='tw-border tw-px-4 tw-rounded tw-text-sm tw-px-4 tw-py-2 tw-w-full' 
          placeholder='Enter Company Website'
          onChange={updateField("website")} />
        {show_error?.website ? <FormErrorText msg={"Please enter company website"} /> : null}
      </div>
    </div>
    <button className='tw-w-full tw-rounded-full tw-mt-4 tw-bg-landing-green tw-px-6 md:tw-px-12 tw-text-white tw-py-3.5 tw-tracking-wide'
      onClick={submitForm}>Submit</button>
  </div>
}

function BookDemo() {
  const [screen] = useBroadcastedState("tw_screen_size");

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none font-figtree">
      {/* Page 1 */}
      <div
        id="home"
        style={{ backgroundImage: 'url(' + (screen['max-md'] ? homeBackMobile : homeBack) + ')', backgroundSize: 'cover' }}
        className="tw-relative tw-px-6 tw-py-6">
        <div className="md:tw-ml-12 tw-my-4" >
          <img src={SukhibaLogo} />
        </div>
        <div className="tw-py-6 md:tw-flex md:tw-justify-center max-lg:tw-gap-8 lg:tw-gap-16">
          <div className="tw-text-white tw-font-semibold">
            <div className="tw-text-[36px] lg:tw-text-[48px] xl:tw-text-[60px] tw-leading-tight">
              <p className="md:tw-whitespace-pre">{"Turn your WhatsApp\nchats into sales"}</p>
            </div>
            <div className="tw-mt-4">
              <div className="tw-flex tw-font-light md:tw-items-center tw-gap-2">
                <img src={ic_check_green} className="tw-w-4 tw-h-4 max-md:tw-mt-0.5" /> List, chat, sell and manage deliveries all in one chat
              </div>
              <div className="tw-flex tw-font-light md:tw-items-center tw-gap-2 tw-mt-4">
                <img src={ic_check_green} className="tw-w-4 tw-h-4 max-md:tw-mt-0.5" /> Broadcast targeted promotions & special offers
              </div>
              <div className="tw-flex tw-font-light md:tw-items-center tw-gap-2 tw-mt-4">
                <img src={ic_check_green} className="tw-w-4 tw-h-4 max-md:tw-mt-0.5" /> Automate customer support with our AI Assistant
              </div>
            </div>
            {
              screen.md
                ?
                <div className="tw-mt-36 tw-text-[26px] tw-text-[#FFB800] md:tw-whitespace-pre">
                  {"Join 85+ Shopify and WooCommerce stores\n"}
                  <span className="tw-text-white">already connected with Sukhiba.</span>
                </div>
                : null
            }

          </div>

          <div className="max-md:tw-mt-12 md:tw-w-[40%]">
            <BookADemo />
          </div>
          {
            !screen.md
              ?
              <div className="tw-mt-8 tw-text-[20px] tw-text-center tw-font-medium tw-text-[#FFB800]">
                {"Join 85+ Shopify and WooCommerce stores\n"}
                <span className="tw-text-white">already connected with Sukhiba.</span>
              </div>
              : null
          }
        </div>

      </div>
      {/* client logos */}
      <div className="tw-bg-[#093232] tw-p-2">
        <LogoScroller images={client_logos} speed={15000} />
      </div>
      <Page2 />
      <Page3 />
      <div className="tw-bg-white tw-px-6 md:tw-py-16 max-md:tw-py-8">
        <div className="md:tw-flex max-md:tw-grid max-md:tw-mx-12 tw-justify-center tw-gap-8 md:tw-gap-4 lg:tw-gap-8 tw-items-center max-md:tw-justify-between">
          <div className="tw-border tw-border-0.5 tw-p-4 tw-rounded-xl">
            <div className="tw-text-[24px] md:tw-text-[22px] lg:tw-text-[28px] tw-flex tw-items-center">
              95%
            </div>
            <div className="tw-text-[16px] md:tw-text-[15px] lg:tw-text-[18px] tw-font-light tw-whitespace-pre">
              {"of WhatsApp messages are\n"}
              <span className="tw-text-[#15966E]">{"opened within 3 minutes\n "}</span></div>
          </div>
          <div className="tw-border tw-border-0.5 tw-p-4 tw-rounded-xl">
            <div className="tw-text-[24px] md:tw-text-[22px] lg:tw-text-[28px] tw-flex tw-items-center">
              2.8x
            </div>
            <div className="tw-text-[16px] md:tw-text-[15px] lg:tw-text-[18px] tw-font-light md:tw-whitespace-pre">
              {"customers who can chat\nand get help on WhatsApp\nare "}
              <span className="tw-text-[#15966E]">{"2.8x more likely to buy"}</span></div>
          </div>

          <div className="tw-border tw-border-0.5 tw-p-4 tw-rounded-xl">
            <div className="tw-text-[24px] md:tw-text-[22px] lg:tw-text-[28px] tw-flex tw-items-center">
              45%
            </div>
            <div className="tw-text-[16px] md:tw-text-[15px] lg:tw-text-[18px] tw-font-light md:tw-whitespace-pre">
              {"of customers are "}
              <span className="tw-text-[#15966E]">{"more likely\nto order on WhatsApp "}</span>
              {"than\non regular websites or apps"}</div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: 'url(' + bgPage1 + ')' }}
        className="tw-relative tw-px-8 tw-py-10 lg:tw-flex tw-justify-center lg:tw-gap-8 xl:tw-gap-16 tw-items-center">
        <div className="tw-text-landing-black font-figtree-semibold  lg:tw-ml-8">
          <div className="tw-text-[36px] md:tw-text-[60px] lg:tw-text-[52px] tw-leading-tight tw-tracking-wide">
            <p className="md:tw-whitespace-pre">{"Supercharge your\nsales today"}</p>
          </div>
          <div className="tw-mt-8 tw-flex tw-gap-4 tw-items-center tw-text-center tw-text-[16px]">
            <button onClick={() => {
              document.body.scrollTop = 0;
            }}
              className="max-md:tw-flex-1 font-figtree tw-rounded-full tw-bg-landing-green tw-px-6 md:tw-px-12 tw-text-white tw-py-3.5">
              Schedule a Demo
            </button>
          </div>
        </div>
        <div className="lg:tw-max-w-[46%] xl:tw-max-w-[40%] max-lg:tw-my-12 max-lg:tw-flex max-lg:tw-justify-center">
          <LazyLoadImage src={ImgHome} className="" />
        </div>
      </div>
    </div>
  );
}

export default BookDemo;
