import { getScrollParent } from 'base/utils/scrolling';
import { useEffect } from 'react';

/* checks if elm is "fixed", "absolute", "relative" */
function checkElemPositioningType(elm, _type) {
    if(!_type) _type = "fixed";
    var el;
    if (typeof elm === 'object') el = elm[0] || elm;
    else if (typeof elm === 'string') el = document.querySelector(elm);
    while (typeof el === 'object' && el.nodeName.toLowerCase() !== 'body') {
        if (window.getComputedStyle(el).getPropertyValue('position').toLowerCase() === _type) return el;
        el = el.parentElement;
    }
    return null;
}

function scrollTop(el, value) {
    if (value === undefined) {
        return el.pageYOffset;
    } else {
        if (el === window || el.nodeType === 9) {
            el.scrollTo(el.pageXOffset, value);
        } else {
            el.pageYOffset = value;
        }
    }
}

function scrollLeft(el, value) {
    if (value === undefined) {
        return el.pageXOffset;
    } else {
        if (el === window || el.nodeType === 9) {
            el.scrollTo(value, el.pageYOffset);
        } else {
            el.pageXOffset = value;
        }
    }
}

function Offset(el) {
    const box = el.getBoundingClientRect();
    const docElem = document.documentElement;
    return {
        top: box.top + window.pageYOffset - docElem.clientTop,
        left: box.left + window.pageXOffset - docElem.clientLeft
    };
}


var _counter = 0;
function getUKey(obj){
    return obj._id || obj._ukey || (obj._ukey= ++_counter);
}

const URL_REGEX =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;



function useOnScroll(el, callback, deps){
    useEffect(
        () => {
            el = getScrollParent(el);
            if(!el) return;
            var scroll_direction = 0;
            var prev_scroll = 0;

            const onScroll = (evt) => {
              let el = evt.target;
              if(el.scrollTop === undefined) el = document.documentElement;  // for window
              scroll_direction = el.scrollTop - prev_scroll;
              prev_scroll = el.scrollTop;
			  let scroll_height = (el.scrollHeight - el.clientHeight) || 1;
              if(el.scrollTop >= scroll_height - 30){
                scroll_direction > 0 && callback(100, scroll_direction);
              }
			  else if(el.scrollTop <= 20){
				scroll_direction < 0 && callback(0, scroll_direction);
			  }
			  else {
				let scroll_percent = el.scrollTop / scroll_height * 100
				scroll_percent && callback(scroll_percent, scroll_direction);
			  }
          }
          el.addEventListener("scroll", onScroll);
          return () => {
            el.removeEventListener("scroll", onScroll); 
          }
        },
        [el, ...(deps || [])] // deps
    );
}


export {checkElemPositioningType, scrollTop, scrollLeft, Offset, getUKey, URL_REGEX, useOnScroll};
