import React, { useEffect, useState } from 'react'
import { getByIds } from 'base/get_by_ids';
import { DateView } from 'base/ui/date';

const FormErrorText = ({ msg }) => {
    return (<div className='tw-text-red-600 tw-text-xs tw-mt-1'>{msg}</div>)
}

const NumberBadge = ({val}) => (
    <div className='tw-text-white tw-text-xs flex-box'>
        <span className='tw-inline-block tw-bg-red-600' style={{lineHeight: 0, borderRadius: '50%'}}>
          <span className='tw-inline-block' style={{paddingTop: '50%', paddingBottom: '50%', marginLeft: 4, marginRight: 4}}>{val}</span>
        </span>
    </div>
)

function LastUpdatedUser({ className, user_at }) {
    const [user, setUser] = useState(null);
    useEffect(
        () => {
            if (!user_at) return;
            getByIds({ "user_ids": [user_at.user_id] }).then((data) => {
                setUser(data.users[user_at.user_id]);
            });
        }, [user_at]
    );
    if (!user) return null;
    return <div className={`tw-flex tw-gap-1 tw-text-xxs tw-text-gray-400 ${className}`}>
        <p>Last Updated by:</p>
        <p className='tw-text-black'>{user.name}</p>
        <DateView millis={user_at.at} />
    </div>
}


export { FormErrorText, NumberBadge, LastUpdatedUser }